<template>
  <div class="overflow-hidden">
    <router-link :to="{ path: `/projects-survey-company/${project.id}/${toUrl}`}" class="block hover:bg-gray-50">
      <div class="px-4 py-4 sm:px-6">
        <div class="flex items-center justify-between">
          <p class="text-sm font-medium text-indigo-600 truncate">
            {{project.basicDetails.name}}
          </p>
          <div class="ml-2 flex-shrink-0 flex">
            <p v-if="currentProposal.type === 'INVITE' && project.status === 'OPEN'" class="ugi-tag" :class="project.status === 'OPEN' ? 'bg-ugi-primary text-white' : 'bg-green-100 text-green-800'">
              {{$t('invited')}}
            </p>
            <p v-if="currentProposal.type === 'QUOTE' && project.status === 'OPEN'" class="ugi-tag" :class="project.status === 'OPEN' ? 'bg-ugi-primary text-white' : 'bg-green-100 text-green-800'">
              {{$t('quoted')}}
            </p>
            <p v-if="currentProposal.type === 'DECLINE'" class="ugi-tag" :class="project.status === 'OPEN' ? 'bg-ugi-primary text-white' : 'bg-green-100 text-green-800'">
              {{$t('declined')}}
            </p>
            <p v-if="currentProposal.type === 'CANCEL'" class="ugi-tag" :class="project.status === 'OPEN' ? 'bg-ugi-primary text-white' : 'bg-green-100 text-green-800'">
              {{$t('cancelled')}}
            </p>
            <p v-if="currentProposal.type === 'APPROVAL' && project.status === 'IN_PROGRESS'" class="ugi-tag" :class="project.status === 'IN_PROGRESS' ? 'bg-ugi-primary text-white' : 'bg-green-100 text-green-800'">
              {{$t('in progress')}}
            </p>
            <p v-if="currentProposal.type === 'APPROVAL' && project.status === 'DELIVERED'" class="ugi-tag" :class="project.status === 'DELIVERED' ? 'bg-ugi-primary text-white' : 'bg-green-100 text-green-800'">
              {{$t('delivered')}}
            </p>
          </div>
        </div>
        <div class="mt-2 sm:flex sm:justify-between">
          <div class="sm:flex">
            <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
              <!-- Heroicon name: solid/location-marker -->
              <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
              </svg>
              {{project.basicDetails.address}}
            </p>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>

export default ({
  props: {
    project: {
      type: Object
    }
  },
  computed: {
    currentProposal ()
    {
      const application = this.project.application;
      const maxtimestamp = Math.max(...application.proposals.map(prop => prop.timestamp));
      const proposal = application.proposals.find(prop => prop.timestamp === maxtimestamp);
      return proposal;
    },
    toUrl ()
    {
      switch (this.project.status)
      {
      case "OPEN":
      {
        if (this.currentProposal.type === "QUOTE")
        {
          return "proposals";
        }
        else
        {
          return "details";
        }
      }
      case "IN_PROGRESS":
        return this.currentProposal.type === "APPROVAL" ? "execution" : "details";
      case "DELIVERED":
        return this.currentProposal.type === "APPROVAL" ? "results" : "details";
      case "CLOSED":
        return "results";
      default:
        return "details";
      }
    }
  }

});
</script>
