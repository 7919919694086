<template>
  <div class="group flex rounded-md shadow-sm ">
    <input type="number" class="ugi-input text-right flex-1 min-w-0 block w-full rounded-none rounded-l-md" v-bind:value="valueInUserUnit" @blur="setValue($event.srcElement.value)"/>
    <span class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
      {{ unit === null ? '' : $t(unit + '_SYMBOL') }}
    </span>
  </div>
</template>

<script>
import { convertUnit } from "@/helpers";

export default {
  props: {
    value: {
      type: Number,
      default: null
    },
    unit: {
      type: String,
      default: null
    }
  },
  data ()
  {
    return {
    };
  },
  watch: {

  },
  mounted ()
  {

  },
  computed: {
    valueInUserUnit ()
    {
      if (this.value === null)
      {
        return null;
      }
      else if (this.unit === null)
      {
        return this.value;
      }
      else
      {
        return +convertUnit(this.value, "M", this.unit).toFixed(3);
      }
    }
  },
  methods: {
    setValue (value, a)
    {
      if (value === undefined || value === "")
      {
        if (this.value !== null)
        {
          this.$emit("input", null);
        }
      }
      else if (this.valueInUserUnit === null || Number(value) !== Number(this.valueInUserUnit))
      {
        this.$emit("input", convertUnit(Number(value), this.unit, "M"));
      }
    }
  }
};
</script>

<style scoped>
    .remove-red-in-firefox {
        box-shadow: 0 0 0px red;
    }
</style>
