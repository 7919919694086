import Vue from "vue";
import VueRouter from "vue-router";
import Login from "./components/loggedOut/Login";
import MainSettings from "./components/settings/MainSettings";
import Profile from "./components/settings/Profile";
import Security from "./components/settings/Security";
import Company from "./components/settings/Company";
import Notifications from "./components/settings/Notifications";
import ClientProjectsList from "./components/clientProjects/clientProjectsList/ClientProjectsList";
import ClientProjectsEdit from "./components/clientProjects/clientProjectsEdit/ClientProjectsEdit";
import ClientProjectsEditDetails from "./components/clientProjects/clientProjectsEdit/clientProjectsEditDetails/ClientProjectsEditDetails";
import ClientProjectsEditContractors from "./components/clientProjects/clientProjectsEdit/clientProjectsEditContractors/ClientProjectsEditContractors";
import ClientProjectsEditProposals from "./components/clientProjects/clientProjectsEdit/clientProjectsEditProposals/ClientProjectsEditProposals";
import ClientProjectsEditResults from "./components/clientProjects/clientProjectsEdit/clientProjectsEditResults/ClientProjectsEditResults";
import ContractorList from "./components/contractors/ContractorList";
import ContractorEdit from "./components/contractors/ContractorEdit";
import ContractorProjectsEdit from "./components/contractorProjects/contractorProjectsEdit/ContractorProjectsEdit";
import ContractorProjectsEditDetails from "./components/contractorProjects/contractorProjectsEdit/ContractorProjectsEditDetails";
import ContractorProjectsEditProposals from "./components/contractorProjects/contractorProjectsEdit/ContractorProjectsEditProposals";
import ContractorProjectsEditExecution from "./components/contractorProjects/contractorProjectsEdit/ContractorProjectsEditExecution";
import ContractorProjectsEditResults from "./components/contractorProjects/contractorProjectsEdit/ContractorProjectsEditResults";
import ContractorProjectList from "./components/contractorProjects/contractorProjectsList/ContractorProjectsList";

Vue.use(VueRouter);

const routes = [
  { path: "/", beforeEnter: requireAuth },
  { path: "/login", component: Login, beforeEnter: requireNotAuth },
  { path: "/projects", component: ClientProjectsList, beforeEnter: requireAuth },
  { path: "/projects-survey-company", component: ContractorProjectList, beforeEnter: requireAuth },
  { path: "/projects-survey-company/:idProject", component: ContractorProjectsEdit, beforeEnter: requireAuth },
  { path: "/companies", component: ContractorList, beforeEnter: requireAuth },
  { path: "/companies/:idContractor", component: ContractorEdit, beforeEnter: requireAuth },
  {
    path: "/settings",
    component: MainSettings,
    beforeEnter: requireAuth,
    children:
            [
              { path: "", redirect: "profile" },
              { path: "profile", component: Profile },
              { path: "security", component: Security },
              { path: "company", component: Company },
              { path: "notifications", component: Notifications }
            ]
  },
  {
    path: "/projects/:idProject",
    component: ClientProjectsEdit,
    beforeEnter: requireAuth,
    children:
      [
        { path: "details", component: ClientProjectsEditDetails },
        { path: "contractors", component: ClientProjectsEditContractors },
        { path: "proposals", component: ClientProjectsEditProposals },
        { path: "results", component: ClientProjectsEditResults }
      ]
  },
  {
    path: "/projects-survey-company/:idProject",
    component: ContractorProjectsEdit,
    beforeEnter: requireAuth,
    children:
      [
        { path: "details", component: ContractorProjectsEditDetails },
        { path: "proposals", component: ContractorProjectsEditProposals },
        { path: "execution", component: ContractorProjectsEditExecution },
        { path: "results", component: ContractorProjectsEditResults }
      ]
  }
];

const router = new VueRouter({
  routes
});

function requireAuth (to, from, next)
{
  if (Vue.$cookies.get("logged_in"))
  {
    if (to.fullPath === "/")
    {
      // Manage different routes according to role here
      next("/projects");
    }
    else
    {
      next();
    }
  }
  else
  {
    if (to.fullPath === "/")
    {
      next("/login");
    }
    else
    {
      next("/login?redirect=" + to.fullPath);
    }
  }
}

function requireNotAuth (to, from, next)
{
  if (Vue.$cookies.get("logged_in"))
  {
    next("/");
  }
  else
  {
    next();
  }
}
export default router;
