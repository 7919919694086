<template>
  <main-logged-in :title="title" :breadcrumbs="breadcrumbs">
    <template v-if="project !== null">
      <ugi-step-bar :steps="steps" :currentStep="currentStep" :selectedStep="selectedStep" @click="changeStep($event)"/>
      <div v-if="!loading" >
        <router-view :project.sync="project" />
      </div>
    </template>
    <div class="max-w-3xl mt-4 mx-auto text-center text-xl leading-9 font-medium text-gray-500">
      <p>
        {{$t(currentError)}}
      </p>
    </div>
  </main-logged-in>
</template>

<script>

import MainLoggedIn from "@/common/MainLoggedIn.vue";
import { HTTP } from "@/http.js";

export default {
  components: {
    MainLoggedIn
  },
  data ()
  {
    return {
      project: null,
      loading: false,
      steps: [
        { title: this.$t("Project details"), urlStep: "details" },
        { title: this.$t("Contractors"), urlStep: "contractors" },
        { title: this.$t("Proposals"), urlStep: "proposals" },
        { title: this.$t("Final delivery"), urlStep: "results" }
      ],
      selectedStep: null,
      currentError: ""
    };
  },
  computed: {
    currentStep ()
    {
      if (this.project.status === "DRAFT")
      {
        return this.steps.findIndex(a => a.urlStep === "details");
      }
      else if (this.project.status === "OPEN")
      {
        return this.steps.findIndex(a => a.urlStep === "proposals");
      }
      else
      {
        return this.steps.findIndex(a => a.urlStep === "results");
      }
    },
    title ()
    {
      if (this.project === null)
      {
        return null;
      }
      else if (this.project.id === undefined)
      {
        return this.$t("New Project");
      }
      else
      {
        return this.project.basicDetails.name;
      }
    },
    breadcrumbs ()
    {
      return [
        { title: this.$t("Projects"), path: "/projects" },
        { title: this.title, path: null }
      ];
    }
  },
  watch: {
    $route (to, from)
    {
      this.recalculateStep();
    }
  },
  mounted ()
  {
    const id = this.$route.params.idProject;
    if (id !== "new")
    {
      this.loading = true;
      HTTP.get(`/client/projects/${id}`)
        .then(response =>
        {
          this.project = response;
        })
        .catch(error =>
        {
          this.currentError = error;
        })
        .finally(() =>
        {
          this.loading = false;
        });
    }
    else
    {
      this.project =
          {
            status: "DRAFT",
            basicDetails:
              {
                step: 1
              }
          };
    }
    this.recalculateStep();
  },
  methods:
      {
        changeStep (event)
        {
          if (parseInt(event) <= this.currentStep)
          {
            this.$router.push(this.steps[event].urlStep);
            this.recalculateStep();
          }
        },
        recalculateStep ()
        {
          const fullPath = this.$router.currentRoute.path;
          const localPath = fullPath.substring(fullPath.lastIndexOf("/") + 1);
          this.selectedStep = this.steps.findIndex(a => a.urlStep === localPath); ;
        }
      }
};
</script>
