<template>
  <div id="app" class="h-full overflow-hidden">
    <div class="absolute bottom-0 left-0 w-full h-full overflow-y-scroll">
      <router-view />
    </div>
    <portal-target slim class="absolute bottom-0 left-0 w-full h-full" name="popup-placer" />
    <portal-target slim class="absolute bottom-0 left-0 w-full h-full" name="popup-panel-placer" />
  </div>
</template>

<script>

export default {

};
</script>

<style>

    @import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@400;500;600&display=swap');
    @import url('https://fonts.googleapis.com/css?family=Baloo+Chettan+2:400&display=swap');

    #app {
        font-family: 'Catamaran';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        @apply text-gray-600;
    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }

    .ugi-font-rounded {
        font-family: 'Baloo Chettan 2'
    }

    .ugi-element-disabled {
        @apply cursor-not-allowed opacity-50 pointer-events-none;
    }

</style>
