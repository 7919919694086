<template>
  <main-logged-in :title="$t('projects')">

    <div class="ugi-card p-2">
      <ugi-inline-alert class="mb-6" message="For testing purposes only, the user can select a survey company to simulate how they would see the projects" />
      <ugi-form-field-with-title class="m-4 w-56" title="Survey company simulating">
        <select class="ugi-select" v-model="idSurveyCompanySelected">
          <option v-for="company in surveyCompanies" :key="company.id" :value="company.id">{{ company.name }}</option>
        </select>
      </ugi-form-field-with-title>
    </div>

    <ugi-main-section :showPagination="true">
      <contractor-projects-list-item v-for="project in this.projects" :key="project.id" :project=project />
    </ugi-main-section>
  </main-logged-in>
</template>

<script>

import MainLoggedIn from "@/common/MainLoggedIn.vue";
import ContractorProjectsListItem from "./ContractorProjectsListItem.vue";
import { HTTP } from "@/http.js";

export default {
  components: {
    MainLoggedIn,
    ContractorProjectsListItem
  },
  computed: {
    idSurveyCompanySelected:
      {
        get: function ()
        {
          if (this.$store.state.currentSurveyCompany === null)
          {
            return null;
          }
          else
          {
            return this.$store.state.currentSurveyCompany.id;
          }
        },
        set: function (newValue)
        {
          this.$store.dispatch("storeSurveyCompany", this.surveyCompanies.find(a => a.id === newValue))
            .then(response =>
            {
              this.loadProjects();
            })
            .catch(error =>
            {
              this.$bus.emit("notification", { type: "ERROR", message: error });
            });
        }
      }
  },
  data ()
  {
    return {
      projects: [],
      surveyCompanies: []
    };
  },
  created ()
  {
    HTTP.get("/survey-companies")
      .then(response =>
      {
        this.surveyCompanies = response;
      })
      .catch(error =>
      {
        this.$bus.emit("notification", { type: "ERROR", message: error });
      });
    this.loadProjects();
  },
  methods: {
    loadProjects ()
    {
      this.projects = [];
      if (this.idSurveyCompanySelected !== null)
      {
        HTTP.get(`/contractor/projects?idContractor=${this.idSurveyCompanySelected}`)
          .then(response =>
          {
            this.projects = response;
          })
          .catch(error =>
          {
            this.$bus.emit("notification", { type: "ERROR", message: error });
          });
      }
    }
  }
};
</script>
