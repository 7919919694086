<template>
  <ugi-main-section :title="$t('Results')">
    <div slot="buttons">
      <button class="ugi-button-light" @click="showPopupApprove = true" v-if="isLastEventSubmission">{{ $t('Approve results') }}</button>
      <ugi-popup v-if="showPopupApprove" @cancel="showPopupApprove = false" @confirm="approveResult" :loadingPositive="loadingApprove" :title="$t('Approve')">
        <div slot>
          {{$t('Approve the current results to finalize the project')}}
        </div>
      </ugi-popup>
    </div>
    <div>
      <ugi-history-bar v-if="steps.length > 0" :steps="steps" @clickItem="clickEvent" :selectedStep="project.resultEvents.indexOf(currentSubmission)" />
      <projects-results-details v-if="currentSubmission !== null" :results="currentSubmission" :idProject="project.id" />
      <p class="ugi-empty-list-legend" v-if="steps.length === 0">{{ $t('No results have been submitted yet') }}</p>
    </div>
  </ugi-main-section>
</template>

<script>

import ProjectsResultsDetails from "../../../commonProjects/ProjectsResultsDetails.vue";
import { HTTP } from "@/http.js";

export default {
  components: {
    ProjectsResultsDetails
  },
  props: {
    project: {
      type: Object
    }
  },
  data ()
  {
    return {
      currentSubmission: null,
      showPopupApprove: false,
      loadingApprove: false
    };
  },
  mounted ()
  {
    if (this.project.resultEvents !== null &&
          this.project.resultEvents !== undefined &&
          this.project.resultEvents.some(a => a.type === "ProjectResultEventSubmission"))
    {
      const maxtimestamp = Math.max(...this.project.resultEvents.filter(a => a.type === "ProjectResultEventSubmission").map(res => res.timestamp));
      this.currentSubmission = this.project.resultEvents.find(res => res.timestamp === maxtimestamp);
    }
  },
  computed: {
    steps ()
    {
      const tmp = [];
      if (this.project.resultEvents !== undefined && this.project.resultEvents !== null)
      {
        this.project.resultEvents.forEach(resultEvent =>
        {
          const step = {
            title: this.$t(resultEvent.type),
            timestamp: resultEvent.timestamp
          };
          if (resultEvent.type === "ProjectResultEventSubmission") step.svgPath = "M3.34 2.57c-1.48,0.19 -2.56,1.17 -2.98,2.38 -0.33,0.98 -0.19,3.9 -0.19,5.12 0,1.78 0,3.55 0,5.32 0,2.16 -0.4,4.65 2.18,5.79 0.73,0.33 1.53,0.28 2.38,0.28 1.45,0 15.21,0.06 15.97,-0.03 1.43,-0.16 2.55,-1.21 2.94,-2.38 0.33,-0.98 0.19,-3.89 0.19,-5.12 0,-1.78 0,-3.55 0,-5.32 0,-1.71 0.25,-3.4 -0.71,-4.61 -1.11,-1.43 -2.16,-1.46 -3.85,-1.46 -1.47,0 -15.15,-0.07 -15.93,0.03zm0.46 16.53l16.4 0c0.88,0 1.27,-0.55 1.27,-1.43l0 -8.16c-0.34,0.15 -4.13,2.72 -4.54,2.99 -0.76,0.51 -1.49,0.99 -2.25,1.5 -0.75,0.5 -1.46,1.07 -2.68,1.07 -1.21,0 -1.93,-0.57 -2.68,-1.07 -0.76,-0.51 -1.49,-0.99 -2.25,-1.5 -0.77,-0.51 -1.47,-0.98 -2.24,-1.5 -0.38,-0.25 -0.73,-0.48 -1.11,-0.73 -0.31,-0.21 -0.85,-0.62 -1.19,-0.76l0 8.11c0,0.9 0.37,1.48 1.27,1.48zm-1.26 -12.43c0.27,0.1 4.28,2.82 4.65,3.06l3.48 2.33c1.6,1.06 1.46,0.8 3.8,-0.76 0.57,-0.38 6.7,-4.52 6.99,-4.63 0.01,-0.65 0.01,-1.64 -1.05,-1.75l-16.77 -0.01c-1.07,0.09 -1.12,1.06 -1.1,1.76z";
          else if (resultEvent.type === "ProjectResultEventApprove") step.svgPath = "M2.33 14.3c-0.59,-0.58 -0.6,-1.54 -0.01,-2.13 0.59,-0.59 1.54,-0.6 2.14,-0.01l3.85 3.85 11.22 -11.22c0.59,-0.6 1.55,-0.6 2.14,0 0.59,0.59 0.59,1.55 0,2.14l-12.29 12.29c-0.59,0.59 -1.54,0.59 -2.14,0l-4.91 -4.92z";
          tmp.push(step);
        });
      }
      return tmp;
    },
    isLastEventSubmission ()
    {
      if (this.project.resultEvents === null || this.project.resultEvents === undefined || this.project.resultEvents.length === 0)
      {
        return false;
      }
      else
      {
        return this.project.resultEvents[this.project.resultEvents.length - 1].type === "ProjectResultEventSubmission";
      }
    }
  },
  methods: {
    clickEvent (index)
    {
      if (this.project.resultEvents[index].type === "ProjectResultEventSubmission")
      {
        this.currentSubmission = this.project.resultEvents[index];
      }
    },
    approveResult ()
    {
      this.loadingApprove = true;
      HTTP.post(`/client/projects/${this.project.id}/results/approval`, {})
        .then(response =>
        {
          this.$emit("update:project", response);
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        })
        .finally(() =>
        {
          this.loadingApprove = false;
          this.showPopupApprove = false;
        });
    }
  }
};
</script>
