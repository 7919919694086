<template>
  <div class="list-item">
    <p class="list-item-header">{{ title }}</p>
    <div class="list-item-data">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data: function ()
  {
    return {

    };
  },
  model: {
  },
  props: {
    title: {
      type: String
    }
  },
  methods: {

  }
};
</script>

<style scoped>
    .list-item {
        @apply px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6;
    }

    .list-item-header {
        @apply text-sm font-medium text-gray-500;
    }

    .list-item-data {
        @apply mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2;
    }
</style>
