<template>
  <div class="flex justify-end">
    <transition-group name="list-complete" tag="div" class="trans-group relative">
      <div style="width: 33vw; max-width: 600px;" class="pt-4 pr-4" v-for="item in notifications" :key="item.id">
        <div class="border-l-4 rounded px-4 py-3 shadow-md" :class="{'bg-orange-100 border-orange-500' : item.type === 'WARNING', 'text-green-700 border-green-500' : item.type === 'SUCCESS', 'text-blue-700 border-blue-500' : item.type === 'INFO', 'text-red-700 border-red-500' : item.type === 'ERROR'}" style="background-color: #f9f9f9">
          <div class="flex items-center">
            <svg v-if="item.type === 'INFO'" class="h-6 w-6 mr-4 mt-1 flex-grow-0 flex-shrink-0 self-start" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <svg v-if="item.type === 'SUCCESS'" class="h-6 w-6 mr-4 mt-1 flex-grow-0 flex-shrink-0 self-start" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
            <svg v-if="item.type === 'ERROR'" class="h-6 w-6 mr-4 mt-1 flex-grow-0 flex-shrink-0 self-start" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div class="flex-grow">
              <p v-if="item.type === 'INFO'" class="font-bold">{{ $t('info') }}</p>
              <p v-if="item.type === 'WARNING'" class="font-bold">{{ $t('warning') }}</p>
              <p v-if="item.type === 'SUCCESS'" class="font-bold">{{ $t('success') }}</p>
              <p v-if="item.type === 'ERROR'" class="font-bold">{{ $t('error') }}</p>
              <p class="text-sm">{{ $t(item.message) }}</p>
            </div>
            <div class="mr-2">
              <div v-for="(action, iAction) in item.actions" v-bind:key="iAction" class="uppercase font-bold cursor-pointer rounded px-1 hover:bg-gray-200" @click="item.timeoutCallback = undefined; action.callback();">
                {{ action.message }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-orange-100" key="dummy" style="width: 33vw; max-width: 600px;" />
    </transition-group>
  </div>

</template>

<script>

import { getNewUuid } from "@/helpers";

export default {
  data: function ()
  {
    return {
      notifications: []
    };
  },
  mounted ()
  {
    this.$bus.on("notification", notification =>
    {
      notification.id = getNewUuid();
      this.notifications.push(notification);
      const thisCopy = this;
      setTimeout(() =>
      {
        thisCopy.notifications = this.notifications.filter(a => a !== notification);
        if (notification.timeoutCallback !== undefined)
        {
          notification.timeoutCallback();
        }
      }, notification.time || this.$constants.NOTIFICATION_TIMEOUT);
    });
  }
};
</script>

<style scoped>

  .trans-group > div {
    transition: all 0.4s;
    width: 100%;
  }

  .list-complete-item {
    transition: all 0.4s;
  }

  .list-complete-enter {
    opacity: 0;
    transform: translateY(-50px);
  }

  .list-complete-leave-to {
    transition: all 0.6s;
    opacity: 0;
    transform: translateY(-20px);
  }

  .list-complete-leave-active {
    position: absolute;
  }

</style>
