<template>
  <a class="text-gray-700 group flex items-center px-4 py-2 text-sm hover:bg-gray-50 cursor-pointer" role="menuitem" tabindex="-1" id="menu-item-4" @click="$emit('click')">
    <!-- <svg class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path :d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
    </svg> -->
    <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="svgPath" />
    </svg>
    {{ label }}
  </a>
</template>

<script>

export default ({
  props: {
    label: {
      type: String
    },
    svgPath: {
      type: String,
      default: null
    }
  },
  data ()
  {
    return {

    };
  },
  mounted ()
  {

  },
  methods:
    {

    }
});
</script>

<style scoped>

</style>
