<template>
  <div class="flex items-center">
    <div class="form-switch inline-block align-middle my-1" :class="{'pointer-events-none' : disabled}">
      <input :checked="valueComputed" type="checkbox" :id="localId" class="form-switch-checkbox" @click="valueComputed = $event.srcElement.checked" />
      <label class="form-switch-label" :for="localId"/>
    </div>
    <div>{{ label }}</div>
  </div>
</template>

<script>
import { getNewUuid } from "@/helpers";

export default {
  mounted ()
  {
    this.localId = getNewUuid();
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    }
  },
  computed: {
    valueComputed:
      {
        get: function ()
        {
          return this.value;
        },
        set: function (newValue)
        {
          this.$emit("input", newValue);
        }
      }
  },
  methods: {
    toggle ()
    {
      this.valueComputed = !this.valueComputed;
    }
  },
  data ()
  {
    return {
      localId: ""
    };
  }
};
</script>

<style lang="postcss">

    .form-switch {
        @apply relative select-none w-12 mr-2 leading-normal;
    }

    .form-switch-checkbox {
        @apply hidden;
    }

    .form-switch-label {
        @apply block overflow-hidden cursor-pointer bg-gray-200 border rounded-full h-6 shadow-inner;
        transition: background-color 0.2s ease-in;
    }

    .form-switch-label:before {
        @apply absolute block bg-white inset-y-0 w-6 border rounded-full -ml-1;
        right: 50%;
        content: "";
        transition: all 0.2s ease-in;
    }

    .form-switch-checkbox:checked + .form-switch-label,
    .form-switch-checkbox:checked + .form-switch-label:before {
    }

    .form-switch-checkbox:checked + .form-switch-label {
        @apply bg-ugi-primary shadow-none;
    }

    .form-switch-checkbox:checked + .form-switch-label:before {
        @apply right-0;
    }
</style>
