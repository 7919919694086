<template>
  <div class="flex-1">
    <div class="divide-y divide-gray-100">
      <ugi-details-field-with-title :title="$t('Introduction')">
        <p>{{ results.introduction }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Discussion of results')">
        <p>{{ results.dicussionResults }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Conclusions')">
        <p>{{ results.conclusions }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Attached files')">
        <ugi-file-uploader v-for="(fileInfo, index) in fileInfoList" :key="index" :fileInfo="fileInfo" @download="downloadFile(fileInfo)" :deleteIcon="false" />
      </ugi-details-field-with-title>
    </div>
  </div>
</template>

<script>

import { HTTP } from "@/http.js";
import { getErrorDescription } from "@/helpers.js";

export default
{
  beforeMount ()
  {

  },
  mounted ()
  {

  },
  props: {
    idProject: {
      type: String
    },
    results: {
      type: Object
    }
  },
  data ()
  {
    return {
    };
  },
  computed: {
    fileInfoList ()
    {
      const tmp = [];
      if (this.results.files !== undefined && this.results.files !== null)
      {
        this.results.files.forEach(file =>
        {
          tmp.push({
            id: file.id,
            name: file.name
          });
        });
      }
      return tmp;
    }
  },
  methods: {
    getErrorDescription: getErrorDescription,
    downloadFile (fileInfo)
    {
      HTTP.get(`/contractor/projects/${this.idProject}/result-files/${fileInfo.id}`, {})
        .then(response =>
        {
          const link = document.createElement("a");
          link.href = response.urlDownload;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        });
    }
  }
};
</script>
