<template>
  <ugi-main-section :title="$t('Proposal')">

    <div slot="buttons">
      <template v-if="lastProposal !== null && (lastProposal.type === 'INVITE' || lastProposal.type === 'CANCEL')">
        <ugi-button :text="$t('Send proposal')" @click="popupQuote" classButton="ugi-button-light" key="button1" />
      </template>
      <template v-if="lastProposal !== null && lastProposal.type === 'QUOTE'">
        <ugi-button :text="$t('Send new proposal')" @click="popupQuote" class="mr-2" classButton="ugi-button-light" key="button2" />
        <ugi-button :text="$t('Cancel current proposal')" @click="popupCancel" class="mr-2" classButton="ugi-button-light" key="button3" />
      </template>

      <ugi-popup v-if="showPopupCancel" @cancel="showPopupCancel = false" @confirm="cancelQuote" :loadingPositive="loadingDelete" :title="$t('Cancel proposal')">
        <p slot>{{$t('Are you sure you want to cancel the current proposal?')}}</p>
      </ugi-popup>

      <contractor-projects-edit-proposal-form v-if="showPopup"
                                              :unitLocation="project.basicDetails.unitLocation"
                                              @cancel="showPopup = false"
                                              @proposalSent="proposalSent" />

    </div>

    <div>
      <ugi-history-bar v-if="steps.length > 0" :steps="steps" :selectedStep="application.proposals.indexOf(currentProposal)" @clickItem="clickProposal" />
    </div>

    <projects-proposal-details v-if="currentProposal !== null" :idContractor="$store.state.currentSurveyCompany.id" :proposal="currentProposal" :unitLocation="project.basicDetails.unitLocation" />

    <div v-if="currentProposal === null">
      <p class="ugi-empty-list-legend">{{ $t('No proposals have been submitted yet') }}</p>
    </div>

  </ugi-main-section>
</template>

<script>

import { HTTP } from "@/http.js";
import { epochToStringHumanReadable } from "@/helpers.js";
import ContractorProjectsEditProposalForm from "./ContractorProjectsEditProposalForm.vue";
import ProjectsProposalDetails from "../../../commonProjects/ProjectsProposalDetails.vue";

export default {
  components: {
    ContractorProjectsEditProposalForm,
    ProjectsProposalDetails
  },
  props: {
    project: {
      type: Object
    },
    application: {
      type: Object
    }
  },
  data ()
  {
    return {
      currentError: "",
      formSubmitted: false,
      loadingSendQuote: false,
      loadingDelete: false,
      showPopup: false,
      showPopupCancel: false,
      currentProposal: null
    };
  },
  computed: {
    errors ()
    {
      const tmp = {};
      tmp.name = [];
      return tmp;
    },
    lastProposal ()
    {
      const maxtimestamp = Math.max(...this.application.proposals.map(prop => prop.timestamp));
      return this.application.proposals.find(prop => prop.timestamp === maxtimestamp);
    },
    steps ()
    {
      const tmp = [];
      if (this.application.proposals !== undefined && this.application.proposals !== null)
      {
        this.application.proposals.forEach(proposalEvent =>
        {
          const step = {
            timestamp: proposalEvent.timestamp
          };

          if (proposalEvent.type === "INVITE") step.svgPath = "M11.37 0.35c-1.25,0.26 -2.7,1.42 -3.78,2.13l-5.32 3.55c-0.66,0.44 -1.17,0.78 -1.59,1.38 -0.89,1.31 -0.63,3.47 -0.63,5.29 0,1.95 0,3.91 0,5.86 0,1.73 -0.16,3.21 1.49,4.44 1.3,0.96 3.4,0.68 5.25,0.68l11.79 0c2,0 2.8,0.21 4.22,-0.96 0.22,-0.17 0.23,-0.25 0.36,-0.4 1.08,-1.26 0.79,-3.24 0.79,-5.13 0,-1.97 0,-3.95 0,-5.92 0,-2.32 0.11,-3.34 -1.28,-4.58 -0.29,-0.26 -3.14,-2.12 -3.54,-2.38 -1.52,-1.02 -3.85,-2.64 -5.33,-3.54 -0.65,-0.4 -1.58,-0.6 -2.43,-0.42zm-8.73 8.45c0.12,0.18 5.87,3.96 6.87,4.63 2.8,1.87 2.16,1.87 4.94,0.02 0.8,-0.53 1.52,-1.02 2.3,-1.54l3.46 -2.3c0.31,-0.21 0.96,-0.59 1.16,-0.81 -0.21,-0.39 -1.73,-1.29 -2.21,-1.61l-5.83 -3.88c-1.27,-0.85 -1.35,-0.87 -2.63,-0.02l-5.82 3.88c-0.41,0.27 -0.75,0.5 -1.15,0.76 -0.42,0.28 -0.89,0.51 -1.09,0.87zm6.68 7.37c-0.51,0.24 -6.64,4.41 -6.72,4.49 0.23,0.44 0.59,0.64 1.23,0.64l16.39 0c0.6,0 1.02,-0.25 1.19,-0.65 -0.72,-0.57 -5.26,-3.51 -6.3,-4.21 -0.32,-0.21 -0.35,-0.34 -0.68,-0.11 -1.08,0.72 -2.15,1.2 -3.58,0.69 -0.71,-0.24 -0.99,-0.6 -1.53,-0.85zm-6.88 1.67c0.24,-0.08 4.56,-2.97 4.68,-3.11 -0.12,-0.16 -4.44,-3.04 -4.67,-3.12l-0.01 6.23zm14.43 -3.14l4.67 3.16 0.02 -0.23 0 -5.81 0 -0.21 -4.69 3.09z";
          else if (proposalEvent.type === "APPROVAL") step.svgPath = "M2.33 14.3c-0.59,-0.58 -0.6,-1.54 -0.01,-2.13 0.59,-0.59 1.54,-0.6 2.14,-0.01l3.85 3.85 11.22 -11.22c0.59,-0.6 1.55,-0.6 2.14,0 0.59,0.59 0.59,1.55 0,2.14l-12.29 12.29c-0.59,0.59 -1.54,0.59 -2.14,0l-4.91 -4.92z";
          else if (proposalEvent.type === "QUOTE") step.svgPath = "M10.85 21.79c-0.46,-0.05 -0.9,-0.14 -1.33,-0.25 -0.76,-0.2 -1.48,-0.52 -2.15,-0.93 -0.67,-0.41 -1.29,-0.93 -1.88,-1.54l0 0 1.81 -2.21 0 0c0.64,0.77 1.34,1.35 2.12,1.74 0.77,0.39 1.61,0.57 2.52,0.57 1.26,0 2.2,-0.24 2.82,-0.73 0.61,-0.5 0.93,-1.24 0.93,-2.24l0 -0.01c0,-0.64 -0.15,-1.14 -0.45,-1.51 -0.3,-0.37 -0.69,-0.65 -1.17,-0.84 -0.48,-0.19 -1.09,-0.35 -1.83,-0.49 -0.03,-0.01 -0.05,-0.01 -0.07,-0.01 -0.02,0 -0.04,-0.01 -0.07,-0.02 -0.04,-0.01 -0.09,-0.02 -0.14,-0.03 -0.05,-0.01 -0.09,-0.02 -0.14,-0.03 -1.19,-0.22 -2.15,-0.46 -2.88,-0.74 -0.73,-0.28 -1.36,-0.77 -1.89,-1.5 -0.52,-0.72 -0.78,-1.75 -0.78,-3.07l0 -0.02c0,-1.3 0.23,-2.36 0.7,-3.22 0.47,-0.85 1.17,-1.5 2.1,-1.93 0.52,-0.24 1.11,-0.41 1.77,-0.51l0 -2.28 2.71 0 0 2.2 0.87 0.15c0.61,0.13 1.2,0.32 1.8,0.58 0.6,0.26 1.18,0.59 1.76,0.97l0 0 -1.63 2.24 0 0c-0.65,-0.44 -1.3,-0.77 -1.92,-0.99 -0.62,-0.21 -1.24,-0.32 -1.85,-0.32 -1.17,0 -2.05,0.24 -2.63,0.72 -0.58,0.48 -0.87,1.21 -0.87,2.17l0 0.01c0,0.66 0.17,1.18 0.5,1.56 0.33,0.38 0.76,0.66 1.27,0.83 0.51,0.18 1.2,0.36 2.09,0.56 0.04,0.01 0.07,0.02 0.11,0.03 0.03,0.01 0.07,0.02 0.11,0.03 0.05,0.01 0.09,0.02 0.13,0.03 0.05,0.01 0.08,0.02 0.13,0.02 1.04,0.23 1.92,0.51 2.62,0.85 0.71,0.33 1.29,0.86 1.76,1.6 0.47,0.73 0.71,1.73 0.71,3l0 0.02c0,1.25 -0.25,2.29 -0.73,3.12 -0.49,0.82 -1.22,1.44 -2.19,1.85 -0.59,0.25 -1.27,0.42 -2.04,0.52l0 2.24 -2.71 0 0 -2.21z";
          else if (proposalEvent.type === "CANCEL") step.svgPath = "M11.37 0.35c-1.25,0.26 -2.7,1.42 -3.78,2.13l-5.32 3.55c-0.66,0.44 -1.17,0.78 -1.59,1.38 -0.89,1.31 -0.63,3.47 -0.63,5.29 0,1.95 0,3.91 0,5.86 0,1.73 -0.16,3.21 1.49,4.44 1.3,0.96 3.4,0.68 5.25,0.68l11.79 0c2,0 2.8,0.21 4.22,-0.96 0.22,-0.17 0.23,-0.25 0.36,-0.4 1.08,-1.26 0.79,-3.24 0.79,-5.13 0,-1.97 0,-3.95 0,-5.92 0,-2.32 0.11,-3.34 -1.28,-4.58 -0.29,-0.26 -3.14,-2.12 -3.54,-2.38 -1.52,-1.02 -3.85,-2.64 -5.33,-3.54 -0.65,-0.4 -1.58,-0.6 -2.43,-0.42zm-8.73 8.45c0.12,0.18 5.87,3.96 6.87,4.63 2.8,1.87 2.16,1.87 4.94,0.02 0.8,-0.53 1.52,-1.02 2.3,-1.54l3.46 -2.3c0.31,-0.21 0.96,-0.59 1.16,-0.81 -0.21,-0.39 -1.73,-1.29 -2.21,-1.61l-5.83 -3.88c-1.27,-0.85 -1.35,-0.87 -2.63,-0.02l-5.82 3.88c-0.41,0.27 -0.75,0.5 -1.15,0.76 -0.42,0.28 -0.89,0.51 -1.09,0.87zm6.68 7.37c-0.51,0.24 -6.64,4.41 -6.72,4.49 0.23,0.44 0.59,0.64 1.23,0.64l16.39 0c0.6,0 1.02,-0.25 1.19,-0.65 -0.72,-0.57 -5.26,-3.51 -6.3,-4.21 -0.32,-0.21 -0.35,-0.34 -0.68,-0.11 -1.08,0.72 -2.15,1.2 -3.58,0.69 -0.71,-0.24 -0.99,-0.6 -1.53,-0.85zm-6.88 1.67c0.24,-0.08 4.56,-2.97 4.68,-3.11 -0.12,-0.16 -4.44,-3.04 -4.67,-3.12l-0.01 6.23zm14.43 -3.14l4.67 3.16 0.02 -0.23 0 -5.81 0 -0.21 -4.69 3.09z";

          if (proposalEvent.type === "INVITE") step.title = this.$t("Invited");
          else if (proposalEvent.type === "APPROVAL") step.title = this.$t("Project awarded");
          else if (proposalEvent.type === "QUOTE") step.title = this.$t("Proposal");
          else if (proposalEvent.type === "CANCEL") step.title = this.$t("Proposal canceled");
          else if (proposalEvent.type === "DECLINE") step.title = this.$t("Proposal declined");

          tmp.push(step);
        });
      }
      return tmp;
    }

  },
  mounted ()
  {
    const proposalEvents = this.application.proposals.filter(a => a.type === "QUOTE");
    if (proposalEvents.length > 0)
    {
      const maxtimestamp = Math.max(...proposalEvents.map(prop => prop.timestamp));
      this.currentProposal = proposalEvents.find(prop => prop.timestamp === maxtimestamp);
    }
  },
  methods: {
    epochToStringHumanReadable: epochToStringHumanReadable,
    cancelQuote ()
    {
      this.loadingDelete = true;
      HTTP.delete(`/contractor/projects/${this.project.id}/quote/${this.$store.state.currentSurveyCompany.id}`)
        .then(response =>
        {
          this.showPopupCancel = false;
          this.$emit("update:project", response);
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        })
        .finally(() =>
        {
          this.loadingDelete = false;
        });
    },
    proposalSent (updatedProject)
    {
      this.$emit("update:project", updatedProject);
      this.showPopup = false;
      this.currentProposal = updatedProject.application.proposals[updatedProject.application.proposals.length - 1];
    },
    popupQuote ()
    {
      this.showPopup = true;
    },
    popupCancel ()
    {
      this.showPopupCancel = true;
    },
    clickProposal (index)
    {
      if (this.application.proposals[index].type === "QUOTE")
      {
        this.currentProposal = this.application.proposals[index];
      }
    }
  }
};
</script>
