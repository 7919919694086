<template>

  <div class="px-4 py-4 sm:px-6 flex cursor-pointer hover:bg-gray-50" @click="contractorClick">

    <div class="flex-grow">
      <div class="flex items-center justify-between">
        <p class="font-medium truncate">
          {{ surveyCompany.name }}
        </p>
      </div>
      <div class="mt-2 flex items-center justify-between">
        <div class="sm:flex">
          <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            {{ surveyCompany.emailAdmin }}
          </p>
        </div>
      </div>
    </div>

    <div class="ml-2 flex-shrink-0">
      <p v-if="currentProposalType === 'APPROVAL'" class="ugi-tag bg-ugi-secondary-light text-ugi-primary">
        {{$t('Approved')}}
      </p>
      <p v-if="currentProposalType === 'DECLINE'" class="ugi-tag bg-ugi-secondary-light text-ugi-primary">
        {{$t('Declined')}}
      </p>
    </div>

  </div>

  <!--<div class="overflow-hidden px-4 py-4 sm:px-6" :class="currentProposalType ? 'cursor-pointer hover:bg-gray-50' : ''" @click="contractorClick">
    <div class="flex items-center justify-between">
      <p class="text-sm font-medium text-indigo-600 truncate">
        {{surveyCompany.name}}
      </p>

    </div>
    <div class="mt-2 flex items-center justify-between">
      <div class="sm:flex">
        <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">-->
  <!-- Heroicon name: solid/location-marker -->
  <!--<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
          </svg>
          {{surveyCompany.country}}
        </p>
      </div>
    </div>
  </div>-->
</template>

<script>

import { HTTP } from "@/http.js";

export default ({
  props:
      {
        project: {
          type: Object
        },
        surveyCompany: {
          type: Object
        }
      },
  data ()
  {
    return { inviting: false };
  },
  computed: {
    currentProposalType ()
    {
      if (this.project.applications)
      {
        const application = this.project.applications.filter(app => app.idSurveyCompany === this.surveyCompany.id);
        if (application.length > 0)
        {
          const maxtimestamp = Math.max(...application[0].proposals.map(prop => prop.timestamp));
          const proposal = application[0].proposals.find(prop => prop.timestamp === maxtimestamp);
          return proposal.type;
        }
        else
        {
          return null;
        }
      }
      else
      {
        return null;
      }
    }
  },
  methods: {
    inviteToApply (id)
    {
      this.inviting = true;
      HTTP.post(`/client/projects/${this.project.id}/applications/${id}/invite`, { proposal: { type: "INVITE" } })
        .then(response =>
        {
          this.$emit("update:project", (response));
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        })
        .finally(() =>
        {
          this.inviting = false;
        });
    },
    contractorClick ()
    {
      if (this.currentProposalType)
      {
        this.$router.push({ path: this.$route.path, query: { ...this.$route.query, idContractor: this.surveyCompany.id } });
      }
    }
  }

});
</script>
