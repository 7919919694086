<template>
  <div class="divide-y divide-gray-200">
    <change-password />
    <active-sessions />
  </div>
</template>

<script>

import ActiveSessions from "./ActiveSessions.vue";
import ChangePassword from "./ChangePassword.vue";

export default {
  components:
    {
      ActiveSessions,
      ChangePassword
    }
};
</script>
