export const constants = Object.freeze({

  DATE_WEBSITE_COMPATIBILITY: "2021-03-14",

  LANGUAGES: [
    { code: "en", name: "English" }
  ],

  GOOGLE_API_KEY: "AIzaSyBzFgHo2uuM7bbTQNtnnWmhA7nA_MbPov8",
  MAPBOX_API_KEY: "pk.eyJ1Ijoic2lncmF4IiwiYSI6ImNrMzMzeGdmaTBxbGczY28zbmo0bjBqZngifQ.FeNx73DeyEIUkh0fkGKKzA",

  NOTIFICATION_TIMEOUT: 3000

});
