<template>
  <div>
    <div class="py-6 px-4 sm:p-6">

      <h2 class="ugi-section-title">{{ $t('profile') }}</h2>

      <div class="grid grid-cols-12 gap-6">
        <ugi-form-field-with-title class="col-span-12" :title="$t('email')">
          <input type="text" disabled="true" name="email" v-model="user.email" class="ugi-input"/>
        </ugi-form-field-with-title>

        <ugi-form-field-with-title class="col-span-12 sm:col-span-6" :title="$t('firstName')" :error="errors.firstName[0]" :showErrors="true">
          <input type="text" name="firstName" id="first_name" autocomplete="given-name" class="ugi-input" v-model="user.firstName" :class="{'ugi-input-invalid' : errors.firstName.length>0 }"/>
        </ugi-form-field-with-title>

        <ugi-form-field-with-title class="col-span-12 sm:col-span-6" :title="$t('lastName')" :error="errors.lastName[0]" :showErrors="true">
          <input type="text" name="lastName" id="lastName" autocomplete="family-name" class="ugi-input" v-model="user.lastName" :class="{'ugi-input-invalid' : errors.lastName.length>0 }"/>
        </ugi-form-field-with-title>

        <ugi-form-field-with-title class="col-span-12 sm:col-span-6" :title="$t('language')">
          <select class="ugi-select">
            <option v-for="lang in $constants.LANGUAGES" :key="lang.code" :value="lang.code">{{lang.name}}</option>
          </select>
        </ugi-form-field-with-title>
      </div>
      <transition-expand :visible="currentError !== ''">
        <div class="my-4 self-center text-red-500">
          {{ $t(currentError) }}
        </div>
      </transition-expand>
    </div>
    <div class="mt-4 py-4 px-4 flex justify-end sm:px-6 bg-gray-50">
      <ugi-button :text="$t('save')" :loadingText="$t('saving')" :loading="loading" :disabled="!isModified || loading" @click="saveProfile"/>
    </div>
  </div>
</template>

<script>

import { cloneObject } from "@/helpers";

export default {
  created ()
  {
    this.user = cloneObject(this.$store.state.currentUser);
  },
  data ()
  {
    return {
      loading: false,
      user: null,
      currentError: ""
    };
  },
  computed:
    {
      isModified ()
      {
        return (JSON.stringify(this.user) !== JSON.stringify(this.$store.state.currentUser));
      },
      errors ()
      {
        const tmp = {};
        tmp.firstName = [];
        tmp.lastName = [];
        tmp.language = [];
        if (this.user.firstName.length === 0) tmp.firstName.push("invalidFieldRequired");
        if (this.user.lastName.length === 0) tmp.lastName.push("invalidFieldRequired");
        if (this.user.language.length === 0) tmp.language.push("invalidFieldRequired");
        return tmp;
      }
    },
  methods: {
    saveProfile ()
    {
      this.currentError = "";
      let valid = true;
      Object.entries(this.errors).forEach(([key, value]) =>
      {
        if (value.length > 0)
        {
          valid = false;
        }
      });
      if (valid)
      {
        const profile = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          language: this.user.language
        };
        this.loading = true;
        this.$store.dispatch("putUser", profile)
          .catch(error =>
          {
            this.currentError = error;
          })
          .finally(() =>
          {
            this.loading = false;
          });
      }
    }
  }
};
</script>
