<template>
  <div class="md:flex md:items-center py-2 pl-4 space-y-2 md:space-y-0 md:space-x-8 bg-gray-100 rounded-lg m-2 overflow-y-auto">
    <template v-for="(step, index) in steps" class="md:flex md:items-center">

      <div class="flex items-center space-x-3 flex-shrink-0" :key="index">
        <div class="px-1">
          <div class="h-8 w-8 bg-white border rounded-full ring-white flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 24 24" fill="currentColor" style="fill-rule:evenodd">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="step.svgPath" />
            </svg>
          </div>
        </div>
        <div class="min-w-0 flex-1">
          <div>
            <a class="text-sm text-gray-900 cursor-pointer" :class="selectedStep === index ? 'font-bold' : ''" @click="$emit('clickItem', index)">{{ step.title }}</a>
            <p class="text-sm text-gray-500" :class="selectedStep === index ? 'font-bold' : ''">{{ epochToStringHumanReadable(step.timestamp) }}</p>
          </div>
        </div>
      </div>

      <div v-if="(index !== steps.length - 1)" class="hidden md:block h-5 w-3" aria-hidden="true" :key="index + '-'">
        <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
          <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
        </svg>
      </div>

    </template>
  </div>
</template>

<script>
import { epochToStringHumanReadable } from "@/helpers.js";

export default ({
  props: {
    steps: {
      type: Array
    },
    selectedStep: {
      type: Number,
      default: null
    }
  },
  methods: {
    epochToStringHumanReadable: epochToStringHumanReadable
  }
});
</script>
