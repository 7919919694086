<template>
  <main-logged-in :title="title" :breadcrumbs="breadcrumbs">
    <template v-if="project !== null">
      <ugi-step-bar :steps="steps" :currentStep="currentStep" :selectedStep="selectedStep" @click="changeStep($event)" />
      <template v-if="!loading">
        <router-view :project.sync="project" :application="project.application" />
      </template>
    </template>
    <div v-if="currentError">
      <p class="max-w-3xl mt-4 mx-auto text-center text-xl leading-9 font-medium text-gray-500">
        {{$t(currentError)}}
      </p>
    </div>
  </main-logged-in>
</template>

<script>

import MainLoggedIn from "@/common/MainLoggedIn.vue";
import { HTTP } from "@/http.js";

export default {
  components: {
    MainLoggedIn
  },
  data ()
  {
    return {
      project: null,
      loading: false,
      steps: [
        { title: this.$t("Project details"), urlStep: "details" },
        { title: this.$t("Proposal"), urlStep: "proposals" },
        { title: this.$t("Execution"), urlStep: "execution" },
        { title: this.$t("Final delivery"), urlStep: "results" }
      ],
      currentError: ""
    };
  },
  computed: {
    currentStep ()
    {
      if (this.project.application.proposals.map(p => p.type).includes("APPROVAL"))
      {
        return this.steps.findIndex(a => a.urlStep === "results");
      }
      else
      {
        return this.steps.findIndex(a => a.urlStep === "proposals");
      }
    },
    title ()
    {
      if (this.project === null)
      {
        return null;
      }
      else
      {
        return this.project.basicDetails.name;
      }
    },
    breadcrumbs ()
    {
      return [
        { title: this.$t("Projects"), path: "/projects-survey-company" },
        { title: this.title, path: null }
      ];
    }
  },
  mounted ()
  {
    const id = this.$route.params.idProject;
    const idSurveyCompany = this.$store.state.currentSurveyCompany.id;
    this.loading = true;
    HTTP.get(`/contractor/projects/${id}?idSurveyCompany=${idSurveyCompany}`)
      .then(response =>
      {
        this.project = response;
      })
      .catch(error =>
      {
        this.currentError = error;
      })
      .finally(() =>
      {
        this.loading = false;
      });
    this.recalculateStep();
  },
  watch: {
    $route (to, from)
    {
      this.recalculateStep();
    }
  },
  methods:
  {
    changeStep (event)
    {
      if (parseInt(event) <= this.currentStep)
      {
        this.$router.push(this.steps[event].urlStep);
        this.recalculateStep();
      }
    },
    recalculateStep ()
    {
      const fullPath = this.$router.currentRoute.path;
      const localPath = fullPath.substring(fullPath.lastIndexOf("/") + 1);
      this.selectedStep = this.steps.findIndex(a => a.urlStep === localPath); ;
    }
  }
};
</script>
