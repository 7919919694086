<template>
  <div class="p-6 grid sm:grid-cols-12 gap-6">
    <ugi-form-field-with-title class="col-span-12"
                               :title="$t('Site risks')"
                               :error="getErrorDescription('siteRisks', validations)" :showErrors="showErrors"
                               :class="{'ugi-input-invalid' : validations.siteRisks.$invalid && showErrors}">
      <ugi-switch :label="$t('Are live pipes or vital cables (e.g. communications) expected in the survey area?')"
                  :value="statusInArray('siteRisks', 'LIVE_PIPES')" @input="toggleInArray('siteRisks', 'LIVE_PIPES')" />
      <ugi-switch :label="$t('Are there any instrument cables connected to sensing elements?')"
                  :value="statusInArray('siteRisks', 'SENSING_ELEMENTS_CABLES')" @input="toggleInArray('siteRisks', 'SENSING_ELEMENTS_CABLES')" />
      <ugi-switch :label="$t('Are there any instrument cables connected to a safeguarding system?')"
                  :value="statusInArray('siteRisks', 'SAFEGUARDING_CABLES')" @input="toggleInArray('siteRisks', 'SAFEGUARDING_CABLES')"/>
      <ugi-switch :label="$t('Are there trip or fall hazards in the area?')"
                  :value="statusInArray('siteRisks', 'TRIP_HAZARDS')" @input="toggleInArray('siteRisks', 'TRIP_HAZARDS')"/>
      <ugi-switch :label="$t('Are there any confined spaces in the area?')"
                  :value="statusInArray('siteRisks', 'CONFINED_SPACES')" @input="toggleInArray('siteRisks', 'CONFINED_SPACES')"/>
      <ugi-switch :label="$t('Are there any prohibited zones?')"
                  :value="statusInArray('siteRisks', 'PROHIBITED_ZONES')" @input="toggleInArray('siteRisks', 'PROHIBITED_ZONES')"/>
      <ugi-switch :label="$t('Are any other activities ongoing in the same area?')"
                  :value="statusInArray('siteRisks', 'OTHER_ACTIVITIES_ONGOING')" @input="toggleInArray('siteRisks', 'OTHER_ACTIVITIES_ONGOING')"/>
      <ugi-switch :label="$t('Are there any planned activities (safety drills, visitors etc.)')"
                  :value="statusInArray('siteRisks', 'PLANNED_ACTIVITIES')" @input="toggleInArray('siteRisks', 'PLANNED_ACTIVITIES')"/>
      <ugi-switch :label="$t('Is there a risk of vehicle impact on person')"
                  :value="statusInArray('siteRisks', 'VEHICLE_IMPACT_RISK')" @input="toggleInArray('siteRisks', 'VEHICLE_IMPACT_RISK')"/>
      <ugi-switch :label="$t('Is there a risk of vehicle collision or rollover')"
                  :value="statusInArray('siteRisks', 'COLLISION_RISK')" @input="toggleInArray('siteRisks', 'COLLISION_RISK')"/>
      <ugi-switch :label="$t('Is there a risk of fall from height')"
                  :value="statusInArray('siteRisks', 'FALL_RISK')" @input="toggleInArray('siteRisks', 'FALL_RISK')"/>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12"
                               :title="$t('What is the hazardous area classification?')"
                               :error="getErrorDescription('hazardousAreaClassification', validations)" :showErrors="showErrors">
      <input type="number"
             :value="basicDetails.hazardousAreaClassification"
             @input="setProp('hazardousAreaClassification', parseFloat($event.srcElement.value))"
             :class="{'ugi-input-invalid' : validations.hazardousAreaClassification.$invalid && showErrors}"
             class="ugi-input"/>
    </ugi-form-field-with-title>

  </div>
</template>

<script>

import { cloneObject, getErrorDescription } from "@/helpers.js";

export default {
  props: {
    basicDetails: {
      type: Object
    },
    showErrors: {
      type: Boolean
    },
    validations: {
      type: Object
    }
  },
  methods: {
    getErrorDescription: getErrorDescription,
    toggleInArray (prop, value)
    {
      let arr = cloneObject(this.basicDetails[prop]);
      if (arr === undefined || arr === null)
      {
        arr = [];
      }
      if (!arr.includes(value))
      {
        arr.push(value);
      }
      else
      {
        arr = arr.filter(a => a !== value);
      }
      this.setProp(prop, arr);
    },
    statusInArray (prop, value)
    {
      if (this.basicDetails[prop] !== undefined && this.basicDetails[prop] !== null)
      {
        return this.basicDetails[prop].includes(value);
      }
    },
    setProp (prop, value)
    {
      this.$emit("propChanged", { propName: prop, value: value });
    }
  }

};
</script>
