import router from "@/router";
import { template, templateSettings } from "lodash";
templateSettings.interpolate = /{{([\s\S]+?)}}/g;

export function getTotalBudgetFromProposal (proposal)
{
  let totalBudget = 0;
  if (proposal.budgetOthers)
  {
    for (const price of Object.values(proposal.budgetOthers))
    {
      totalBudget += price;
    }
  }
  if (proposal.budgetGprAcquisition) totalBudget += proposal.budgetGprAcquisition;
  if (proposal.budgetGprProcessing) totalBudget += proposal.budgetGprProcessing;
  if (proposal.budgetEmlAcquisition) totalBudget += proposal.budgetEmlAcquisition;
  if (proposal.budgetEmlProcessing) totalBudget += proposal.budgetEmlProcessing;
  if (proposal.budgetLidarAcquisition) totalBudget += proposal.budgetLidarAcquisition;
  if (proposal.budgetLidarProcessing) totalBudget += proposal.budgetLidarProcessing;
  if (proposal.budgetObjectSurveyAcquisition) totalBudget += proposal.budgetObjectSurveyAcquisition;
  if (proposal.budgetObjectSurveyProcessing) totalBudget += proposal.budgetObjectSurveyProcessing;
  if (proposal.budgetManualManholeAcquisition) totalBudget += proposal.budgetManualManholeAcquisition;
  if (proposal.budgetManualManholeProcessing) totalBudget += proposal.budgetManualManholeProcessing;
  if (proposal.budgetTrenchDiggingAcquisition) totalBudget += proposal.budgetTrenchDiggingAcquisition;
  if (proposal.budgetTrenchDiggingProcessing) totalBudget += proposal.budgetTrenchDiggingProcessing;
  if (proposal.budgetMobilisation) totalBudget += proposal.budgetMobilisation;
  if (proposal.budgetReporting) totalBudget += proposal.budgetReporting;
  return totalBudget;
}

export function getErrorDescription (propName, validationModel)
{
  const valid = validationModel[propName];
  let tmp;
  if (valid !== undefined)
  {
    tmp = Object.keys(valid.$params).reduce(
      (errors, validator) =>
      {
        if (!valid[validator])
        {
          const compiled = template(router.app.$t("invalid_" + validator));
          errors.push(compiled(valid.$params[validator]));
        }
        return errors;
      }, []);
  }
  else
  {
    tmp = [];
  }
  if (tmp.length > 0)
  {
    return tmp[0];
  }
  else
  {
    return "";
  }
}

export function getNewUuid ()
{
  function randomDigit ()
  {
    if (crypto && crypto.getRandomValues)
    {
      var rands = new Uint8Array(1);
      crypto.getRandomValues(rands);
      return (rands[0] % 16).toString(16);
    }
    else
    {
      return ((Math.random() * 16) | 0).toString(16);
    }
  }
  var crypto = window.crypto || window.msCrypto;
  return "xxxxxxxx-xxxx-4xxx-8xxx-xxxxxxxxxxxx".replace(/x/g, randomDigit);
}

export function isStringEmail (s)
{
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(s).toLowerCase());
}

export function checkPasswordError (s)
{
  if (s.length < 8)
  {
    return "invalidPassAtLeastEight";
  }
  else if (s === s.toLowerCase())
  {
    return "invalidPassAtLeastOneUppercase";
  }
  else if (!/[\d]{1}/.test(s))
  {
    return "invalidPassAtLeastOneDigit";
  }
  else if (!/[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/.test(s))
  {
    return "invalidPassAtLeastOneSymbol";
  }
  else
  {
    return null;
  }
}

export function cloneObject (src)
{
  if (src === undefined)
  {
    return undefined;
  }
  return JSON.parse(JSON.stringify(src));
}

export function epochToStringHumanReadable (utcDate)
{
  const date = new Date(utcDate).toLocaleDateString([], { year: "numeric", month: "numeric", day: "numeric" });
  return date;
}

export function epochToStringInputDate (epoch)
{
  const zeroPad = (num, places) => String(num).padStart(places, "0");
  var d = new Date(0);
  d.setUTCMilliseconds(epoch);
  return d.getFullYear() + "-" + zeroPad(d.getMonth() + 1, 2) + "-" + zeroPad(d.getDate(), 2);
}

export function convertUnit (value, originUnit, destinationUnit)
{
  let valueMeters;
  if (originUnit === "CM") valueMeters = Number(value) / 100;
  else if (originUnit === "IN") valueMeters = Number(value) * 2.54 / 100;
  else if (originUnit === "FT") valueMeters = Number(value) * 0.3048;
  else if (originUnit === "M") valueMeters = Number(value);

  if (destinationUnit === "FT") return valueMeters / 0.3048;
  if (destinationUnit === "CM") return valueMeters * 100;
  if (destinationUnit === "IN") return valueMeters / 2.54 * 100;
  if (destinationUnit === "M") return valueMeters;
  return null;
}
