<template>
  <div class="p-6 grid sm:grid-cols-12 gap-6">
    <ugi-form-field-with-title class="col-span-12" :title="$t('Project name')" :error="getErrorDescription('name', validations)" :showErrors="showErrors">
      <input type="text" :value="basicDetails.name" @input="setProp('name', $event.srcElement.value)" class="ugi-input"
             :class="{'ugi-input-invalid' : validations.name.$invalid && showErrors}"/>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12" :title="$t('Address')"
                               :error="getErrorDescription('address', validations)" :showErrors="showErrors">
      <ugi-input-address class="ugi-input"
                         :address.sync="basicDetails.address"
                         :latitude.sync="basicDetails.latitude"
                         :longitude.sync="basicDetails.longitude"
                         :class="{'ugi-input-invalid' : validations.address.$invalid && showErrors}" />
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12" :title="$t('Additional location details')" :subtitle="$t('optional')">
      <input type="text" id="address-line-2" class="ugi-input" :value="basicDetails.addressLine2" @input="setProp('addressLine2', $event.srcElement.value)"
             :class="{'ugi-input-invalid' : validations.addressLine2.$invalid && showErrors}"/>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12" :title="$t('Survey perimeter')" :error="getErrorDescription('coordinates', validations)" :showErrors="showErrors">
      <ugi-map class="col-span-12" style="height: 300px" :value="basicDetails.coordinates" :latitude="basicDetails.latitude" :longitude="basicDetails.longitude" @input="setProp('coordinates', $event)" />
    </ugi-form-field-with-title>
  </div>
</template>

<script>

import UgiInputAddress from "@/generic/UgiInputAddress.vue";
import UgiMap from "@/generic/UgiMap.vue";
import { getErrorDescription } from "@/helpers";

export default {
  components: {
    UgiInputAddress,
    UgiMap
  },
  props: {
    basicDetails: {
      type: Object
    },
    showErrors: {
      type: Boolean
    },
    validations: {
      type: Object
    }
  },
  data ()
  {
    return {
    };
  },
  methods: {
    getErrorDescription: getErrorDescription,
    setProp (prop, value)
    {
      this.$emit("propChanged", { propName: prop, value: value });
    }
  }
};
</script>
