<template>
  <portal to="popup-panel-placer" >
    <transition name="bounce">
      <div class="bg-black bg-opacity-30 flex items-center justify-center h-full">

        <!--Card-->
        <div class="max-h-full bg-white w-11/12 mx-auto rounded shadow-lg z-50 md:max-w-2xl flex flex-col">
          <!--Title-->
          <div class="px-4 py-6 bg-gray-50 rounded-t sm:px-6 flex-grow">
            <div class="flex items-start justify-between space-x-3">
              <div class="space-y-1">
                <h2 class="text-lg font-medium text-gray-900" id="slide-over-title">
                  {{title}}
                </h2>
                <p class="text-sm text-gray-500">
                  {{subtitle}}
                </p>
              </div>
              <div class="h-7 flex items-center">
                <button type="button" @click="$emit('cancel', false)" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ugi-primary">
                  <span class="sr-only">Close panel</span>
                  <!-- Heroicon name: outline/x -->
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <!--Content-->
          <div class="flex-shrink overflow-y-auto" >
            <slot />
          </div>

          <!--Footer-->
          <div class="flex justify-end bg-gray-50 rounded-b flex-grow p-4" >
            <slot name="buttons"/>
            <div class="space-x-2">
              <ugi-button :text="positiveLabelInternal" :loading="loadingPositive" @click="confirm" />
            </div>
          </div>

        </div>
      </div>
    </transition>
  </portal>
</template>

<script>

export default ({
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    positiveLabel: {
      type: String,
      default: null
    },
    loadingPositive:
    {
      type: Boolean,
      default: false
    }
  },
  data ()
  {
    return {
      positiveLabelInternal: null
    };
  },
  beforeMount ()
  {
    if (this.positiveLabel === null)
    {
      this.positiveLabelInternal = this.$t("ok");
    }
    else
    {
      this.positiveLabelInternal = this.positiveLabel;
    }
  },
  methods: {
    confirm ()
    {
      this.$emit("confirm");
    }
  }
});
</script>

<style scoped>

  .bounce-enter-active {
    animation: bounce-in .2s;
  }

  .bounce-leave-active {
    animation: bounce-in .15s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

</style>
