<template>
  <input type="text" ref="autocomplete" placeholder="" />
</template>

<script>

import { Loader } from "@googlemaps/js-api-loader";

export default {
  props: {
    address: {
      type: String
    },
    latitude: {
      type: Number
    },
    longitude: {
      type: Number
    }
  },
  methods: {

  },
  mounted ()
  {
    if (this.address)
    {
      this.$refs.autocomplete.value = this.address;
    }

    const loader = new Loader({
      apiKey: this.$constants.GOOGLE_API_KEY,
      version: "weekly",
      libraries: ["places"]
    });

    loader.load().then(() =>
    {
      const autocomplete = new window.google.maps.places.Autocomplete(/** @type {!HTMLInputElement} */(this.$refs.autocomplete), { types: ["address"] });
      autocomplete.addListener("place_changed", () =>
      {
        const place = autocomplete.getPlace();
        this.$emit("update:address", place.formatted_address);
        this.$emit("update:latitude", place.geometry.location.lat());
        this.$emit("update:longitude", place.geometry.location.lng());
      });
    });
  }
};
</script>
