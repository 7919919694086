<template>
  <ugi-main-section :title="$t('Project execution')">
    <div slot="buttons">
      <button class="ugi-button-light" @click="$router.push('results');">{{ $t('Go to final delivery') }}</button>
    </div>
    <div class="">
      <p class="ugi-empty-list-legend">Here users will have access to the Geolitix platform</p>
    </div>
  </ugi-main-section>
</template>

<script>

export default {
  components: {
  },
  props: {
    project: {
      type: Object
    }
  },
  beforeMount ()
  {

  },
  mounted ()
  {

  },
  data ()
  {
    return {

    };
  },
  computed: {

  },
  methods: {
  }
};
</script>
