<template>
  <div class="p-6 grid sm:grid-cols-12">
    <ugi-form-field-with-title class="col-span-12 mb-6" :title="$t('Field Type')" :error="getErrorDescription('fieldType', validations)" :showErrors="showErrors" >
      <span class="relative z-0 inline-flex shadow-sm rounded-md">
        <button type="button"
                class="ugi-button-grouped-left"
                :class="[{'ugi-button-grouped-invalid' : validations.fieldType.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.fieldType === 'GREENFIELD'}]"
                :value="basicDetails.fieldType"
                @click="setProp('fieldType', 'GREENFIELD')" >
          {{ $t("Greenfield") }}
        </button>
        <button type="button"
                class="ugi-button-grouped-right"
                :class="[{'ugi-button-grouped-invalid' : validations.fieldType.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.fieldType === 'BROWNFIELD'}]"
                :value="basicDetails.fieldType"
                @click="setProp('fieldType', 'BROWNFIELD')">
          {{ $t("Brownfield") }}
        </button>
      </span>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12 lg:col-start-1 lg:col-end-7 mb-6" :title="$t('Soil type')" :error="getErrorDescription('soilType', validations)" :showErrors="showErrors" >
      <select class="ugi-select"
              :value="basicDetails.soilType"
              :class="{'ugi-input-invalid' : validations.soilType.$invalid && showErrors}"
              @input="setProp('soilType', $event.srcElement.value)">
        <option value="BEDROCK">{{ $t('soilType_BEDROCK') }}</option>
        <option value="SAND">{{ $t('soilType_SAND') }}</option>
        <option value="SILT">{{ $t('soilType_SILT') }}</option>
        <option value="CLAY">{{ $t('soilType_CLAY') }}</option>
        <option value="LOAMY">{{ $t('soilType_LOAMY') }}</option>
        <option value="UNKNOWN">{{ $t('soilType_UNKNOWN') }}</option>
      </select>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12 mb-6" :title="$t('Have similar previous surveys been performed on this site?')">
      <ugi-switch label="" :value="basicDetails.previousProjects" @input="setProp('previousProjects', !basicDetails.previousProjects)"/>
      <transition-expand :visible="basicDetails.previousProjects" class="grid sm:grid-cols-12">
        <!-- placeholder for previous project details -->
      </transition-expand>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12 mb-6" :title="$t('Additional questions')" >
      <ugi-switch :label="$t('Is soil contamination expected?')" :value="basicDetails.soilContamination" @input="setProp('soilContamination', !basicDetails.soilContamination)" />
      <ugi-switch :label="$t('Will the survey area be subject to vehicle traffic?')" :value="basicDetails.vehicleTraffic" @input="setProp('vehicleTraffic', !basicDetails.vehicleTraffic)" />
      <ugi-switch :label="$t('Are there overhead obstructions?')" :value="basicDetails.overheadObstructions" @input="setProp('overheadObstructions', !basicDetails.overheadObstructions)" />
      <ugi-switch :label="$t('Is soil or groundwater salinity expected?')" :value="basicDetails.soilSalinity" @input="setProp('soilSalinity', !basicDetails.soilSalinity)" />
      <ugi-switch :label="$t('Is the water table expected within the depth range of interest?')" :value="basicDetails.expectedWaterTable" @input="setProp('expectedWaterTable', !basicDetails.expectedWaterTable)"/>
    </ugi-form-field-with-title>

    <transition-expand :visible="basicDetails.expectedWaterTable" class="col-span-12 lg:col-span-6">
      <ugi-form-field-with-title :title="$t('Water table depth')" :subtitle="$t('optional')">
        <ugi-input-with-unit :value="basicDetails.depthWaterTable"
                             @input="setProp('depthWaterTable', $event)"
                             :unit="basicDetails.unitDepth"
                             :class="{'ugi-input-invalid' : validations.depthWaterTable.$invalid && showErrors}" />
      </ugi-form-field-with-title>
    </transition-expand>
  </div>
</template>

<script>

import { getErrorDescription } from "@/helpers.js";

export default {
  props: {
    basicDetails: {
      type: Object
    },
    showErrors: {
      type: Boolean
    },
    validations: {
      type: Object
    }
  },
  methods: {
    getErrorDescription: getErrorDescription,
    setProp (prop, value)
    {
      this.$emit("propChanged", { propName: prop, value: value });
    }
  }
};
</script>
