<template>
  <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-4 sm:pr-0">
    <button @click="isOpen = !isOpen" class="bg-white p-1 rounded-full text-ugi-primary hover:text-ugi-primary-dark focus:outline-none">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
      </svg>
    </button>
    <transition enter-active-class="transition ease-out duration-100 transform"
                enter-class="opacity-0 scale-95"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75 transform"
                leave-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-95">
      <div v-show="isOpen" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
        <div class="rounded-md bg-white shadow-xs">
          <div class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <a :class="{'ugi-element-disabled' : loading}" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer" role="menuitem" @click="signOut">{{ $t('signOut') }}</a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>

export default {
  data: () => ({
    isOpen: false,
    loading: false
  }),
  methods: {
    signOut ()
    {
      const returnUrl = window.location.origin + "/" + this.$router.resolve({ path: "login" }).href;
      const identityProviderLogoutUrl = "https://dev-aug52obt.us.auth0.com/v2/logout?returnTo=" + escape(returnUrl);

      this.loading = true;
      this.$store.dispatch("deleteSessionCurrent")
        .then(response =>
        {
          window.location.replace(identityProviderLogoutUrl);
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
          this.currentError = this.$t(error.data);
        })
        .finally(() =>
        {
          this.loading = false;
        });
    }
  }
};
</script>
