<template>
  <main-logged-in :title="$t('Survey Companies')">
    <div slot="buttons">
      <ugi-button :text="$t('Add new company')" classButton="ugi-button-light" @click="newCompany" />
    </div>
    <ugi-main-section :showPagination="true">
      <div v-for="company in companies" :key="company.id" >
        <div class="px-4 py-4 sm:px-6 flex">

          <div class="flex-grow">
            <div class="flex items-center justify-between">
              <p class="font-medium truncate">
                {{ company.name }}
              </p>
            </div>
            <div class="mt-2 flex items-center justify-between">
              <div class="sm:flex">
                <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                  <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  {{ company.emailAdmin }}
                </p>
              </div>
            </div>
          </div>

          <div>
            <ugi-dropdown >
              <div>
                <ugi-dropdown-button :label="$t('Edit')" @click="editCompany(company.id)" svgPath="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
              </div>
            </ugi-dropdown>
          </div>

        </div>
      </div>
    </ugi-main-section>

  </main-logged-in>
</template>

<script>

import MainLoggedIn from "@/common/MainLoggedIn.vue";
import { HTTP } from "@/http.js";
import UgiDropdown from "@/generic/dropdown/UgiDropdown.vue";

export default {
  components: {
    MainLoggedIn,
    UgiDropdown
  },
  data ()
  {
    return {
      companies: []
    };
  },
  async created ()
  {
    this.companies = await HTTP.get("/survey-companies");
  },
  methods: {
    newCompany ()
    {
      this.$router.push("/companies/new");
    },
    editCompany (idSurveyCompany)
    {
      this.$router.push(`/companies/${idSurveyCompany}`);
    }
  }
};
</script>
