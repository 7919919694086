<template>
  <ol class="ugi-card divide-y divide-gray-300 md:flex md:divide-y-0">
    <li v-for="(step, index) in steps" :key="index" class="relative md:flex-1 md:flex" @click="$emit('click', index)">

      <a v-if="selectedStep === index" class="group flex items-center w-full group-hover:text-ugi-primary-dark cursor-pointer">
        <span class="px-6 py-4 flex items-center">
          <span class="ml-4 text-ugi-primary font-bold">{{ step.title }}</span>
        </span>
      </a>
      <a v-else-if="index <= currentStep" class="group flex items-center w-full group-hover:text-ugi-primary-dark cursor-pointer">
        <span class="px-6 py-4 flex items-center">
          <span class="ml-4 text-gray-900">{{ step.title }}</span>
        </span>
      </a>
      <a v-else class="group flex items-center">
        <span class="px-6 py-4 flex items-center">
          <span class="ml-4 text-gray-400">{{ step.title }}</span>
        </span>
      </a>
      <template v-if="(index !== steps.length - 1)">
        <!-- Arrow separator for lg screens and up -->
        <div class="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
          <svg class="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
            <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round" />
          </svg>
        </div>
      </template>
    </li>
  </ol>
</template>

<script>
export default ({
  props: {
    steps: {
      type: Array
    },
    currentStep: {
      type: Number
    },
    selectedStep: {
      type: Number
    }
  }
});
</script>
