import axios from "axios";
import Vue from "vue";
import Router from "@/router";

let baseURL;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
{
  baseURL = "http://" + window.location.hostname + ":5000/api";
}
else
{
  baseURL = "https://" + window.location.hostname + "/api";
}

export const HTTP = axios.create(
  {
    baseURL: baseURL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    }
  });

HTTP.interceptors.response.use(
  function (response)
  {
    return response.data;
  },
  function (error)
  {
    if (error.response !== undefined && error.response.status !== undefined && error.response.status === 401)
    {
      Vue.$cookies.remove("logged_in");
      Router.push("/login?redirect=" + Router.currentRoute.fullPath);
      throw (error.response.data);
    }
    else
    {
      if (error.response === undefined)
      {
        throw ("errorNetwork");
      }
      else if (error.response.status === 500)
      {
        throw ("errorUnknown");
      }
      else
      {
        throw (error.response.data);
      }
    }
  }
);
