<template>
  <div class="p-6 grid sm:grid-cols-12 gap-6">
    <ugi-form-field-with-title class="col-span-6"
                               :title="$t('Expected report delivery date')"
                               :error="getErrorDescription('expectedDeliveryDate', validations)" :showErrors="showErrors">
      <input type="date"
             :value="basicDetails.expectedDeliveryDate"
             :min="today"
             @input="setProp('expectedDeliveryDate', $event.srcElement.value)"
             :class="{'ugi-input-invalid' : validations.expectedDeliveryDate.$invalid && showErrors}"
             class="ugi-input"/>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12"
                               :title="$t('Quotation type')"
                               :error="getErrorDescription('quotationType', validations)" :showErrors="showErrors">
      <span class="relative z-0 inline-flex shadow-sm rounded-md">
        <button type="button"
                class="ugi-button-grouped-left"
                :class="[{'ugi-button-grouped-invalid' : validations.quotationType.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.quotationType === 'BUDGET'}]"
                :value="basicDetails.quotationType"
                @click="setProp('quotationType', 'BUDGET')">
          {{ $t("Budget quotation") }}
        </button>
        <button type="button"
                class="ugi-button-grouped-right"
                :class="[{'ugi-button-grouped-invalid' : validations.quotationType.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.quotationType === 'FINAL'}]"
                :value="basicDetails.quotationType"
                @click="setProp('quotationType', 'FINAL')">
          {{ $t("Final quotation") }}
        </button>
      </span>
    </ugi-form-field-with-title>
  </div>
</template>

<script>
import { getErrorDescription, epochToStringInputDate } from "@/helpers.js";

export default {
  props: {
    basicDetails: {
      type: Object
    },
    showErrors: {
      type: Boolean
    },
    validations: {
      type: Object
    }
  },
  methods: {
    getErrorDescription: getErrorDescription,
    epochToStringInputDate: epochToStringInputDate,
    setProp (prop, value)
    {
      this.$emit("propChanged", { propName: prop, value: value });
    }
  },
  computed: {
    today ()
    {
      return epochToStringInputDate(new Date().getTime());
    }
  }
};
</script>
