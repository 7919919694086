<template>

  <div class="min-h-screen max-h-screen bg-white flex items-center justify-center" style=" background-size: cover; background-image: url('https://images.unsplash.com/photo-1583773192743-6fbcc9994e04?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1958&q=80'); ">
    <div class="bg-white bg-opacity-95 overflow-y-auto flex-1 sm:rounded-lg flex flex-col justify-center py-12 px-4 sm:px-6 sm:flex-none sm:px-16" style="box-shadow: 0px 0px 80px #0007">
      <div class="mx-auto w-full max-w-sm sm:w-72 flex flex-col items-center">

        <img class="h-12 w-auto" src="@/assets/logo_drawing.svg" />
        <label class="mt-4 text-gray-700 text-2xl ugi-rounded-font flex">{{ $t('Welcome to UGI') }}</label>
        <label class="text-gray-700 text-lg">{{ $t('Log in to access the platform') }}</label>
        <ugi-button class="w-full mt-10" :text="$t('Log in')" :loadingText="$t('Logging in')" :loading="loading" :disabled="( loading )" @click="loginSaml2" />
      </div>
    </div>
  </div>

</template>

<script>

export default {
  components: {
  },
  data ()
  {
    return {
      loading: false
    };
  },
  mounted ()
  {
    document.addEventListener("keydown", this.keyPressed);

    if (this.$route.query.verificationToken !== undefined)
    {
      this.email = this.$route.query.email;
      this.verifiedEmail = true;
    }
  },
  beforeDestroy ()
  {
    document.removeEventListener("keydown", this.keyPressed);
  },
  methods: {
    keyPressed (event)
    {
      if (event.keyCode === 13)
      {
        this.loginSaml2();
      }
    },
    loginSaml2 ()
    {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
      {
        window.location.href = "https://dev-aug52obt.us.auth0.com/samlp/Ey47qxHmCnNWUYSzB48x24fOlg5SSqxf";
      }
      else
      {
        window.location.href = "https://dev-aug52obt.us.auth0.com/samlp/it8mkc0TdXxuGU9TVTh80Z8P267H5yWX";
      }
    }
  }
};
</script>

<style scoped>

</style>
