<template>
  <div class="group flex rounded-md shadow-sm ">
    <span class="ugi-currency-symbol-prefix">
      {{ unit === null || unit === undefined ? '' : $t(unit + '_SYMBOL') }}
    </span>
    <input type="number" :disabled="disabled" :class="{'ugi-input-invalid' : invalid}" class="ugi-input-with-currency"
           v-bind:value="value" @blur="setValue($event.srcElement.value)"/>
  </div>

</template>

<script>

export default {
  props: {
    value: {
      type: Number,
      default: null
    },
    unit: {
      type: String,
      default: null
    },
    invalid: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setValue (value, a)
    {
      if (value === undefined || value === "")
      {
        if (this.value !== null)
        {
          this.$emit("input", null);
        }
      }
      else
      {
        this.$emit("input", Number(value));
      }
    }
  }
};
</script>
