<template>
  <ugi-main-section :title="$t('Project details')" >

    <div class="divide-y divide-gray-100">
      <ugi-details-field-with-title :title="$t('Address')">
        <p>{{ project.basicDetails.address }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Additional location details')" v-if="project.basicDetails.addressLine2 !== null && project.basicDetails.addressLine2 !== ''">
        <p>{{ project.basicDetails.addressLine2 }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Use case')">
        <p>{{ project.basicDetails.useCase.map(a=> a === 'OTHER' ? project.basicDetails.useCaseOther : $t('useCase_' + a)).join(', ') }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Depth range of interest')">
        <p>{{ +convertUnit(project.basicDetails.depthOfInterestFrom, "M", project.basicDetails.unitDepth).toFixed(3) + ' ' + $t(project.basicDetails.unitDepth + '_SYMBOL') + ' - ' + +convertUnit(project.basicDetails.depthOfInterestTo, "M", project.basicDetails.unitDepth).toFixed(3) + ' ' + $t(project.basicDetails.unitDepth + '_SYMBOL')}}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Field type')">
        <p v-if="project.basicDetails.fieldType === 'GREENFIELD'">{{ $t('Greenfield') }}</p>
        <p v-if="project.basicDetails.fieldType === 'BROWNFIELD'">{{ $t('Brownfield') }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Area details')">
        <p v-if="project.basicDetails.vehicleTraffic">{{ $t('Vehicle traffic is expected') }}</p>
        <p v-if="project.basicDetails.overheadObstructions">{{ $t('Overhead osbtructions are present') }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Soil details')">
        <p>{{ $t('soilType_' + project.basicDetails.soilType) }}</p>
        <p v-if="project.basicDetails.soilContamination">{{ $t('Soil contamination expected') }}</p>
        <p v-if="project.basicDetails.soilSalinity">{{ $t('Soil salinity is expected') }}</p>
        <p v-if="project.basicDetails.expectedWaterTable && !project.basicDetails.depthWaterTable">{{ $t('Water table expected') }}</p>
        <p v-if="project.basicDetails.expectedWaterTable && project.basicDetails.depthWaterTable">{{ $t('Water table expected at ') + (+convertUnit(project.basicDetails.depthWaterTable, "M", project.basicDetails.unitDepth).toFixed(3)) + ' ' + $t(project.basicDetails.unitDepth + '_SYMBOL') }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Survey type')">
        <p v-if="project.basicDetails.gpr === 'YES'">{{ $t('GPR') }}</p>
        <p v-if="project.basicDetails.gpr === 'OPEN'">{{ $t('GPR - open to service provider recommendations') }}</p>
        <p>{{ 'Minimum profile separation ' + (+convertUnit(project.basicDetails.minimumProfileSeparation, "M", project.basicDetails.unitLocation).toFixed(3)) + ' ' + $t(project.basicDetails.unitLocation + '_SYMBOL')}}</p>
        <p v-if="project.basicDetails.eml === 'YES'">{{ $t('EML') }}</p>
        <p v-if="project.basicDetails.eml === 'OPEN'">{{ $t('EML - open to service provider recommendations') }}</p>
        <p v-if="project.basicDetails.lidar === 'YES'">{{ $t('Lidar') }}</p>
        <p v-if="project.basicDetails.lidar === 'OPEN'">{{ $t('Lidar - open to service provider recommendations') }}</p>
        <p v-if="project.basicDetails.objectSurvey === 'YES'">{{ $t('Above-Ground Object Survey') }}</p>
        <p v-if="project.basicDetails.objectSurvey === 'OPEN'">{{ $t('Above-Ground Object Survey - open to service provider recommendations') }}</p>
        <p v-if="project.basicDetails.handManholeSurvey === 'YES'">{{ $t('Manual manhole inspection') }}</p>
        <p v-if="project.basicDetails.handManholeSurvey === 'OPEN'">{{ $t('Manual manhole inspection - open to service provider recommendations') }}</p>
        <p v-if="project.basicDetails.trenchDigging === 'YES'">{{ $t('Trench digging') }}</p>
        <p v-if="project.basicDetails.trenchDigging === 'OPEN'">{{ $t('Trench digging - open to service provider recommendations') }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Files')">
        <ugi-file-uploader v-for="(fileInfo, index) in fileInfoList" :key="index" :fileInfo="fileInfo" @download="downloadFile(fileInfo)" :deleteIcon="false" />
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('PPE requirements')" v-if="project.basicDetails.ppeRequirements !== null">
        <p>{{ project.basicDetails.ppeRequirements.map(a=> a === 'OTHER' ? project.basicDetails.ppeRequirementsOther : $t('ppe_' + a)).join(', ') }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Climate conditions')">
        <p>{{ project.basicDetails.climateConditions }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Regional security considerations')">
        <p>{{ project.basicDetails.regionalSecurityConsiderations }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Permission required to access')">
        <p>{{ project.basicDetails.permissionRequired ? $t('Yes') : $t('No') }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Permit issuer')" v-if="project.basicDetails.permissionRequired">
        <p>{{ project.basicDetails.permitIssuer }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Permit requisites')" v-if="project.basicDetails.permissionRequired">
        <p>{{ project.basicDetails.permitRequisites }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Time frame')">
        <p>{{ project.basicDetails.startDate + ' - ' + project.basicDetails.endDate }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Site risks')" v-if="project.basicDetails.siteRisks !== null">
        <p>{{ project.basicDetails.siteRisks.map(a=> $t('risk_' + a)).join(', ') }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Hazardous area classification')">
        <p>{{ project.basicDetails.hazardousAreaClassification }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Quotation type')">
        <p v-if="project.basicDetails.quotationType === 'BUDGET'">{{ $t('Budget quotation') }}</p>
        <p v-if="project.basicDetails.quotationType === 'FINAL'">{{ $t('Final quotation') }}</p>
      </ugi-details-field-with-title>
      <ugi-details-field-with-title :title="$t('Delivery date')">
        <p>{{ project.basicDetails.expectedDeliveryDate }}</p>
      </ugi-details-field-with-title>
    </div>
  </ugi-main-section>
</template>

<script>

import { HTTP } from "@/http.js";
import { epochToStringHumanReadable, convertUnit } from "@/helpers";

export default {
  props: {
    project: {
      type: Object
    }
  },
  data ()
  {
    return {
      currentError: ""
    };
  },
  computed: {
    errors ()
    {
      const tmp = {};
      tmp.name = [];
      return tmp;
    },
    fileInfoList ()
    {
      const tmp = [];
      if (this.project.basicDetails.files !== undefined && this.project.basicDetails.files !== null)
      {
        this.project.basicDetails.files.forEach(file =>
        {
          tmp.push({
            id: file.id,
            name: file.name
          });
        });
      }
      return tmp;
    }
  },
  methods: {
    epochToStringHumanReadable: epochToStringHumanReadable,
    convertUnit: convertUnit,
    downloadFile (fileInfo)
    {
      HTTP.get(`/client/projects/${this.project.id}/files/${fileInfo.id}`, {})
        .then(response =>
        {
          const link = document.createElement("a");
          link.href = response.urlDownload;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        });
    }
  }
};
</script>

<style scoped>
.list-item {
  @apply px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6
}

.list-item-header {
  @apply text-sm font-medium text-gray-500
}

.list-item-data {
  @apply mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2
}
</style>
