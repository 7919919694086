<template>
  <div>
    <client-projects-edit-proposals-list v-if="$route.query.idContractor === undefined" :project.sync="projectInternal" />
    <client-projects-edit-proposals-details v-else :project.sync="projectInternal" />
  </div>
</template>

<script>
import ClientProjectsEditProposalsList from "./ClientProjectsEditProposalsList.vue";
import ClientProjectsEditProposalsDetails from "./ClientProjectsEditProposalsDetails.vue";

export default {
  props: {
    project: {
      type: Object
    }
  },
  components:
      {
        ClientProjectsEditProposalsList,
        ClientProjectsEditProposalsDetails
      },
  computed:
      {
        projectInternal: {
          get ()
          {
            return this.project;
          },
          set (value)
          {
            this.$emit("update:project", value);
          }
        }
      }
};
</script>
