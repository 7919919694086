<template>
  <li v-if="state === 'WAITING'" @click="$emit('click', $event)">
    <!-- Upcoming Step -->
    <div class="flex items-start">
      <div class="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
        <div class="h-2 w-2 bg-gray-300 rounded-full"/>
      </div>
      <p class="ml-3 font-medium text-gray-400">{{title}}</p>
    </div>
  </li>
  <li v-else @click="$emit('click', $event)">
    <a class="group">
      <span class="flex items-start">
        <span class="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
          <!-- Heroicon name: solid/check-circle -->
          <svg v-if="state === 'DONE'" class="h-full w-full text-ugi-primary group-hover:text-ugi-primary-dark cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
          <svg v-if="state === 'DONE_WAITING'" class="h-full w-full text-gray-500 group-hover:text-gray-700 cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
          </svg>
          <span v-if="state === 'CURRENT'" class="absolute h-4 w-4 rounded-full bg-ugi-secondary"/>
          <span v-if="state === 'CURRENT'" class="relative block w-2 h-2 bg-ugi-primary rounded-full"/>
        </span>
        <span v-if="state === 'DONE'" class="ml-3 font-medium text-gray-600 group-hover:text-gray-900 cursor-pointer">{{title}}</span>
        <span v-if="state === 'DONE_WAITING'" class="ml-3 font-medium text-gray-500 group-hover:text-gray-700 cursor-pointer">{{title}}</span>
        <span v-if="state === 'CURRENT'" class="ml-3 font-medium text-ugi-primary">{{title}}</span>
      </span>
    </a>
  </li>

</template>

<script>

export default ({
  props: {
    title: {
      type: String
    },
    state: {
      type: String
    }
  }
});
</script>
