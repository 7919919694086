<template>
  <div class="w-80 flex items-center">
    <p v-if="fileInfo.file === undefined" class="no-underline" :class="allowDownload ? 'hover:underline cursor-pointer' : ''" @click="titleClick">{{ fileInfo.name }}</p>
    <p v-if="fileInfo.file !== undefined" class="">{{ fileInfo.name }}</p>
    <p class="font-medium ml-2" v-if="fileInfo.file !== undefined">{{ Math.round(fractionUploaded * 100) + ' %' }}</p>
    <button type="button" class="button-icon ml-2" @click="$emit('delete', { })" v-if="fileInfo.file === undefined && deleteIcon">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
      </svg>
    </button>
    <button type="button" class="button-icon ml-2" @click="cancelUpload" v-if="fileInfo.file !== undefined">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  </div>
</template>

<script>

import { HTTP } from "@/http.js";
const axios = require("axios").default;

export default ({
  props: {
    fileInfo: {
      type: Object
    },
    deleteIcon: {
      type: Boolean,
      default: true
    },
    allowDownload: {
      type: Boolean,
      default: true
    }
  },
  data ()
  {
    return {
      fractionUploaded: 0,
      source: null
    };
  },
  mounted ()
  {
    if (this.fileInfo.file !== undefined)
    {
      const cancelToken = axios.CancelToken;
      this.source = cancelToken.source();

      var options = {
        onUploadProgress: progressEvent =>
        {
          this.fractionUploaded = progressEvent.loaded / progressEvent.total;
        },
        cancelToken: this.source.token,
        headers: {
          "Content-Type": "application/octet-stream;"
        },
        withCredentials: false
      };

      HTTP
        .put(this.fileInfo.url, this.fileInfo.file, options)
        .then(response2 =>
        {
          this.$emit("uploaded", { });
        });
    }
  },
  methods: {
    cancelUpload ()
    {
      this.source.cancel();
      this.$emit("delete", {});
    },
    titleClick ()
    {
      if (this.allowDownload)
      {
        this.$emit("download");
      }
    }
  }
});
</script>

<style scoped>
    .button-icon {
        @apply bg-ugi-secondary-light text-ugi-primary p-1 rounded-full h-6 w-6;
    }

    .button-icon:disabled {
        @apply text-gray-300;
    }
</style>
