<template>
  <div>
    <template v-if="project">
      <ugi-main-section :showPagination="true">
        <ul class="divide-y divide-gray-200">
          <client-projects-edit-contractors-list-item v-for="company in surveyCompanies" :key="company.id" :project.sync="projectInternal" :surveyCompany="company" />
        </ul>
      </ugi-main-section>
    </template>
    <div>
      <p class="max-w-3xl mt-4 mx-auto text-center text-xl leading-9 font-medium text-gray-500">
        {{$t(currentError)}}
      </p>
    </div>
  </div>
</template>

<script>
import ClientProjectsEditContractorsListItem from "./ClientProjectsEditContractorsListItem.vue";
import { HTTP } from "@/http.js";

export default {
  props: {
    project: {
      type: Object
    }
  },
  components:
      {
        ClientProjectsEditContractorsListItem
      },
  data ()
  {
    return {
      surveyCompanies: [],
      currentError: ""
    };
  },
  computed:
      {
        projectInternal: {
          get ()
          {
            return this.project;
          },
          set (value)
          {
            this.$emit("update:project", value);
          }
        }
      },
  mounted ()
  {
    HTTP.get("/survey-companies")
      .then(response =>
      {
        this.surveyCompanies = response;
      })
      .catch(error =>
      {
        this.currentError = error;
      });
  },
  methods: {

  },
  watch: {

  }
};
</script>
