<template>
  <div class="ugi-card divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
    <aside class="lg:col-span-3">
      <nav class="flex p-4 sm:p-6 lg:p-8" aria-label="Progress">
        <ol class="space-y-3">
          <ugi-wizard-item v-for="(step, index) in projectSteps" :key="step.title" :title="step.title" :state="state(index + 1)" @click="goToStep(index + 1)" />
        </ol>
      </nav>
    </aside>
    <div class="flex flex-col lg:col-span-9">

      <height-tween switching name='slide-fade' class="">
        <component :is="'client-projects-edit-details-step' + formStep"
                   :key="formStep"
                   :idProject="project.id"
                   :basicDetails.sync="projectEditingBasicDetails"
                   :showErrors="showingErrors"
                   :validations.sync="$v.projectEditingBasicDetails"
                   @propChanged="propChanged" />
      </height-tween>

      <div class="mt-auto py-4 px-4 flex justify-end sm:px-6 space-x-3 bg-gray-50">
        <ugi-button :disabled="formStep === 1" :text="$t('Previous')" @click="previous" classButton="ugi-button-light" />
        <ugi-button v-show="formStep < this.projectSteps.length" :text="$t('next')" @click="next" :loadingText="$t('saving')" :loading="savingNext" />
        <ugi-button v-show="formStep === this.projectSteps.length" :text="$t('Publish')" :loadingText="$t('saving')" :loading="savingPublish && projectEditingBasicDetails.status === 'OPEN'" @click="publish" />
      </div>
    </div>
  </div>
</template>

<script>
import UgiWizardItem from "@/generic/UgiWizardItem.vue";
import { cloneObject, epochToStringInputDate } from "@/helpers.js";
import { HTTP } from "@/http.js";
import { required, requiredIf, minValue } from "vuelidate/lib/validators";

import ClientProjectsEditDetailsStep1 from "./ClientProjectsEditDetailsStep1.vue";
import ClientProjectsEditDetailsStep2 from "./ClientProjectsEditDetailsStep2.vue";
import ClientProjectsEditDetailsStep3 from "./ClientProjectsEditDetailsStep3.vue";
import ClientProjectsEditDetailsStep4 from "./ClientProjectsEditDetailsStep4.vue";
import ClientProjectsEditDetailsStep5 from "./ClientProjectsEditDetailsStep5.vue";
import ClientProjectsEditDetailsStep6 from "./ClientProjectsEditDetailsStep6.vue";
import ClientProjectsEditDetailsStep7 from "./ClientProjectsEditDetailsStep7.vue";
import ClientProjectsEditDetailsStep8 from "./ClientProjectsEditDetailsStep8.vue";

export default {
  components: {
    UgiWizardItem,
    ClientProjectsEditDetailsStep1,
    ClientProjectsEditDetailsStep2,
    ClientProjectsEditDetailsStep3,
    ClientProjectsEditDetailsStep4,
    ClientProjectsEditDetailsStep5,
    ClientProjectsEditDetailsStep6,
    ClientProjectsEditDetailsStep7,
    ClientProjectsEditDetailsStep8
  },
  props: {
    project: {
      type: Object
    }
  },
  validations ()
  {
    return {
      projectEditingBasicDetails: {
        name: { required },
        address: { required },
        addressLine2: { },
        coordinates: { required },
        step1:
        [
          "projectEditingBasicDetails.name",
          "projectEditingBasicDetails.address",
          "projectEditingBasicDetails.addressLine2",
          "projectEditingBasicDetails.coordinates"
        ],
        useCase: {
          required: () =>
          {
            if (Array.isArray(this.projectEditingBasicDetails.useCase) && this.projectEditingBasicDetails.useCase.includes("OTHER"))
            {
              if (this.projectEditingBasicDetails.useCaseOther)
              {
                return true;
              }
              else
              {
                return false;
              }
            }
            else
            {
              if (Array.isArray(this.projectEditingBasicDetails.useCase))
              {
                return this.projectEditingBasicDetails.useCase.length > 0;
              }
              else
              {
                return false;
              }
            }
          }
        },
        unitLocation: { required },
        unitDepth: { required },
        depthOfInterestFrom: {
          minDepth: (value) =>
          {
            if (!isNaN(value)) return value >= 0;
            else return true;
          }
        },
        depthOfInterestTo: {
          required: requiredIf((obj) => (obj.depthOfInterestFrom !== null && obj.depthOfInterestFrom >= 0)),
          minDepth: (value) =>
          {
            if (!isNaN(value)) return value > this.projectEditingBasicDetails.depthOfInterestFrom;
            else return true;
          }
        },
        step2: [
          "projectEditingBasicDetails.unitLocation",
          "projectEditingBasicDetails.unitDepth",
          "projectEditingBasicDetails.useCase",
          "projectEditingBasicDetails.depthOfInterestFrom",
          "projectEditingBasicDetails.depthOfInterestTo"
        ],
        fieldType: { required },
        soilType: { required },
        depthWaterTable: { },
        step3: [
          "projectEditingBasicDetails.fieldType",
          "projectEditingBasicDetails.soilType",
          "projectEditingBasicDetails.depthWaterTable"
        ],
        gpr: { required },
        minimumProfileSeparation: {
          required: requiredIf((obj) =>
          {
            return (obj.gpr === "YES");
          }),
          minValue: minValue(0)
        },
        eml: { required },
        lidar: { required },
        objectSurvey: { required },
        handManholeSurvey: { required },
        trenchDigging: { required },
        step4: [
          "projectEditingBasicDetails.gpr",
          "projectEditingBasicDetails.minimumProfileSeparation",
          "projectEditingBasicDetails.eml",
          "projectEditingBasicDetails.lidar",
          "projectEditingBasicDetails.objectSurvey",
          "projectEditingBasicDetails.handManholeSurvey",
          "projectEditingBasicDetails.trenchDigging"
        ],
        historicalDrawings: { },
        aboveGroundDrawings: { },
        undergroundDrawings: { },
        previousSurveyData: { },
        step5:
      [
        "projectEditingBasicDetails.historicalDrawings",
        "projectEditingBasicDetails.aboveGroundDrawings",
        "projectEditingBasicDetails.undergroundDrawings",
        "projectEditingBasicDetails.previousSurveyData"
      ],
        ppeRequirements: { },
        ppeRequirementsOther: { },
        climateConditions: { },
        regionalSecurityConsiderations: { },
        permitIssuer: { },
        permitRequisites: { },
        startDate: {
          required,
          minDate: (value) =>
          {
            if (value !== undefined) return new Date(value) >= new Date(epochToStringInputDate(new Date().getTime()));
            else return true;
          }
        },
        endDate: {
          required,
          minDate: (value) =>
          {
            if (value !== undefined) return value >= this.projectEditingBasicDetails.startDate;
            else return true;
          }
        },
        step6:
      [
        "projectEditingBasicDetails.ppeRequirements",
        "projectEditingBasicDetails.ppeRequirementsOther",
        "projectEditingBasicDetails.climateConditions",
        "projectEditingBasicDetails.regionalSecurityConsiderations",
        "projectEditingBasicDetails.permitIssuer",
        "projectEditingBasicDetails.permitRequisites",
        "projectEditingBasicDetails.startDate",
        "projectEditingBasicDetails.endDate"
      ],
        siteRisks: { },
        hazardousAreaClassification: { required },
        step7:
      [
        "projectEditingBasicDetails.siteRisks",
        "projectEditingBasicDetails.hazardousAreaClassification"
      ],
        expectedDeliveryDate: {
          required,
          minDate: (value) =>
          {
            if (value !== undefined) return value >= this.projectEditingBasicDetails.endDate;
            else return true;
          }
        },
        quotationType: { required },
        step8:
      [
        "projectEditingBasicDetails.expectedDeliveryDate",
        "projectEditingBasicDetails.quotationType"
      ]
      }
    };
  },
  async beforeMount ()
  {
    this.projectEditingBasicDetails = cloneObject(this.project.basicDetails);
    this.projectEditingStatus = this.project.status;
    if (!this.$route.query.step)
    {
      if (this.projectEditingBasicDetails.step === this.projectSteps.length)
      {
        this.formStep = 1;
      }
      else
      {
        this.formStep = this.projectEditingBasicDetails.step;
      }
    }
  },
  data ()
  {
    return {
      projectSteps: [
        {
          title: this.$t("Basic details")
        },
        {
          title: this.$t("Objectives")
        },
        {
          title: this.$t("Site characteristics")
        },
        {
          title: this.$t("Survey methods")
        },
        {
          title: this.$t("Available documentation")
        },
        {
          title: this.$t("Site accessibility")
        },
        {
          title: this.$t("Risk management")
        },
        {
          title: this.$t("Contract")
        }
      ],
      projectEditingBasicDetails: null,
      projectEditingStatus: null,
      showingErrors: false,
      savingNext: false,
      savingPublish: false
    };
  },
  computed:
  {
    formStep: {
      get ()
      {
        let tmp = !this.$route.query.step ? -1 : Number(this.$route.query.step);
        tmp = Math.min(tmp, this.projectEditingBasicDetails.step);
        return tmp;
      },
      set (value)
      {
        if (value !== this.formStep)
        {
          this.$router.push({ path: this.$route.path, query: { ...this.$route.query, step: value } });
        }
      }
    }
  },
  methods:
  {
    epochToStringInputDate: epochToStringInputDate,
    state (step)
    {
      if (this.formStep === step)
      {
        return "CURRENT";
      }
      if (this.projectEditingBasicDetails.step >= step)
      {
        if (this.formStep < step)
        {
          return "DONE_WAITING";
        }
        return "DONE";
      }
      return "WAITING";
    },
    propChanged (params)
    {
      this.$set(this.projectEditingBasicDetails, params.propName, params.value);
    },
    async previous ()
    {
      if (this.formStep > 1)
      {
        if (this.$v.projectEditingBasicDetails[`step${this.formStep}`].$invalid && this.formStep !== this.projectEditingBasicDetails.step)
        {
          this.showingErrors = true;
        }
        else
        {
          this.showingErrors = false;
          this.formStep--;
        }
      }
    },
    async next ()
    {
      if (this.formStep <= this.projectSteps.length)
      {
        if (this.$v.projectEditingBasicDetails[`step${this.formStep}`].$invalid)
        {
          this.showingErrors = true;
        }
        else
        {
          this.showingErrors = false;
          this.savingNext = true;
          this.saveProjectAsync()
            .then(() =>
            {
              this.projectEditingBasicDetails.step = Math.max(this.formStep + 1, this.projectEditingBasicDetails.step);
              this.formStep++;
            })
            .catch(error =>
            {
              this.$bus.emit("notification", { type: "ERROR", message: error });
            })
            .finally(() =>
            {
              this.savingNext = false;
            });
        }
      }
    },
    goToStep (step)
    {
      if (step <= this.projectEditingBasicDetails.step)
      {
        if (this.$v.projectEditingBasicDetails[`step${this.formStep}`].$invalid && this.formStep !== this.projectEditingBasicDetails.step)
        {
          this.showingErrors = true;
        }
        else
        {
          this.showingErrors = false;
          this.formStep = step;
        }
      }
    },
    publish ()
    {
      if (this.$v.$invalid)
      {
        this.showingErrors = true;
      }
      else
      {
        this.projectEditingStatus = "OPEN";
        this.showingErrors = false;
        this.savingPublish = true;
        this.saveProjectAsync()
          .then(() =>
          {
            this.$router.push(`/projects/${this.project.id}/contractors`);
          })
          .catch(error =>
          {
            this.$bus.emit("notification", { type: "ERROR", message: error });
          })
          .finally(() =>
          {
            this.savingPublish = false;
          });
      }
    },
    async saveProjectAsync ()
    {
      if (!this.$v.projectEditingBasicDetails[`step${this.formStep}`].$invalid)
      {
        if (this.project.id === undefined)
        {
          const response = await HTTP.post("/client/projects", { status: this.projectEditingStatus, basicDetails: this.projectEditingBasicDetails });

          const newProject = cloneObject(this.project);
          newProject.id = response.id;
          newProject.basicDetails = cloneObject(this.projectEditingBasicDetails);
          this.$emit("update:project", cloneObject(newProject));
        }
        else
        {
          await HTTP.put("/client/projects", { id: this.project.id, status: this.projectEditingStatus, basicDetails: this.projectEditingBasicDetails });
          this.project.status = this.projectEditingStatus;
          this.$emit("update:project", cloneObject(this.project));
        }
      }
    }
  }
};
</script>

<style scoped>

    .slide-fade-enter-active {
        transition: all 0.4s ease;
    }

    .slide-fade-leave-active {
        transition: all 0.2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
        position: absolute;
        width: 100%;
    }

    .slide-fade-enter {
        transform: translateX(20px);
        opacity: 0;
    }

    .slide-fade-leave-to {
        transform: translateX(-20px);
        opacity: 0;
    }

</style>
