<template>
  <div class="ugi-card overflow-hidden divide-y divide-gray-200">
    <div class="my-4" v-if="title !== null">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 flex items-center">
        <div>
          <h1 class="text-xl ugi-font-rounded">
            {{ title }}
          </h1>
          <h2 v-if="subtitle !== null" class="mt-1 max-w-2xl text-sm text-gray-500">
            {{subtitle}}
          </h2>
        </div>
        <div class="ml-auto">
          <slot name="buttons" />
        </div>
      </div>
    </div>
    <slot />

    <div v-if="showPagination" class="p-4 flex justify-end">
      <nav class="inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        <a class="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
          </svg>
        </a>
        <a class="relative cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
          1
        </a>
        <!-- <span class="relative cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
          ...
        </span> -->
        <a class="relative cursor-pointer inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
        </a>
      </nav>
    </div>

  </div>
</template>

<script>

export default {
  components: {

  },
  data: function ()
  {
    return {

    };
  },
  model: {
  },
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    showPagination: {
      type: Boolean,
      default: false
    }
  },
  methods: {

  }
};
</script>
