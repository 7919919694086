<template>
  <ugi-popup-panel @confirm="sendReport"
                   @cancel="$emit('cancel', false)"
                   :loadingPositive="savingSubmission"
                   :title="$t('Submit results')"
                   :subtitle="$t('Please complete this form to submit the project results')">
    <div slot>
      <div class="flex-1">

        <div class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">

          <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <label class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">{{ $t('Introduction') }}</label>
            <ugi-form-field-with-title class="sm:col-span-2" :error="getErrorDescription('introduction', $v.submissionEditing)" :showErrors="showErrors">
              <textarea type="text" class="ugi-input" v-model="submissionEditing.introduction" :class="{'ugi-input-invalid' : $v.submissionEditing.introduction.$invalid && showErrors}" />
            </ugi-form-field-with-title>
          </div>

          <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <label class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">{{ $t('Discussion of results') }}</label>
            <ugi-form-field-with-title class="sm:col-span-2" :error="getErrorDescription('dicussionResults', $v.submissionEditing)" :showErrors="showErrors">
              <textarea type="text" class="ugi-input" v-model="submissionEditing.dicussionResults" :class="{'ugi-input-invalid' : $v.submissionEditing.dicussionResults.$invalid && showErrors}" />
            </ugi-form-field-with-title>
          </div>

          <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <label class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">{{ $t('Conclusions') }}</label>
            <ugi-form-field-with-title class="sm:col-span-2" :error="getErrorDescription('conclusions', $v.submissionEditing)" :showErrors="showErrors">
              <textarea type="text" class="ugi-input" v-model="submissionEditing.conclusions" :class="{'ugi-input-invalid' : $v.submissionEditing.conclusions.$invalid && showErrors}" />
            </ugi-form-field-with-title>
          </div>

          <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <div>
              <label class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {{$t('Files')}}
              </label>
            </div>
            <div class="sm:col-span-2">
              <ugi-file-uploader class="" v-for="(fileInfo, index) in fileInfoList"
                                 :key="index"
                                 :allowDownload="false"
                                 :fileInfo="fileInfo"
                                 @uploaded="addFileToSubmission(fileInfo, $event)"
                                 @delete="deleteFile(fileInfo)" />
              <label>
                <div class="ugi-button-light">{{ $t('Upload document') }}</div>
                <input type="file" style="display:none" @input="importFiles" />
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ugi-popup-panel>
</template>

<script>

import { HTTP } from "@/http.js";
import { required } from "vuelidate/lib/validators";
import { cloneObject, getErrorDescription } from "@/helpers.js";

export default
{
  beforeMount ()
  {
    this.submissionEditing = cloneObject(this.submission);
  },
  props: {
    submission: {
      type: Object
    },
    unitLocation: {
      type: String
    }
  },
  data ()
  {
    return {
      fileInfoList: [],
      submissionEditing: null,
      showErrors: false,
      savingSubmission: false
    };
  },
  validations ()
  {
    return {
      submissionEditing: {
        introduction: { required },
        dicussionResults: { required },
        conclusions: { required }
      }
    };
  },
  computed: {

  },
  methods: {
    getErrorDescription: getErrorDescription,
    sendReport ()
    {
      if (this.$v.$invalid)
      {
        this.showErrors = true;
      }
      else
      {
        this.savingSubmission = true;
        const resultEvents = {
          submission: this.submissionEditing,
          status: "DELIVERED"
        };
        HTTP.post(`/contractor/projects/${this.$route.params.idProject}/results`, resultEvents)
          .then(response =>
          {
            this.$emit("resultsSent", response);
          })
          .catch(error =>
          {
            this.$bus.emit("notification", { type: "ERROR", message: error });
          })
          .finally(() =>
          {
            this.savingSubmission = false;
            this.showPopup = false;
          });
      }
    },
    setProp (prop, value)
    {
      this.$set(this.submissionEditing, prop, value);
    },
    importFiles (event)
    {
      const droppedFiles = event.target.files;
      if (!droppedFiles) return;
      const file = droppedFiles[0];
      event.target.value = "";

      HTTP.post(`/contractor/projects/${this.$route.params.idProject}/result-files`, {})
        .then(response =>
        {
          this.fileInfoList.push({
            id: response.idFile,
            name: file.name,
            file: file,
            url: response.urlUpload
          });
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        });
    },
    addFileToSubmission (fileInfo, event)
    {
      if (this.submissionEditing.files === null || this.submissionEditing.files === undefined)
      {
        this.submissionEditing.files = [];
      }

      this.submissionEditing.files.push({
        id: fileInfo.id,
        name: fileInfo.file.name
      });

      var index = this.fileInfoList.indexOf(fileInfo);
      this.$set(this.fileInfoList, index, { // to make array change reactive
        id: fileInfo.id,
        name: fileInfo.file.name
      });
    },
    deleteFile (fileInfo)
    {
      var index = this.fileInfoList.indexOf(fileInfo);
      this.$delete(this.fileInfoList, index);

      let arr = cloneObject(this.submissionEditing.files);
      arr = arr.filter(a => a.id !== fileInfo.id);
      this.setProp("files", arr);
    }
  }
};
</script>

<style scoped>

</style>
