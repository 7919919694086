<template>
  <portal to="popup-placer" >
    <transition name="bounce">
      <div class="bg-black bg-opacity-30 flex items-center justify-center h-full">
        <!--Card-->
        <div class="bg-white w-11/12 mx-auto rounded shadow-lg z-50 md:max-w-md">
          <!--Title-->
          <div class="m-4 flex justify-between items-center">
            <p class="text-xl font-bold geo-rounded-font">{{ title }}</p>
          </div>

          <!--Content-->
          <div :class="{'m-4' : borderless == false}">
            <slot />
          </div>

          <!--Footer-->
          <div class="flex justify-end" :class="{'m-4' : borderless == false}">
            <slot name="buttons"/>
            <div class="space-x-2">
              <ugi-button :text="negativeLabelInternal" :loading="false" @click="cancel" classButton="ugi-button-light"/>
              <ugi-button :text="positiveLabelInternal" :loading="loadingPositive" @click="confirm" />
            </div>
          </div>

        </div>
      </div>
    </transition>
  </portal>
</template>

<script>

export default ({
  props: {
    title: {
      type: String
    },
    message: {
      type: String
    },
    borderless: {
      type: Boolean,
      default: false
    },
    positiveLabel: {
      type: String,
      default: null
    },
    negativeLabel: {
      type: String,
      default: null
    },
    loadingPositive:
    {
      type: Boolean,
      default: false
    }
  },
  data ()
  {
    return {
      positiveLabelInternal: null,
      negativeLabelInternal: null
    };
  },
  beforeMount ()
  {
    if (this.positiveLabel === null)
    {
      this.positiveLabelInternal = this.$t("ok");
    }
    else
    {
      this.positiveLabelInternal = this.positiveLabel;
    }

    if (this.negativeLabel === null)
    {
      this.negativeLabelInternal = this.$t("cancel");
    }
    else
    {
      this.negativeLabelInternal = this.negativeLabel;
    }
  },
  mounted ()
  {
    document.addEventListener("keydown", this.keyPressed);
    document.addEventListener("keyup", this.keyUnpressed);
  },
  beforeDestroy ()
  {
    document.removeEventListener("keydown", this.keyPressed);
    document.removeEventListener("keyup", this.keyUnpressed);
  },
  methods: {
    keyPressed (event)
    {
      if (event.keyCode === 13)
      {
        //  keyboard confirm
      }
      else if (event.keyCode === 27)
      {
        this.cancel();
      }
    },
    keyUnpressed (event)
    {

    },
    cancel ()
    {
      this.$emit("cancel", false);
    },
    confirm ()
    {
      this.$emit("confirm", false);
    }
  }
});
</script>

<style scoped>

  .bounce-enter-active {
    animation: bounce-in .2s;
  }

  .bounce-leave-active {
    animation: bounce-in .15s reverse;
  }

  @keyframes bounce-in {
    0% {
      transform: scale(1.1);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

</style>
