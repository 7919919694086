<template>
  <div>
    <div class="py-6 px-4 sm:p-6">
      <h2 class="ugi-section-title">{{ $t('changePassword') }}</h2>

      <transition-expand :visible="currentError !== ''">
        <ugi-inline-alert class="mb-6" :message="$t(currentError)" />
      </transition-expand>

      <div class="grid grid-cols-12 gap-6">
        <ugi-form-field-with-title class="col-span-12 sm:col-span-6" :title="$t('currentPassword')">
          <input name="current-password" type="password" v-model="currentPassword" class="ugi-input"/>
        </ugi-form-field-with-title>

        <ugi-form-field-with-title class="col-span-12 sm:col-start-1 sm:col-end-7" :title="$t('newPassword')" :error="errors.newPassword[0]" :showErrors="typingPassword">
          <input name="-new-password" type="password" v-model="newPassword" class="ugi-input"/>
        </ugi-form-field-with-title>

        <ugi-form-field-with-title class="col-span-12 sm:col-span-6" :title="$t('repeatPassword')" :error="errors.repeatNewPassword[0]" :showErrors="!passwordsMatch">
          <input name="repeat-new-password" type="password" v-model="repeatNewPassword" class="ugi-input"/>
        </ugi-form-field-with-title>
      </div>
    </div>
    <div class="mt-4 py-4 px-4 flex justify-end sm:px-6 bg-gray-50">
      <ugi-button :text="$t('save')" :loadingText="$t('saving')" :loading="loading" :disabled="(!passwordsMatch || errors.newPassword.length != 0 || loading )" @click="savePassword" />
    </div>
  </div>
</template>

<script>

import { checkPasswordError } from "@/helpers";
import { HTTP } from "@/http.js";

export default {
  data ()
  {
    return {
      loading: false,
      currentError: "",
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: ""
    };
  },
  computed:
  {
    passwordsMatch ()
    {
      return (this.newPassword === this.repeatNewPassword);
    },
    typingPassword ()
    {
      if (this.newPassword !== "")
      {
        return true;
      }
      else
      {
        return false;
      }
    },
    errors ()
    {
      const tmp = {};
      tmp.newPassword = [];
      tmp.repeatNewPassword = [];
      if (checkPasswordError(this.newPassword) !== null) tmp.newPassword.push(checkPasswordError(this.newPassword));
      if (!this.passwordsMatch) tmp.repeatNewPassword.push("invalidPassNotMatch");
      return tmp;
    }
  },
  methods: {
    savePassword ()
    {
      this.currentError = "";
      let valid = true;
      Object.entries(this.errors).forEach(([key, value]) =>
      {
        if (value.length > 0)
        {
          valid = false;
        }
      });
      if (valid)
      {
        const passwords = {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword
        };
        this.loading = true;
        HTTP.put("/users/current/password", passwords)
          .then(response =>
          {
            // TODO show success notification
          })
          .catch(error =>
          {
            this.currentError = error;
          })
          .finally(() =>
          {
            this.loading = false;
          });
      }
    }
  }
};
</script>
