<template>
  <div class="p-6 grid sm:grid-cols-12">
    <ugi-form-field-with-title class="col-span-12 mb-6" :title="$t('Ground penetrating radar (GPR)')" :error="getErrorDescription('gpr', validations)" :showErrors="showErrors">
      <button type="button"
              class="ugi-button-grouped-left"
              :class="[{'ugi-button-grouped-invalid' : validations.gpr.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.gpr === 'YES'}]"
              @click="setProp('gpr', 'YES')" >
        {{ $t("Yes") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-center"
              :class="[{'ugi-button-grouped-invalid' : validations.gpr.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.gpr === 'NO'}]"
              @click="setProp('gpr', 'NO')">
        {{ $t("No") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-right"
              :class="[{'ugi-button-grouped-invalid' : validations.gpr.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.gpr === 'OPEN'}]"
              @click="setProp('gpr', 'OPEN')">
        {{ $t("Open to survey company suggestions") }}
      </button>
    </ugi-form-field-with-title>

    <transition-expand :visible="basicDetails.gpr === 'YES'" class="col-span-12 lg:col-span-6">
      <ugi-form-field-with-title class="pb-6" :title="$t('Minimum profile separation')" :error="getErrorDescription('minimumProfileSeparation', validations)" :showErrors="showErrors">
        <ugi-input-with-unit :value="basicDetails.minimumProfileSeparation"
                             @input="setProp('minimumProfileSeparation', $event)"
                             :unit="basicDetails.unitLocation"
                             :class="{'ugi-input-invalid' : validations.minimumProfileSeparation.$invalid && showErrors}"/>
      </ugi-form-field-with-title>
    </transition-expand>

    <ugi-form-field-with-title class="col-span-12 mb-6" :title="$t('Electromagnetic locating (EML)')" :error="getErrorDescription('eml', validations)" :showErrors="showErrors">
      <button type="button"
              class="ugi-button-grouped-left"
              :class="[{'ugi-button-grouped-invalid' : validations.eml.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.eml === 'YES'}]"
              @click="setProp('eml', 'YES')">
        {{ $t("Yes") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-center"
              :class="[{'ugi-button-grouped-invalid' : validations.eml.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.eml === 'NO'}]"
              @click="setProp('eml', 'NO')">
        {{ $t("No") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-right"
              :class="[{'ugi-button-grouped-invalid' : validations.eml.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.eml === 'OPEN'}]"
              @click="setProp('eml', 'OPEN')">
        {{ $t("Open to survey company suggestions") }}
      </button>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12 mb-6" :title="$t('Lidar')" :error="getErrorDescription('lidar', validations)" :showErrors="showErrors">
      <button type="button"
              class="ugi-button-grouped-left"
              :class="[{'ugi-button-grouped-invalid' : validations.lidar.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.lidar === 'YES'}]"
              @click="setProp('lidar', 'YES')">
        {{ $t("Yes") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-center"
              :class="[{'ugi-button-grouped-invalid' : validations.lidar.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.lidar === 'NO'}]"
              @click="setProp('lidar', 'NO')">
        {{ $t("No") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-right"
              :class="[{'ugi-button-grouped-invalid' : validations.lidar.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.lidar === 'OPEN'}]"
              @click="setProp('lidar', 'OPEN')">
        {{ $t("Open to survey company suggestions") }}
      </button>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12 mb-6" :title="$t('Above-Ground Object Survey')" :error="getErrorDescription('objectSurvey', validations)" :showErrors="showErrors">
      <button type="button"
              class="ugi-button-grouped-left"
              :class="[{'ugi-button-grouped-invalid' : validations.objectSurvey.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.objectSurvey === 'YES'}]"
              @click="setProp('objectSurvey', 'YES')">
        {{ $t("Yes") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-center"
              :class="[{'ugi-button-grouped-invalid' : validations.objectSurvey.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.objectSurvey === 'NO'}]"
              @click="setProp('objectSurvey', 'NO')">
        {{ $t("No") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-right"
              :class="[{'ugi-button-grouped-invalid' : validations.objectSurvey.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.objectSurvey === 'OPEN'}]"
              @click="setProp('objectSurvey', 'OPEN')">
        {{ $t("Open to survey company suggestions") }}
      </button>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12 mb-6" :title="$t('Manual manhole inspection')" :error="getErrorDescription('handManholeSurvey', validations)" :showErrors="showErrors">
      <button type="button"
              class="ugi-button-grouped-left"
              :class="[{'ugi-button-grouped-invalid' : validations.handManholeSurvey.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.handManholeSurvey === 'YES'}]"
              @click="setProp('handManholeSurvey', 'YES')">
        {{ $t("Yes") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-center"
              :class="[{'ugi-button-grouped-invalid' : validations.handManholeSurvey.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.handManholeSurvey === 'NO'}]"
              @click="setProp('handManholeSurvey', 'NO')">
        {{ $t("No") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-right"
              :class="[{'ugi-button-grouped-invalid' : validations.handManholeSurvey.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.handManholeSurvey === 'OPEN'}]"
              @click="setProp('handManholeSurvey', 'OPEN')">
        {{ $t("Open to survey company suggestions") }}
      </button>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12" :title="$t('Trench digging')" :error="getErrorDescription('trenchDigging', validations)" :showErrors="showErrors">
      <button type="button"
              class="ugi-button-grouped-left"
              :class="[{'ugi-button-grouped-invalid' : validations.trenchDigging.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.trenchDigging === 'YES'}]"
              @click="setProp('trenchDigging', 'YES')">
        {{ $t("Yes") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-center"
              :class="[{'ugi-button-grouped-invalid' : validations.trenchDigging.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.trenchDigging === 'NO'}]"
              @click="setProp('trenchDigging', 'NO')">
        {{ $t("No") }}
      </button>
      <button type="button"
              class="ugi-button-grouped-right"
              :class="[{'ugi-button-grouped-invalid' : validations.trenchDigging.$invalid && showErrors}, {'ugi-button-grouped-selected' : basicDetails.trenchDigging === 'OPEN'}]"
              @click="setProp('trenchDigging', 'OPEN')">
        {{ $t("Open to survey company suggestions") }}
      </button>
    </ugi-form-field-with-title>

  </div>
</template>

<script>
import { getErrorDescription } from "@/helpers.js";

export default {
  props: {
    basicDetails: {
      type: Object
    },
    showErrors: {
      type: Boolean
    },
    validations: {
      type: Object
    }
  },
  methods: {
    getErrorDescription: getErrorDescription,
    setProp (prop, value)
    {
      this.$emit("propChanged", { propName: prop, value: value });
    }
  }
};
</script>
