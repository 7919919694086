<template>
  <transition name="expandv2"
              v-on:leave="leave"
              v-on:enter="enter">
    <div v-if="visible">
      <slot />
    </div>
  </transition>
</template>

<script>

export default {
  components: {

  },
  events: [],
  data ()
  {
    return {
      firstLoad: true
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  mounted ()
  {
    setTimeout(() =>
    {
      this.firstLoad = false;
    }, 100);
  },
  beforeDestroy ()
  {
  },
  methods: {
    leave (element, b)
    {
      const { height } = getComputedStyle(element);
      element.style.height = height; // set static height to allow animation
      element.style.overflow = "hidden";
      // getComputedStyle(element).height;
      setTimeout(() =>
      {
        // eslint-disable-next-line no-param-reassign
        element.style.height = 0;
      }, 20);
    },
    enter (element, done)
    {
      if (this.firstLoad)
      {
        element.style.height = "auto";
      }
      else
      {
        // make it auto height to measure it
        const { width } = getComputedStyle(element);
        element.style.width = width;
        element.style.position = "absolute";
        element.style.visibility = "hidden";
        element.style.height = "auto";
        element.style.overflow = "hidden";

        setTimeout(() =>
        {
          const { height } = getComputedStyle(element);
          element.style.width = null;
          element.style.position = null;
          element.style.visibility = null;
          element.style.height = 0;
          // element.style.overflow = 'hidden';
          // getComputedStyle(element).height;
          setTimeout(() =>
          {
            // eslint-disable-next-line no-param-reassign
            element.style.height = height;
          }, 20);

          const checkDone = () =>
          {
            setTimeout(() =>
            {
              if (getComputedStyle(element).height === height)
              {
                element.style.height = "auto";
                element.style.overflow = "visible";
              }
              else
              {
                checkDone();
              }
            }, 20);
          };
          checkDone();
        }, 20); // this delay allows a correct measurement
      }
    }
  }
};
</script>

<style scoped>
  .expandv2-leave-active,
  .expandv2-enter-active {
    transition: height 0.3s ease-in-out;
  }

  .expandv2-enter,
  .expandv2-leave-to {
    height: 0;
  }
</style>
