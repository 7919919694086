<template>
  <div class="rounded relative" >
    <div id="map" class="absolute w-full h-full"/>
    <div class="top-0 mt-4 ml-4 absolute space-x-1 flex">
      <button type="button" class="button-map" @click="setMapStyle('mapbox://styles/mapbox/streets-v11')">
        <svg class="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" >
          <path d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM10 5.47l4 1.4v11.66l-4-1.4V5.47zm-5 .99l3-1.01v11.7l-3 1.16V6.46zm14 11.08l-3 1.01V6.86l3-1.16v11.84z" />
        </svg>
      </button>
      <button type="button" class="button-map" @click="setMapStyle('mapbox://styles/mapbox/satellite-v9')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </button>

      <div class="w-2" />

      <button type="button" class="button-map" @click="setMapMode('draw_polygon')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
        </svg>
      </button>
      <button type="button" class="button-map" :disabled="selectedFeatures.length === 0" @click="setMapMode('split_polygon')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.121 14.121L19 19m-7-7l7-7m-7 7l-2.879 2.879M12 12L9.121 9.121m0 5.758a3 3 0 10-4.243 4.243 3 3 0 004.243-4.243zm0-5.758a3 3 0 10-4.243-4.243 3 3 0 004.243 4.243z" />
        </svg>
      </button>
      <button type="button" class="button-map" :disabled="selectedFeatures.length === 0" @click="deleteSelected()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>

import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import SplitPolygonMode from "@/generic/mapbox/SplitPolygonMode";
import PassingPolygonMode from "@/generic/mapbox/PassingPolygonMode";
import { cloneObject } from "@/helpers.js";

export default {
  props: {
    latitude: {
      type: Number
    },
    longitude: {
      type: Number
    },
    value: {
      type: Array
    }
  },
  data ()
  {
    return {
      map: null,
      draw: null,
      selectedFeatures: [],
      coordinatesInternal: []
    };
  },
  watch: {
    latitude: function (newVal, oldVal)
    {
      this.locate();
    },
    coordinatesInternal: function (newVal, oldVal)
    {
      this.$emit("input", cloneObject(newVal));
    }
  },
  mounted ()
  {
    mapboxgl.accessToken = this.$constants.MAPBOX_API_KEY;

    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [0, 0],
      zoom: 1
    });

    this.draw = new MapboxDraw({
      modes: Object.assign(MapboxDraw.modes, {
        split_polygon: SplitPolygonMode,
        passing_mode_polygon: PassingPolygonMode(
          MapboxDraw.modes.draw_polygon
        )
      }),
      displayControlsDefault: false

      // defaultMode: "draw_polygon"
    });

    this.map.addControl(this.draw);

    this.map.on("draw.selectionchange", (args) =>
    {
      this.selectedFeatures = args.features;
    });

    this.map.on("draw.create", (args) =>
    {
      this.recalculatePolygons();
    });

    this.map.on("draw.delete", (args) =>
    {
      this.recalculatePolygons();
    });

    this.map.on("draw.update", (args) =>
    {
      this.recalculatePolygons();
    });

    this.map.on("draw.split", (args) =>
    {
      this.recalculatePolygons();
    });

    this.map.on("load", () =>
    {
      if (this.value !== null && this.value !== undefined)
      {
        var feature = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: []
              }
            }
          ]
        };

        this.value.forEach(a =>
        {
          const tmp = [];
          a.forEach(b =>
          {
            tmp.push([b.longitude, b.latitude]);
          });
          feature.features[0].geometry.coordinates.push(tmp);
        });

        this.draw.add(feature);
      }
    });

    if (this.latitude !== null && this.latitude !== undefined)
    {
      this.locate();
    }
  },
  methods:
  {
    locate ()
    {
      this.map.setCenter([this.longitude, this.latitude]);
      this.map.setZoom(17);
    },
    recalculatePolygons ()
    {
      const tmp = [];
      const features = this.draw.getAll().features;
      features.forEach(feature =>
      {
        feature.geometry.coordinates.forEach(geometry =>
        {
          const tmpShape = [];
          geometry.forEach(pair =>
          {
            tmpShape.push({
              latitude: pair[1],
              longitude: pair[0]
            });
          });
          tmp.push(tmpShape);
        });
      });

      console.log(JSON.stringify(tmp));
      this.coordinatesInternal = tmp;
    },
    setMapStyle (style)
    {
      this.map.setStyle(style);
    },
    setMapMode (mode)
    {
      this.draw.changeMode(mode);
    },
    deleteSelected ()
    {
      this.draw.trash();
      this.selectedFeatures = this.draw.getSelected().features;
    }
  }
};
</script>

<style scoped>

.button-map {
  @apply bg-white p-1 rounded h-6 w-6 shadow;
}

.button-map:disabled {
  @apply text-gray-300;
}

</style>
