<template>
  <ugi-main-section :title="$t('Final delivery')">
    <div slot="buttons">
      <button class="ugi-button-light" @click="startNewResults" v-if="canSendNewResults">{{ $t('Submit new results') }}</button>
    </div>
    <div>
      <ugi-history-bar v-if="steps.length > 0" :steps="steps" @clickItem="clickResult" :selectedStep="project.resultEvents.indexOf(submissionCurrent)" />
      <projects-results-details v-if="submissionCurrent !== null" :results="submissionCurrent" :idProject="project.id" />

      <p class="ugi-empty-list-legend" v-if="steps.length === 0">{{ $t('No results have been submitted yet') }}</p>

      <contractor-projects-edit-results-form v-if="showPopup"
                                             :submission="submissionEditing"
                                             :unitLocation="project.basicDetails.unitLocation"
                                             @resultsSent="resultsSent"
                                             @cancel="showPopup = false" />
    </div>
  </ugi-main-section>
</template>

<script>

import { epochToStringHumanReadable } from "@/helpers.js";
import { HTTP } from "@/http.js";
import ContractorProjectsEditResultsForm from "./ContractorProjectsEditResultsForm.vue";
import ProjectsResultsDetails from "../../../commonProjects/ProjectsResultsDetails.vue";

export default {
  components: {
    ContractorProjectsEditResultsForm,
    ProjectsResultsDetails
  },
  props: {
    project: {
      type: Object
    },
    application: {
      type: Object
    }
  },
  beforeMount ()
  {
    if (this.project.resultEvents !== null && this.project.resultEvents !== undefined && this.project.resultEvents.some(r => r.type === "ProjectResultEventSubmission"))
    {
      const projectSubmissions = this.project.resultEvents.filter(r => r.type === "ProjectResultEventSubmission");
      const maxtimestamp = Math.max(...projectSubmissions.map(r => r.timestamp));
      this.submissionCurrent = projectSubmissions.find(r => r.timestamp === maxtimestamp);
    }
  },
  mounted ()
  {

  },
  data ()
  {
    return {
      showPopup: false,
      submissionCurrent: null,
      submissionEditing: null
    };
  },
  computed: {
    errors ()
    {
      const tmp = {};
      return tmp;
    },
    steps ()
    {
      const tmp = [];
      if (this.project.resultEvents !== undefined && this.project.resultEvents !== null)
      {
        this.project.resultEvents.forEach(resultEvent =>
        {
          const step = {
            title: this.$t(resultEvent.type),
            timestamp: resultEvent.timestamp
          };
          if (resultEvent.type === "ProjectResultEventSubmission") step.svgPath = "M3.34 2.57c-1.48,0.19 -2.56,1.17 -2.98,2.38 -0.33,0.98 -0.19,3.9 -0.19,5.12 0,1.78 0,3.55 0,5.32 0,2.16 -0.4,4.65 2.18,5.79 0.73,0.33 1.53,0.28 2.38,0.28 1.45,0 15.21,0.06 15.97,-0.03 1.43,-0.16 2.55,-1.21 2.94,-2.38 0.33,-0.98 0.19,-3.89 0.19,-5.12 0,-1.78 0,-3.55 0,-5.32 0,-1.71 0.25,-3.4 -0.71,-4.61 -1.11,-1.43 -2.16,-1.46 -3.85,-1.46 -1.47,0 -15.15,-0.07 -15.93,0.03zm0.46 16.53l16.4 0c0.88,0 1.27,-0.55 1.27,-1.43l0 -8.16c-0.34,0.15 -4.13,2.72 -4.54,2.99 -0.76,0.51 -1.49,0.99 -2.25,1.5 -0.75,0.5 -1.46,1.07 -2.68,1.07 -1.21,0 -1.93,-0.57 -2.68,-1.07 -0.76,-0.51 -1.49,-0.99 -2.25,-1.5 -0.77,-0.51 -1.47,-0.98 -2.24,-1.5 -0.38,-0.25 -0.73,-0.48 -1.11,-0.73 -0.31,-0.21 -0.85,-0.62 -1.19,-0.76l0 8.11c0,0.9 0.37,1.48 1.27,1.48zm-1.26 -12.43c0.27,0.1 4.28,2.82 4.65,3.06l3.48 2.33c1.6,1.06 1.46,0.8 3.8,-0.76 0.57,-0.38 6.7,-4.52 6.99,-4.63 0.01,-0.65 0.01,-1.64 -1.05,-1.75l-16.77 -0.01c-1.07,0.09 -1.12,1.06 -1.1,1.76z";
          else if (resultEvent.type === "ProjectResultEventApprove") step.svgPath = "M2.33 14.3c-0.59,-0.58 -0.6,-1.54 -0.01,-2.13 0.59,-0.59 1.54,-0.6 2.14,-0.01l3.85 3.85 11.22 -11.22c0.59,-0.6 1.55,-0.6 2.14,0 0.59,0.59 0.59,1.55 0,2.14l-12.29 12.29c-0.59,0.59 -1.54,0.59 -2.14,0l-4.91 -4.92z";
          tmp.push(step);
        });
      }
      return tmp;
    },
    submissionCurrentFiles ()
    {
      const tmp = [];
      if (this.submissionCurrent !== null)
      {
        this.submissionCurrent.files.forEach(file =>
        {
          tmp.push({
            id: file.id,
            name: file.name
          });
        });
      }
      return tmp;
    },
    canSendNewResults ()
    {
      if (this.project.resultEvents !== undefined && this.project.resultEvents !== null)
      {
        return !this.project.resultEvents.some(resultEvent => resultEvent.type === "ProjectResultEventApprove");
      }
      else
      {
        return true;
      }
    }
  },
  methods: {
    epochToStringHumanReadable: epochToStringHumanReadable,
    downloadFile (fileInfo)
    {
      HTTP.get(`/contractor/projects/${this.project.id}/result-files/${fileInfo.id}`, {})
        .then(response =>
        {
          const link = document.createElement("a");
          link.href = response.urlDownload;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        });
    },
    clickResult (index)
    {
      this.submissionCurrent = this.project.resultEvents[index];
    },
    startNewResults ()
    {
      this.submissionEditing = {};
      this.showPopup = true;
    },
    resultsSent (updatedProject)
    {
      this.$emit("update:project", updatedProject);
      this.showPopup = false;
      this.submissionCurrent = updatedProject.resultEvents[updatedProject.resultEvents.length - 1];
    }
  }
};
</script>
