<template>
  <div class="overflow-hidden p-6">
    <h2 class="text-lg leading-6 font-medium text-gray-900">{{ $t('activeSessions') }}</h2>
    <transition-expand :visible="sessions.length > 0">
      <ul class="">
        <li v-for="session in sessions" :key=session.id class="block flex items-center py-4 rounded border my-2 px-4">
          <div class="flex-1 items-center">
            <p class="my-1 flex items-center text-sm text-gray-700">
              <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
              </svg>
              <time :datetime="session.dateStart">{{epochToStringHumanReadable(session.dateStart)}}</time>
            </p>
            <p class="my-1 flex items-center text-sm text-gray-700">
              <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
              </svg>
              {{ session.country }}
            </p>
            <p class="my-1 flex items-center text-sm text-gray-700">
              <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z" clip-rule="evenodd" />
              </svg>
              {{ session.ip }}
            </p>
          </div>
          <ugi-button v-if="!session.isCurrent" :text="$t('terminateSession')" :loadingText="$t('Terminating session')" :loading="idSessionsLoading.includes(session.id)" :disabled="idSessionsLoading.includes(session.id)" classButton="ugi-button-light" @click="terminate(session)" />
          <ugi-button v-if="session.isCurrent" class="ml-4" :text="$t('currentSession')" :disabled="true" classButton="ugi-button-light" />
        </li>
      </ul>
    </transition-expand>
    <transition-expand :visible="loading">
      <ul class="animate-pulse">
        <li class="block items-center py-4 rounded border my-2 px-4 space-y-2">
          <div class="h-4 bg-gray-200 rounded w-5/6"/>
          <div class="h-4 bg-gray-200 rounded w-3/6"/>
          <div class="h-4 bg-gray-200 rounded w-4/6"/>
        </li>
      </ul>
    </transition-expand>
    <transition-expand :visible="currentError !== ''">
      <div class="my-4 self-center text-red-500">
        {{ $t(currentError) }}
      </div>
    </transition-expand>
  </div>
</template>

<script>
import { HTTP } from "@/http.js";
import { epochToStringHumanReadable } from "@/helpers.js";

export default {
  data ()
  {
    return {
      sessions: [],
      idSessionsLoading: [],
      currentError: "",
      loading: true
    };
  },
  created ()
  {
    HTTP.get("/sessions?onlyActives=true")
      .then(response =>
      {
        this.sessions = response;
      })
      .catch(error =>
      {
        this.currentError = this.$t(error);
      })
      .finally(() =>
      {
        this.loading = false;
      });
  },
  methods: {
    epochToStringHumanReadable: epochToStringHumanReadable,
    terminate (session)
    {
      this.idSessionsLoading.push(session.id);
      HTTP.delete("/sessions/" + session.id)
        .then(response =>
        {
          this.sessions = this.sessions.filter(a => a.id !== session.id);
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
          this.currentError = this.$t(error.data);
        })
        .finally(() =>
        {
          this.idSessionsLoading = this.idSessionsLoading.filter(id => id !== session.id);
        });
    }
  }
};
</script>
