
<template>
  <main-logged-in :title="title" :breadcrumbs="breadcrumbs">

    <template slot="buttons">
      <ugi-button :text="$t('save')" :loadingText="$t('saving')" :loading="saving" @click="save" />
    </template>

    <template v-if="company !== null">

      <div v-if="!loading" class="">

        <div class="space-y-6">
          <div class="ugi-card">
            <div class="md:grid md:grid-cols-3">
              <div class="md:col-span-1 border-b md:border-b-0 md:border-r px-4 py-5 sm:p-6">
                <h3 class="text-lg font-medium leading-6 text-gray-900">{{ $t("Company details") }}</h3>
                <p class="mt-1 text-sm text-gray-500"/>
              </div>
              <div class="md:col-span-2 px-4 py-5 sm:p-6">
                <div class="grid grid-cols-6 gap-6">

                  <ugi-form-field-with-title :title="$t('name')" class="col-span-6">
                    <input class="ugi-input w-full" type="text" v-model="company.name"/>
                  </ugi-form-field-with-title>

                  <ugi-form-field-with-title :title="$t('Admin email')" class="col-span-6">
                    <input class="ugi-input w-full" :disabled="!canEditEmail" type="text" v-model="company.emailAdmin"/>
                  </ugi-form-field-with-title>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </template>
  </main-logged-in>
</template>

<script>

import MainLoggedIn from "@/common/MainLoggedIn.vue";
import { HTTP } from "@/http.js";

export default {
  components: {
    MainLoggedIn
  },
  data ()
  {
    return {
      company: null,
      loading: false,
      saving: false
    };
  },
  mounted ()
  {
    const id = this.$route.params.idContractor;
    if (id !== "new")
    {
      this.loading = true;
      HTTP.get(`/survey-companies/${id}`)
        .then(response =>
        {
          this.company = response;
          this.loading = false;
        })
        .catch(e =>
        {
          this.loading = false;
        });
    }
    else
    {
      this.company = { };
    }
  },
  computed: {
    title ()
    {
      if (this.company === null)
      {
        return null;
      }
      else if (this.company.id === undefined)
      {
        return this.$t("New Survey Company");
      }
      else
      {
        return this.company.name;
      }
    },
    breadcrumbs ()
    {
      return [
        { title: this.$t("Survey Companies"), path: "/companies" },
        { title: this.title, path: null }
      ];
    },
    canEditEmail ()
    {
      return this.company.id === undefined;
    }
  },
  methods:
  {
    save ()
    {
      this.saving = true;
      if (this.company.id === undefined)
      {
        HTTP.post("/survey-companies", this.company)
          .then(response =>
          {
            this.company.id = response.id;
            this.$router.push("/companies");
          })
          .catch(error =>
          {
            this.$bus.emit("notification", { type: "ERROR", message: error });
          })
          .finally(() =>
          {
            this.saving = false;
          });
      }
      else
      {
        HTTP.put("/survey-companies", this.company)
          .catch(error =>
          {
            this.$bus.emit("notification", { type: "ERROR", message: error });
          })
          .finally(() =>
          {
            this.saving = false;
          });
      }
    }
  }
};
</script>
