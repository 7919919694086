<template>
  <div v-if="$store.state.currentUser !== null" class="flex flex-col h-full">
    <nav-bar class=""/>
    <div class="w-full bg-white shadow" v-if="title !== null">
      <div class="w-full flex-grow max-w-7xl mx-auto px-6 lg:px-8 justify-center md:flex md:items-center py-4">
        <div class="h-20 hidden md:block"><!--dummy for min height--></div>
        <div class="flex flex-col justify-center md:flex-grow">
          <ol class="flex items-center">
            <li v-for="(breadcrumb, iBreadcrumb) in breadcrumbs" :key="iBreadcrumb">
              <div class="flex items-center">
                <svg v-if="iBreadcrumb !== 0" class="flex-shrink-0 h-5 w-5 text-gray-400 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                </svg>
                <router-link :to="breadcrumb.path" v-if="breadcrumb.path !== null">
                  <a class="text-sm font-medium text-gray-500 hover:text-gray-700"> {{ breadcrumb.title }} </a>
                </router-link>
                <a class="text-sm font-medium text-gray-500 hover:text-gray-700" v-if="breadcrumb.path === null"> {{ breadcrumb.title }} </a>
              </div>
            </li>
          </ol>
          <div class="flex-1 min-w-0">
            <div class="flex items-center">
              <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate">
                {{ title }}
              </h1>
            </div>
          </div>
        </div>
        <div class="mt-4 flex space-x-3 md:mt-0 md:ml-4">
          <slot name="buttons" />
        </div>
      </div>
    </div>
    <div class="w-full flex-grow max-w-7xl mx-auto sm:px-6 lg:px-8 my-6 space-y-2">
      <slot />
    </div>

    <footer class="bg-gray-800 " aria-labelledby="footerHeading">
      <!--<h2 id="footerHeading" class="sr-only">Footer</h2>-->
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="border-t border-gray-700 py-4 md:flex md:items-center md:justify-between">
          <div class="flex flex-wrap -mx-6">
            <!--<a href="#" class="footer-link">
                            About us
                        </a>
                        <a href="#" class="footer-link">
                            Terms of service
                        </a>
                        <a href="#" class="footer-link">
                            Cookie Policy
                        </a>
                        <a href="#" class="footer-link">
                            Privacy policy
                        </a>
                        <a href="#" class="footer-link">
                            Help & Support
                        </a>-->
          </div>
          <p class="mt-8 text-sm text-gray-400 md:mt-0">
            &copy; {{ currentYear }} Geolitix Technologies Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  </div>
  <div v-else class=" h-full flex items-center justify-center">
    <img class="block h-40 w-auto m-auto opacity-25" src="@/assets/logo.svg"/>
  </div>
</template>

<script>

import NavBar from "./navBar/NavBar.vue";

export default {
  components:
  {
    NavBar
  },
  props: {
    title: {
      type: String,
      default: null
    },
    breadcrumbs: {
      type: Array,
      default: null
    }
  },
  data ()
  {
    return {
      currentError: ""
    };
  },
  computed:
  {
    currentYear ()
    {
      return new Date().getFullYear();
    }
  },
  created ()
  {
    if (!this.$store.state.currentUser)
    {
      this.$store.dispatch("getUser")
        .catch(error =>
        {
          this.currentError = error;
        });
    }
  }
};
</script>

<style scoped>
    .footer-link {
        @apply text-sm text-gray-300 hover:text-white inline-flex mx-6;
    }
</style>
