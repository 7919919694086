<template>
  <div class="flex-1">

    <div class="divide-y divide-gray-100">

      <ugi-details-field-with-title :title="$t('Introduction')">
        <p>{{ proposal.introduction }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Safety protocols')">
        <p>{{ proposal.safetyProtocols }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Methodologies')">
        <p v-if="proposal.gpr">{{ $t('GPR') }}</p>
        <p>{{ 'Profile separation ' + (+convertUnit(proposal.minimumProfileSeparation, "M", unitLocation).toFixed(3)) + ' ' + $t(unitLocation + '_SYMBOL')}}</p>
        <p v-if="proposal.eml">{{ $t('EML') }}</p>
        <p v-if="proposal.lidar">{{ $t('Lidar') }}</p>
        <p v-if="proposal.objectSurvey">{{ $t('Above-Ground Object Survey') }}</p>
        <p v-if="proposal.handManholeSurvey">{{ $t('Manual manhole inspection') }}</p>
        <p v-if="proposal.trenchDigging">{{ $t('Trench digging') }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Survey date')">
        <p>{{ proposal.dateFieldSurveyFrom + ' - ' + proposal.dateFieldSurveyTo }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Attached files')">
        <ugi-file-uploader v-for="(fileInfo, index) in fileInfoList" :key="index" :fileInfo="fileInfo" @download="downloadFile(fileInfo)" :deleteIcon="false" />
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data acquisition (GPR)')" v-if="proposal.gpr">
        <p>{{ proposal.currency + ' ' + proposal.budgetGprAcquisition }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data processing and interpretation (GPR)')" v-if="proposal.gpr">
        <p>{{ proposal.currency + ' ' + proposal.budgetGprProcessing }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data acquisition (EML)')" v-if="proposal.eml">
        <p>{{ proposal.currency + ' ' + proposal.budgetEmlAcquisition }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data processing and interpretation (EML)')" v-if="proposal.eml">
        <p>{{ proposal.currency + ' ' + proposal.budgetEmlProcessing }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data acquisition (Lidar)')" v-if="proposal.lidar">
        <p>{{ proposal.currency + ' ' + proposal.budgetLidarAcquisition }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data processing and interpretation (Lidar)')" v-if="proposal.lidar">
        <p>{{ proposal.currency + ' ' + proposal.budgetLidarProcessing }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data acquisition (Above-Ground Object Survey)')" v-if="proposal.objectSurvey">
        <p>{{ proposal.currency + ' ' + proposal.budgetObjectSurveyAcquisition }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data processing and interpretation (Above-Ground Object Survey)')" v-if="proposal.objectSurvey">
        <p>{{ proposal.currency + ' ' + proposal.budgetObjectSurveyProcessing }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data acquisition (Manual manhole)')" v-if="proposal.manualManhole">
        <p>{{ proposal.currency + ' ' + proposal.budgetManualManholeAcquisition }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data processing and interpretation (Manual manhole)')" v-if="proposal.manualManhole">
        <p>{{ proposal.currency + ' ' + proposal.budgetManualManholeProcessing }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data acquisition (Trench digging)')" v-if="proposal.trenchDigging">
        <p>{{ proposal.currency + ' ' + proposal.budgetTrenchDiggingAcquisition }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Data processing and interpretation (Trench digging)')" v-if="proposal.trenchDigging">
        <p>{{ proposal.currency + ' ' + proposal.budgetTrenchDiggingProcessing }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Reporting')">
        <p>{{ proposal.currency + ' ' + proposal.budgetMobilisation }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Mobilisation of plant and personnel')">
        <p>{{ proposal.currency + ' ' + proposal.budgetReporting }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t(budget.description)" v-for="budget, index in proposal.budgetOthers" :key="index">
        <p>{{ proposal.currency + ' ' + budget.budget }}</p>
      </ugi-details-field-with-title>

      <ugi-details-field-with-title :title="$t('Total budget')">
        <p>{{ proposal.currency + ' ' + totalBudget }}</p>
      </ugi-details-field-with-title>

    </div>

  </div>
</template>

<script>

import { HTTP } from "@/http.js";
import { getTotalBudgetFromProposal, getErrorDescription, convertUnit } from "@/helpers.js";

export default
{
  beforeMount ()
  {

  },
  mounted ()
  {

  },
  props: {
    idContractor: {
      type: String
    },
    proposal: {
      type: Object
    },
    unitLocation: {
      type: String
    }
  },
  data ()
  {
    return {

    };
  },
  computed: {
    totalBudget ()
    {
      return getTotalBudgetFromProposal(this.proposal);
    },
    fileInfoList ()
    {
      const tmp = [];
      if (this.proposal.files !== undefined && this.proposal.files !== null)
      {
        this.proposal.files.forEach(file =>
        {
          tmp.push({
            id: file.id,
            name: file.name
          });
        });
      }
      return tmp;
    }
  },
  methods: {
    getErrorDescription: getErrorDescription,
    convertUnit: convertUnit,
    downloadFile (fileInfo)
    {
      HTTP.get(`/contractor/projects/${this.$route.params.idProject}/proposal/${this.idContractor}/files/${fileInfo.id}`, {})
        .then(response =>
        {
          const link = document.createElement("a");
          link.href = response.urlDownload;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        });
    }
  }
};
</script>
