<template>
  <div class="p-6 grid sm:grid-cols-12 gap-6">
    <ugi-form-field-with-title class="col-span-12"
                               :title="$t('Survey Objective')"
                               :error="getErrorDescription('useCase', validations)"
                               :showErrors="showErrors"
                               :class="{'ugi-input-invalid' : validations.useCase.$invalid && showErrors}">
      <ugi-switch :label="$t('useCase_DESIGN')" :value="statusInArray('useCase', 'DESIGN')" @input="toggleInArray('useCase', 'DESIGN')" />
      <ugi-switch :label="$t('useCase_CONTAMINATION')" :value="statusInArray('useCase', 'CONTAMINATION')" @input="toggleInArray('useCase', 'CONTAMINATION')" />
      <ugi-switch :label="$t('useCase_DETAILED_DESIGN')" :value="statusInArray('useCase', 'DETAILED_DESIGN')" @input="toggleInArray('useCase', 'DETAILED_DESIGN')" />
      <ugi-switch :label="$t('useCase_AVOID_EXCAVATION_DAMAGE')" :value="statusInArray('useCase', 'AVOID_EXCAVATION_DAMAGE')" @input="toggleInArray('useCase', 'AVOID_EXCAVATION_DAMAGE')" />
      <ugi-switch :label="$t('useCase_ORDNANCES')" :value="statusInArray('useCase', 'ORDNANCES')" @input="toggleInArray('useCase', 'ORDNANCES')" />
      <ugi-switch :label="$t('useCase_OTHER')" :value="statusInArray('useCase', 'OTHER')" @input="toggleInArray('useCase', 'OTHER')" />
      <transition-expand :visible="statusInArray('useCase', 'OTHER')" class="grid sm:grid-cols-12">
        <input type="text" :value="basicDetails.useCaseOther" @input="setProp('useCaseOther', $event.srcElement.value)" class="ugi-input col-span-6 mt-4"/>
      </transition-expand>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12 lg:col-start-1 lg:col-end-7"
                               :title="$t('Location unit')"
                               :error="getErrorDescription('unitLocation', validations)"
                               :showErrors="showErrors">
      <select class="ugi-select"
              :value="basicDetails.unitLocation"
              @input="setProp('unitLocation', $event.srcElement.value)"
              :class="{'ugi-input-invalid' : validations.unitLocation.$invalid && showErrors}">
        <option value='M'>{{ $t('M_NAME') }}</option>
        <option value='FT'>{{ $t('FT_NAME') }}</option>
      </select>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12 lg:col-start-1 lg:col-end-7"
                               :title="$t('Depth unit')"
                               :error="getErrorDescription('unitDepth', validations)"
                               :showErrors="showErrors">
      <select class="ugi-select"
              :value="basicDetails.unitDepth"
              @input="setProp('unitDepth', $event.srcElement.value)"
              :class="{'ugi-input-invalid' : validations.unitDepth.$invalid && showErrors}">
        <option value='M'>{{ $t('M_NAME') }}</option>
        <option value='CM'>{{ $t('CM_NAME') }}</option>
        <option value='FT'>{{ $t('FT_NAME') }}</option>
        <option value='IN'>{{ $t('IN_NAME') }}</option>
      </select>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12" :title="$t('Depth range of interest')" :subtitle="$t('optional')">
      <div class="flex items-center">
        <ugi-form-field-with-title class="col-span-12" :error="getErrorDescription('depthOfInterestFrom', validations)" :showErrors="showErrors">
          <ugi-input-with-unit :value="basicDetails.depthOfInterestFrom"
                               @input="setProp('depthOfInterestFrom', $event)"
                               :unit="basicDetails.unitDepth"
                               :class="{'ugi-input-invalid' : validations.depthOfInterestFrom.$invalid && showErrors}"/>
        </ugi-form-field-with-title>
        <div class="mx-2">{{ $t('to') }}</div>
        <ugi-form-field-with-title class="col-span-12" :error="getErrorDescription('depthOfInterestTo', validations)" :showErrors="showErrors">
          <ugi-input-with-unit :value="basicDetails.depthOfInterestTo"
                               @input="setProp('depthOfInterestTo', $event)"
                               :unit="basicDetails.unitDepth"
                               :class="{'ugi-input-invalid' : validations.depthOfInterestTo.$invalid && showErrors}"/>
        </ugi-form-field-with-title>
      </div>
    </ugi-form-field-with-title>
  </div>
</template>

<script>

import { cloneObject, getErrorDescription } from "@/helpers.js";

export default {
  props: {
    basicDetails: {
      type: Object
    },
    showErrors: {
      type: Boolean
    },
    validations: {
      type: Object
    }
  },
  methods: {
    getErrorDescription: getErrorDescription,
    toggleInArray (prop, value)
    {
      let arr = cloneObject(this.basicDetails[prop]);
      if (arr === undefined || arr === null)
      {
        arr = [];
      }
      if (!arr.includes(value))
      {
        arr.push(value);
      }
      else
      {
        arr = arr.filter(a => a !== value);
      }
      this.setProp(prop, arr);
    },
    statusInArray (prop, value)
    {
      if (this.basicDetails[prop] !== null && this.basicDetails[prop] !== undefined)
      {
        return this.basicDetails[prop].includes(value);
      }
    },
    setProp (prop, value)
    {
      this.$emit("propChanged", { propName: prop, value: value });
    }
  }
};
</script>
