import Vue from "vue";
import Vuex from "vuex";
import { HTTP } from "@/http.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null,
    currentProject: null,
    currentSurveyCompany: null
  },
  mutations: {
    storeUser (state, value)
    {
      state.currentUser = value;
    },
    storeSurveyCompany (state, value)
    {
      state.currentSurveyCompany = value;
    }
  },
  actions: {
    async getUser ({ commit, state }, params)
    {
      const response = await HTTP.get("/users/current");
      commit("storeUser", response);
    },
    async putUser ({ commit, state }, params)
    {
      const response = await HTTP.put("/users/current", params);
      commit("storeUser", response);
    },
    async postSession ({ commit, state }, params)
    {
      const response = await HTTP.post("/sessions", params);
      commit("storeUser", response);
    },
    async deleteSession ({ commit, state }, params)
    {
      await HTTP.put("/session/current");
      commit("storeUser", null);
    },
    async deleteSessionCurrent ({ commit, state }, params)
    {
      await HTTP.delete("/sessions/current");
      commit("storeUser", null);
    },
    async storeSurveyCompany ({ commit, state }, params) // TODO replace this with logic to load survey company associated with user
    {
      commit("storeSurveyCompany", params);
    }
  }
});
