<template>
  <div class="relative inline-block text-left">
    <button type="button" class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-ugi-secondary" id="menu-button" aria-expanded="true" aria-haspopup="true" @click="isOpen = !isOpen" v-on-clickaway="handleClickAway">
      <span class="sr-only">Open options</span>
      <!-- Heroicon name: solid/dots-vertical -->
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
      </svg>
    </button>
    <transition enter-active-class="transition ease-out duration-100" enter-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <div v-if="isOpen" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script>

import { mixin as clickaway } from "vue-clickaway";

export default ({
  mixins: [clickaway],
  props: {

  },
  data ()
  {
    return {
      isOpen: false
    };
  },
  mounted ()
  {

  },
  methods:
  {
    handleClickAway ()
    {
      this.isOpen = false;
    }
  }
});
</script>

<style scoped>

</style>
