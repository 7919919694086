<template>
  <main-logged-in :title="$t('projects')">
    <div slot="buttons" >
      <button class="ugi-button-light" @click="$router.push('/projects/new/details');">{{ $t('newProject') }}</button>
    </div>
    <ugi-main-section :showPagination="true" >
      <ul v-if="projects" class="divide-y divide-gray-200">
        <client-projects-list-item v-for="project in this.projects" :key="project.id" :project=project />
      </ul>
      <div v-if="currentError" >
        <p class="max-w-3xl mt-4 mx-auto text-center text-xl leading-9 font-medium text-gray-500">
          {{$t(currentError)}}
        </p>
      </div>
    </ugi-main-section>
  </main-logged-in>
</template>

<script>

import MainLoggedIn from "@/common/MainLoggedIn.vue";
import ClientProjectsListItem from "./ClientProjectsListItem.vue";
import { HTTP } from "@/http.js";

export default {
  components: {
    MainLoggedIn,
    ClientProjectsListItem
  },
  data ()
  {
    return {
      projects: [],
      currentError: ""
    };
  },
  created ()
  {
    HTTP.get("/client/projects")
      .then(response =>
      {
        this.projects = response;
      })
      .catch(error =>
      {
        this.currentError = error;
      });
  }
};
</script>
