<template>
  <nav class="bg-ugi-primary">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16 border-b border-white border-opacity-25">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button type="button" class="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray" aria-controls="mobile-menu" aria-expanded="false">
            <!--<span class="sr-only">Open main menu</span>-->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="isOn" @click="isOn = !isOn" :class="isOn ? 'hidden' : 'block'">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="isOn" @click="isOn = !isOn" :class="isOn ? 'block' : 'hidden'">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex-shrink-0 flex items-center">
            <img class="block lg:hidden h-8 w-auto" src="@/assets/logo.svg" alt="Workflow"/>
            <img class="hidden lg:block h-8 w-auto" src="@/assets/logo.svg" alt="Workflow"/>
          </div>
          <div class="hidden sm:block sm:ml-6">
            <div class="flex space-x-4">

              <router-link v-for="menuOption in menuOptions" :to="menuOption.link" custom :key="menuOption.link" v-slot="{ navigate, isActive }">
                <span :class="isActive ? 'navbar-link-selected' : 'navbar-link'" @click="navigate">
                  {{$t(menuOption.title)}}
                </span>
              </router-link>

            </div>
          </div>
        </div>
        <notifications />
        <nav-bar-profile />
      </div>
      <ugi-notifications class="absolute top-10 right-0"/>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div :class="isOn ? 'block' : 'hidden'" class='sm:hidden' id="mobile-menu">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <router-link v-for="menuOption in menuOptions" :to="menuOption.link" custom :key="menuOption.link" v-slot="{ navigate }">
          <a class="text-white hover:bg-ugi-primary-dark block px-3 py-2 rounded-md text-base font-medium" @click="navigate">{{ $t(menuOption.title) }}</a>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import Notifications from "./Notifications.vue";
import NavBarProfile from "./NavBarProfile.vue";
import UgiNotifications from "@/generic/UgiNotifications.vue";

export default {
  components: {
    Notifications,
    NavBarProfile,
    UgiNotifications
  },
  data: () => ({
    isOn: false,
    menuOptions: [
      {
        title: "PO Projects",
        link: "/projects"
      },
      {
        title: "SC Projects",
        link: "/projects-survey-company"
      },
      {
        title: "Survey Companies",
        link: "/companies"
      },
      {
        title: "settings",
        link: "/settings"
      }
    ]
  })
};
</script>

<style scoped>

    .navbar-link {
        @apply text-white hover:bg-ugi-primary-dark px-3 py-1.5 rounded-md cursor-pointer;
    }

    .navbar-link-selected {
        @apply bg-ugi-primary-dark text-white px-3 py-1.5 rounded-md cursor-pointer;
    }

</style>
