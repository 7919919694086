<template>
  <button class="" :class="[{ 'ugi-element-disabled ' : disabled || loading }, classButton]" @click="$emit('click', $event)">
    <div :style="{ 'width': containerWidth }" class="fade mx-auto" ref="container">
      <transition name="fade" mode="out-in" class="fader" @before-enter="beforeEnter">
        <div v-if="!loading" key="1" class="flex whitespace-nowrap">
          <div class="h-4">
            <!-- placeholder to make animation work well on popup buttons -->
          </div>
          {{ text }}
        </div>
        <div v-if="loading" key="2" class="flex whitespace-nowrap">
          <svg class="animate-spin h-4 mr-2" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"/>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
          </svg>
          {{ loadingText != null ? loadingText : text }}
        </div>
      </transition>
    </div>
  </button>
</template>

<script>

export default ({
  props: {
    text: {
      type: String,
      default: ""
    },
    loadingText: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classButton: {
      type: String,
      default: "ugi-button-primary"
    }
  },
  data ()
  {
    return {
      containerWidth: "auto"
    };
  },
  mounted ()
  {
    const { width } = getComputedStyle(this.$refs.container);
    this.containerWidth = width;
  },
  methods:
    {
      beforeEnter (element)
      {
        const tmp = this.containerWidth;
        this.containerWidth = "auto";
        requestAnimationFrame(() =>
        {
          const { width } = getComputedStyle(element);
          this.containerWidth = tmp;
          requestAnimationFrame(() =>
          {
            this.containerWidth = `${width}`;
          });
        });
      }
    }
});
</script>

<style scoped>

    .fade {
        transition: all 150ms;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .fade-leave,
    .fade-enter-to {
        opacity: 1;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 150ms ease;
        /*   position: absolute; */
    }

    .fade-enter-active {
        transition-delay: 50ms;
    }
</style>
