<template>
  <div class="px-4 py-4 sm:px-6 flex">

    <div class="flex-grow">
      <div class="flex items-center justify-between">
        <p class="font-medium truncate">
          {{ surveyCompany.name }}
        </p>
      </div>
      <div class="mt-2 flex items-center justify-between">
        <div class="sm:flex">
          <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            {{ surveyCompany.emailAdmin }}
          </p>
        </div>
      </div>
    </div>

    <div class="ml-2 flex-shrink-0">
      <div class="ml-1 flex items-center justify-between">
        <p v-if="currentProposalType !== null && currentProposalType !== 'CANCEL'" class="ugi-tag bg-ugi-secondary-light text-ugi-primary">
          {{$t('Invited')}}
        </p>

        <ugi-dropdown v-if="currentProposalType === 'INVITE'" class="ml-2">
          <div>
            <ugi-dropdown-button :label="$t('Cancel invitation')" @click="cancelInvite(surveyCompany.id)" svgPath="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </div>
        </ugi-dropdown>

      </div>
      <ugi-button v-if="currentProposalType !== 'INVITE' && project.status === 'OPEN'" :text="$t('Invite')" :loadingText="$t('Inviting')" :loading="inviting" @click="inviteToApply(surveyCompany.id)" classButton="ugi-button-light" />
    </div>

  </div>
</template>

<script>

import { HTTP } from "@/http.js";

export default ({
  props:
      {
        project: {
          type: Object
        },
        surveyCompany: {
          type: Object
        }
      },
  data ()
  {
    return {
      inviting: false,
      cancellingInvite: false
    };
  },
  computed: {
    invited ()
    {
      if (this.project.applications)
      {
        return this.project.applications.some(a => a.idSurveyCompany === this.surveyCompany.id);
      }
      else
      {
        return false;
      }
    },
    currentProposalType ()
    {
      if (this.project.applications)
      {
        const application = this.project.applications.filter(app => app.idSurveyCompany === this.surveyCompany.id);
        if (application.length > 0)
        {
          const maxtimestamp = Math.max(...application[0].proposals.map(prop => prop.timestamp));
          const proposal = application[0].proposals.find(prop => prop.timestamp === maxtimestamp);
          return proposal.type;
        }
        else
        {
          return null;
        }
      }
      else
      {
        return null;
      }
    }
  },
  methods: {
    inviteToApply (id)
    {
      this.inviting = true;
      HTTP.post(`/client/projects/${this.project.id}/applications/${id}/invite`, { proposal: { type: "INVITE" } })
        .then(response =>
        {
          this.$emit("update:project", (response));
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        })
        .finally(() =>
        {
          this.inviting = false;
        });
    },
    cancelInvite (id)
    {
      this.cancelInvite = true;
      HTTP.post(`/client/projects/${this.project.id}/applications/${id}/cancel`)
        .then(response =>
        {
          this.$emit("update:project", (response));
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        })
        .finally(() =>
        {
          this.cancelInvite = false;
        });
    }
  }

});
</script>
