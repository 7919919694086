import Vue from "vue";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./generic/lang/lang";
import { constants } from "@/constants.js";
import "@/assets/tailwind.css";
import "vue-height-tween-transition/index.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import vueHeightTweenTransition from "vue-height-tween-transition";
import PortalVue from "portal-vue";
import mitt from "mitt";
import vuelidate from "vuelidate";

import TransitionExpand from "@/generic/TransitionExpand";
import UgiFormFieldWithTitle from "@/generic/UgiFormFieldWithTitle";
import UgiMainSection from "@/generic/UgiMainSection";
import UgiSwitch from "@/generic/UgiSwitch";
import UgiWizardItem from "@/generic/UgiWizardItem";
import UgiButton from "@/generic/UgiButton";
import UgiInlineAlert from "@/generic/UgiInlineAlert";
import UgiStepBar from "@/generic/UgiStepBar";
import UgiPopup from "@/generic/UgiPopup";
import UgiPopupPanel from "@/generic/UgiPopupPanel";
import UgiDropdown from "@/generic/dropdown/UgiDropdown";
import UgiDropdownButton from "@/generic/dropdown/UgiDropdownButton";
import UgiFileUploader from "@/generic/UgiFileUploader";
import UgiInputWithUnit from "@/generic/UgiInputWithUnit.vue";
import UgiInputWithCurrency from "@/generic/UgiInputWithCurrency.vue";
import UgiHistoryBar from "@/generic/UgiHistoryBar";
import UgiDetailsFieldWithTitle from "@/generic/UgiDetailsFieldWithTitle";

Vue.component("transition-expand", TransitionExpand);
Vue.component("ugi-form-field-with-title", UgiFormFieldWithTitle);
Vue.component("ugi-main-section", UgiMainSection);
Vue.component("ugi-switch", UgiSwitch);
Vue.component("ugi-wizard-item", UgiWizardItem);
Vue.component("ugi-step-bar", UgiStepBar);
Vue.component("ugi-popup", UgiPopup);
Vue.component("ugi-popup-panel", UgiPopupPanel);
Vue.component("height-tween", vueHeightTweenTransition);
Vue.component("ugi-button", UgiButton);
Vue.component("ugi-inline-alert", UgiInlineAlert);
Vue.component("ugi-dropdown", UgiDropdown);
Vue.component("ugi-dropdown-button", UgiDropdownButton);
Vue.component("ugi-file-uploader", UgiFileUploader);
Vue.component("ugi-input-with-unit", UgiInputWithUnit);
Vue.component("ugi-input-with-currency", UgiInputWithCurrency);
Vue.component("ugi-history-bar", UgiHistoryBar);
Vue.component("ugi-details-field-with-title", UgiDetailsFieldWithTitle);

Vue.use(vuelidate);
Vue.use(PortalVue);

Vue.prototype.$constants = constants;

const bus = mitt(); // bus
Vue.prototype.$bus = bus;

Vue.config.productionTip = false;

Vue.use(VueCookies);

Vue.directive("focus", {
  // When the bound element is inserted into the DOM...
  inserted: function (el)
  {
    // Focus the element
    el.focus();
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
