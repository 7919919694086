<template>
  <div class="bg-white overflow-hidden">
    <li :key="project.id">
      <router-link :to="{ path: `/projects/${project.id}/${toUrl}`}" class="block hover:bg-gray-50">
        <div class="px-4 py-4 sm:px-6">
          <div class="flex items-center justify-between">
            <p class="text-sm font-medium text-indigo-600 truncate">
              {{project.basicDetails.name}}
            </p>
            <div class="ml-2 flex-shrink-0 flex">
              <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                {{project.status}}
              </p>
            </div>
          </div>
          <div class="mt-2 sm:flex sm:justify-between">
            <div class="sm:flex">
              <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <!-- Heroicon name: solid/location-marker -->
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                </svg>
                {{project.basicDetails.address}}
              </p>
            </div>
          </div>
        </div>
      </router-link>
    </li>
  </div>
</template>

<script>

export default ({
  props: {
    project: {
      type: Object
    }
  },
  computed: {
    toUrl ()
    {
      if (this.project.status === "DRAFT")
      {
        return "details";
      }
      else if (this.project.status === "OPEN")
      {
        if (this.project.applications !== null && this.project.applications.some(a => a.proposals.some(b => b.type === "QUOTE")))
        {
          return "proposals";
        }
        else
        {
          return "contractors";
        }
      }
      else
      {
        return "results";
      }
    }
  }
});
</script>
