<template>
  <div class="p-6">

    <span v-html="$t('Please upload all documentation that might help the survey process. (Underground drawings from asset, historical construction drawings, above ground drawings, previous survey data, etc.)')"/>

    <div class="mt-4 grid grid-cols-1 gap-2">
      <ugi-file-uploader v-for="(fileInfo, index) in fileInfoList" :key="index" :fileInfo="fileInfo" @uploaded="addFileToProject(fileInfo, $event)" @delete="deleteFile(fileInfo)" @download="downloadFile(fileInfo)" />
      <label>
        <div class="ugi-button-light">{{ $t('Upload document') }}</div>
        <input type="file" style="display:none" @input="importFiles"/>
      </label>
    </div>
  </div>
</template>

<script>

import { cloneObject, getErrorDescription } from "@/helpers.js";
import { HTTP } from "@/http.js";

export default {
  props: {
    basicDetails: {
      type: Object
    },
    showErrors: {
      type: Boolean
    },
    validations: {
      type: Object
    },
    idProject: {
      type: String
    }
  },
  data ()
  {
    return {
      fileInfoList: []
    };
  },
  mounted ()
  {
    if (this.basicDetails.files !== undefined && this.basicDetails.files !== null)
    {
      this.basicDetails.files.forEach(file =>
      {
        this.fileInfoList.push({
          id: file.id,
          name: file.name
        });
      });
    }
  },
  methods: {
    getErrorDescription: getErrorDescription,
    importFiles (event)
    {
      const droppedFiles = event.target.files;
      if (!droppedFiles) return;
      const file = droppedFiles[0];
      event.target.value = "";

      HTTP.post(`/client/projects/${this.idProject}/files`, {})
        .then(response =>
        {
          this.fileInfoList.push({
            id: response.idFile,
            name: file.name,
            file: file,
            url: response.urlUpload
          });
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        });
    },
    addFileToProject (fileInfo, event)
    {
      let arr = cloneObject(this.basicDetails.files);
      if (arr === undefined || arr === null)
      {
        arr = [];
      }

      arr.push({
        id: fileInfo.id,
        name: fileInfo.file.name
      });

      this.setProp("files", arr);

      var index = this.fileInfoList.indexOf(fileInfo);
      this.$set(this.fileInfoList, index, { // to make array change reactive
        id: fileInfo.id,
        name: fileInfo.file.name
      });
    },
    setProp (prop, value)
    {
      this.$emit("propChanged", { propName: prop, value: value });
    },
    downloadFile (fileInfo)
    {
      HTTP.get(`/client/projects/${this.idProject}/files/${fileInfo.id}`, {})
        .then(response =>
        {
          const link = document.createElement("a");
          link.href = response.urlDownload;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        });
    },
    deleteFile (fileInfo)
    {
      var index = this.fileInfoList.indexOf(fileInfo);
      this.$delete(this.fileInfoList, index);

      let arr = cloneObject(this.basicDetails.files);
      arr = arr.filter(a => a.id !== fileInfo.id);
      this.setProp("files", arr);
    }
  }
};
</script>

<style scoped>

</style>
