<template>
  <div>
    <label class="block text-gray-500" :class="{'mb-1' : !(subtitle)}" v-if="title">{{ title }}</label>
    <label class="block text-gray-400 text-sm mb-1" v-if="subtitle">{{ subtitle }}</label>
    <slot/>
    <transition-expand :visible="error !== undefined && error.length > 0 && showErrors">
      <div class="text-red-700 text-sm">{{ $t(error) }}</div>
    </transition-expand>
  </div>
</template>

<script>

export default {
  components: {

  },
  data: function ()
  {
    return {

    };
  },
  model: {
  },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String,
      default: null
    },
    error: {
      type: String
    },
    showErrors: {
      type: Boolean
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  methods: {

  }
};
</script>
