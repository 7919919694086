<template>
  <div class="lg:col-span-9">
    <div class="py-6 px-4 sm:p-6 lg:pb-8">
      <div>
        <h2 class="ugi-section-title">{{$t('notifications')}}</h2>
      </div>
      <!-- Privacy section -->
      <div class="divide-y divide-gray-200">
        <div class="px-4 sm:px-6">
          <ul class="mt-2 divide-y divide-gray-200">
            <li class="py-4 flex items-center justify-between">
              <div class="flex flex-col">
                <p class="text-sm font-medium text-gray-900" id="privacy-option-1-label">
                  Available to hire
                </p>
                <p class="text-sm text-gray-500" id="privacy-option-1-description">
                  Nulla amet tempus sit accumsan. Aliquet turpis sed sit lacinia.
                </p>
              </div>
              <!-- Enabled: "bg-teal-500", Not Enabled: "bg-gray-200" -->
              <button type="button" class="bg-gray-200 ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500" aria-pressed="true" aria-labelledby="privacy-option-1-label" aria-describedby="privacy-option-1-description">
                <span class="sr-only">Use setting</span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"/>
              </button>
            </li>
            <li class="py-4 flex items-center justify-between">
              <div class="flex flex-col">
                <p class="text-sm font-medium text-gray-900" id="privacy-option-2-label">
                  Make account private
                </p>
                <p class="text-sm text-gray-500" id="privacy-option-2-description">
                  Pharetra morbi dui mi mattis tellus sollicitudin cursus pharetra.
                </p>
              </div>
              <!-- Enabled: "bg-teal-500", Not Enabled: "bg-gray-200" -->
              <button type="button" class="bg-gray-200 ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500" aria-pressed="false" aria-labelledby="privacy-option-2-label" aria-describedby="privacy-option-2-description">
                <span class="sr-only">Use setting</span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"/>
              </button>
            </li>
            <li class="py-4 flex items-center justify-between">
              <div class="flex flex-col">
                <p class="text-sm font-medium text-gray-900" id="privacy-option-3-label">
                  Allow commenting
                </p>
                <p class="text-sm text-gray-500" id="privacy-option-3-description">
                  Integer amet, nunc hendrerit adipiscing nam. Elementum ame
                </p>
              </div>
              <!-- Enabled: "bg-teal-500", Not Enabled: "bg-gray-200" -->
              <button type="button" class="bg-gray-200 ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500" aria-pressed="true" aria-labelledby="privacy-option-3-label" aria-describedby="privacy-option-3-description">
                <span class="sr-only">Use setting</span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"/>
              </button>
            </li>
            <li class="py-4 flex items-center justify-between">
              <div class="flex flex-col">
                <p class="text-sm font-medium text-gray-900" id="privacy-option-4-label">
                  Allow mentions
                </p>
                <p class="text-sm text-gray-500" id="privacy-option-4-description">
                  Adipiscing est venenatis enim molestie commodo eu gravid
                </p>
              </div>
              <!-- Enabled: "bg-teal-500", Not Enabled: "bg-gray-200" -->
              <button type="button" class="bg-gray-200 ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500" aria-pressed="true" aria-labelledby="privacy-option-4-label" aria-describedby="privacy-option-4-description">
                <span class="sr-only">Use setting</span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"/>
              </button>
            </li>
          </ul>
        </div>

      </div>
      <div class="mt-4 py-4 px-4 flex justify-end sm:px-6 bg-gray-50">
        <button type="submit" class="ugi-button-primary">
          {{$t('save')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup ()
  {

  }
};
</script>
