<template>
  <form class="divide-y divide-gray-200 lg:col-span-9" action="#" method="POST">
    <!-- Profile section -->
    <div class="py-6 px-4 sm:p-6 lg:pb-8">
      <div>
        <h2 class="ugi-section-title">{{$t('company')}}</h2>
      </div>
      <div class="flex flex-col lg:flex-row">
        <div class="flex-grow space-y-6">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              {{ $t('companyName') }}
            </label>
            <div class="mt-1 rounded-md shadow-sm flex">
              <input type="text" name="company-name" id="company-name" autocomplete="company-name" class="focus:ring-ugi-primary focus:border-ugi-primary flex-grow block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 py-4 px-4 flex justify-end sm:px-6 bg-gray-50">
      <ugi-button :text="$t('save')" :loadingText="$t('saving')" :loading="loading" :disabled="(loading )" />
    </div>
  </form>
</template>

<script>
export default {
  data ()
  {
    return {
      loading: false
    };
  }

};
</script>
