<template>
  <main-logged-in :title="$t('settings')" >
    <div class="ugi-card divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
      <aside class="lg:col-span-3">
        <!--<div class="p-6 lg:hidden">
                <select class="ugi-select" :value="currentRoute" @input="$router.push($event.srcElement.value)">
                    <option v-for="option in menuOptions" :value="option.link">{{ $t(option.title) }}</option>
                </select>
            </div>-->
        <nav class="space-y-1 my-6">
          <router-link v-for="option in menuOptions" :to="option.title" :key="option.title" custom v-slot="{ navigate, isActive }">
            <span :class="isActive ? 'settings-menu-option-selected' : 'settings-menu-option'" @click="navigate">
              <svg :class="isActive ? 'settings-menu-icon-selected' : 'settings-menu-icon'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :d="option.svgContent" />
              </svg>
              <span class="truncate">
                {{ $t(option.title) }}
              </span>
            </span>
          </router-link>
        </nav>
      </aside>
      <div class="lg:col-span-9">
        <router-view />
      </div>
    </div>
  </main-logged-in>
</template>

<script>
import MainLoggedIn from "@/common/MainLoggedIn.vue";

export default {
  components: {
    MainLoggedIn
  },
  computed: {
    currentRoute ()
    {
      return this.$route.fullPath;
    }
  },
  data ()
  {
    return {
      menuOptions: [
        {
          title: "profile",
          link: "/settings/profile",
          svgContent: "M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        },
        {
          title: "security",
          link: "/settings/security",
          svgContent: "M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
        }
        // {
        //    title: "company",
        //    link: "/settings/company",
        //    svgContent: "M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4",
        // },
        // {
        //    title: "notifications",
        //    link: "/settings/notifications",
        //    svgContent: "M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9",
        // },
      ]
    };
  }
};
</script>

<style scoped>

    .settings-menu-option {
        @apply hover:border-ugi-secondary hover:bg-gray-50 group border-white border-l-4 px-3 py-2 flex items-center text-sm font-medium cursor-pointer;
    }

    .settings-menu-option-selected {
        @apply bg-gray-100 border-ugi-secondary text-ugi-primary-dark group border-l-4 px-3 py-2 flex items-center text-sm font-medium cursor-pointer;
    }

    .settings-menu-icon {
        @apply text-gray-400 group-hover:text-gray-500 flex-shrink-0 -ml-1 mr-3 h-6 w-6;
    }

    .settings-menu-icon-selected {
        @apply text-ugi-primary-dark group-hover:text-ugi-primary-dark flex-shrink-0 -ml-1 mr-3 h-6 w-6;
    }

</style>
