<template>
  <div class="p-6 grid sm:grid-cols-12 gap-6">
    <ugi-form-field-with-title class="col-span-12"
                               :title="$t('PPE requirements')"
                               :error="getErrorDescription('ppeRequirements', validations)" :showErrors="showErrors"
                               :class="{'ugi-input-invalid' : validations.ppeRequirements.$invalid && showErrors}">
      <ugi-switch :label="$t('ppe_EYE')" :value="statusInArray('ppeRequirements', 'EYE')" @input="toggleInArray('ppeRequirements', 'EYE')" />
      <ugi-switch :label="$t('ppe_HEARING')" :value="statusInArray('ppeRequirements', 'HEARING')" @input="toggleInArray('ppeRequirements', 'HEARING')" />
      <ugi-switch :label="$t('ppe_HEAD')" :value="statusInArray('ppeRequirements', 'HEAD')" @input="toggleInArray('ppeRequirements', 'HEAD')" />
      <ugi-switch :label="$t('ppe_FOOT')" :value="statusInArray('ppeRequirements', 'FOOT')" @input="toggleInArray('ppeRequirements', 'FOOT')" />
      <ugi-switch :label="$t('ppe_FACE')" :value="statusInArray('ppeRequirements', 'FACE')" @input="toggleInArray('ppeRequirements', 'FACE')" />
      <ugi-switch :label="$t('Other')" :value="statusInArray('ppeRequirements', 'OTHER')" @input="toggleInArray('ppeRequirements', 'OTHER')" />
      <transition-expand :visible="statusInArray('ppeRequirements', 'OTHER')" class="grid sm:grid-cols-12">
        <input type="text"
               :value="basicDetails.ppeRequirementsOther"
               :error="getErrorDescription('ppeRequirementsOther', validations)" :showErrors="showErrors"
               :class="{'ugi-input-invalid' : validations.ppeRequirementsOther.$invalid && showErrors}"
               @input="setProp('ppeRequirementsOther', $event.srcElement.value)" class="ugi-input col-span-6 mt-4" />
      </transition-expand>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12"
                               :title="$t('Climate conditions')"
                               :error="getErrorDescription('climateConditions', validations)" :showErrors="showErrors">
      <input type="text"
             :value="basicDetails.climateConditions"
             @input="setProp('climateConditions', $event.srcElement.value)" class="ugi-input"
             :class="{'ugi-input-invalid' : validations.climateConditions.$invalid && showErrors}" />
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12"
                               :title="$t('Regional security considerations')"
                               :error="getErrorDescription('regionalSecurityConsiderations', validations)" :showErrors="showErrors">
      <input type="text"
             :value="basicDetails.regionalSecurityConsiderations"
             @input="setProp('regionalSecurityConsiderations', $event.srcElement.value)" class="ugi-input"
             :class="{'ugi-input-invalid' : validations.regionalSecurityConsiderations.$invalid && showErrors}" />
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12"
                               :title="$t('Permitting')">
      <ugi-switch :label="$t('Is permission required to access the site?')"
                  :value="basicDetails.permissionRequired"
                  @input="setProp('permissionRequired', !basicDetails.permissionRequired)" />
      <transition-expand :visible="basicDetails.permissionRequired">
        <ugi-form-field-with-title class="lg:col-start-1 lg:col-end-7"
                                   :title="$t('Permit issuer')"
                                   :error="getErrorDescription('permitIssuer', validations)" :showErrors="showErrors">
          <input type="text"
                 :value="basicDetails.permitIssuer"
                 @input="setProp('permitIssuer', $event.srcElement.value)"
                 :class="{'ugi-input-invalid' : validations.permitIssuer.$invalid && showErrors}"
                 class="ugi-input" />
        </ugi-form-field-with-title>
        <ugi-form-field-with-title class="lg:col-start-1 lg:col-end-7"
                                   :title="$t('Permit requisites')"
                                   :error="getErrorDescription('permitRequisites', validations)" :showErrors="showErrors">
          <input type="text"
                 :value="basicDetails.permitRequisites"
                 @input="setProp('permitRequisites', $event.srcElement.value)"
                 :class="{'ugi-input-invalid' : validations.permitRequisites.$invalid && showErrors}"
                 class="ugi-input" />
        </ugi-form-field-with-title>
      </transition-expand>
    </ugi-form-field-with-title>

    <ugi-form-field-with-title class="col-span-12" :title="$t('Time frame')">
      <div class="grid sm:grid-cols-12 gap-6">
        <ugi-form-field-with-title class="col-span-12 lg:col-span-6"
                                   :title="$t('Start date')"
                                   :error="getErrorDescription('startDate', validations)" :showErrors="showErrors">
          <input type="date"
                 :value="basicDetails.startDate"
                 :min="today"
                 @input="setProp('startDate', $event.srcElement.value)" class="ugi-input"
                 :class="{'ugi-input-invalid' : validations.startDate.$invalid && showErrors}" />
        </ugi-form-field-with-title>

        <ugi-form-field-with-title class="col-span-12 lg:col-span-6"
                                   :title="$t('End date')"
                                   :error="getErrorDescription('endDate', validations)" :showErrors="showErrors">
          <input type="date"
                 :value="basicDetails.endDate"
                 :min="basicDetails.startDate"
                 @input="setProp('endDate', $event.srcElement.value)" class="ugi-input"
                 :class="{'ugi-input-invalid' : validations.endDate.$invalid && showErrors}" />
        </ugi-form-field-with-title>
      </div>
    </ugi-form-field-with-title>
  </div>
</template>

<script>
import { cloneObject, getErrorDescription, epochToStringInputDate } from "@/helpers.js";

export default {
  props: {
    basicDetails: {
      type: Object
    },
    showErrors: {
      type: Boolean
    },
    validations: {
      type: Object
    }
  },
  methods: {
    getErrorDescription: getErrorDescription,
    epochToStringInputDate: epochToStringInputDate,
    toggleInArray (prop, value)
    {
      let arr = cloneObject(this.basicDetails[prop]);
      if (arr === undefined || arr === null)
      {
        arr = [];
      }
      if (!arr.includes(value))
      {
        arr.push(value);
      }
      else
      {
        arr = arr.filter(a => a !== value);
      }
      this.setProp(prop, arr);
    },
    statusInArray (prop, value)
    {
      if (this.basicDetails[prop] !== undefined && this.basicDetails[prop] !== null)
      {
        return this.basicDetails[prop].includes(value);
      }
    },
    setProp (prop, value)
    {
      this.$emit("propChanged", { propName: prop, value: value });
    }
  },
  computed: {
    today ()
    {
      return this.epochToStringInputDate(new Date().getTime());
    }
  }
};
</script>
