<template>
  <ugi-popup-panel @confirm="sendProposal"
                   @cancel="$emit('cancel', false)"
                   :loadingPositive="savingProposal"
                   :title="$t('Send proposal')"
                   :subtitle="$t('Here you can define your proposal details.')">
    <div slot>
      <div class="flex-1">
        <!-- Divider container -->
        <div class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
          <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <div>
              <label for="introduction" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {{$t('Introduction')}}
              </label>
            </div>
            <div class="sm:col-span-2">
              <ugi-form-field-with-title class="col-span-12" :error="getErrorDescription('introduction', $v.proposalEditing)"

                                         :showErrors="showErrors">
                <textarea type="text" id="introduction" class="ugi-input" v-model="proposalEditing.introduction"
                          :class="{'ugi-input-invalid' : $v.proposalEditing.introduction.$invalid && showErrors}"/>
              </ugi-form-field-with-title>
            </div>
          </div>

          <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <div>
              <label for="safety-protocols" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {{$t('Safety protocols')}}
              </label>
            </div>
            <div class="sm:col-span-2">
              <ugi-form-field-with-title class="col-span-12" :error="getErrorDescription('safetyProtocols', $v.proposalEditing)"
                                         :showErrors="showErrors">
                <textarea type="text" id="safety-protocols" class="ugi-input" v-model="proposalEditing.safetyProtocols"
                          :class="{'ugi-input-invalid' : $v.proposalEditing.safetyProtocols.$invalid && showErrors}"/>
              </ugi-form-field-with-title>
            </div>
          </div>

          <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <div>
              <label for="proposed-methodologies" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {{$t('Proposed Methodology(ies)')}}
              </label>
            </div>
            <div class="sm:col-span-2">

              <ugi-form-field-with-title class="col-span-6">
                <ugi-switch :label="$t('GPR')" :value="proposalEditing.gpr" @input="setProp('gpr', $event)" />
                <transition-expand :visible="proposalEditing.gpr" class="col-span-12 lg:col-span-6">
                  <ugi-form-field-with-title class="pb-6" :title="$t('Minimum profile separation')"
                                             :error="getErrorDescription('minimumProfileSeparation', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-unit :value="proposalEditing.minimumProfileSeparation"
                                         @input="setProp('minimumProfileSeparation', $event)"
                                         :unit="unitLocation"
                                         :class="{'ugi-input-invalid' : $v.proposalEditing.minimumProfileSeparation.$invalid && showErrors}"/>
                  </ugi-form-field-with-title>
                </transition-expand>
                <ugi-switch :label="$t('EML')" :value="proposalEditing.eml" @input="setProp('eml', $event)" />
                <ugi-switch :label="$t('Lidar')" :value="proposalEditing.lidar" @input="setProp('lidar', $event)" />
                <ugi-switch :label="$t('Above-Ground Object Survey')" :value="proposalEditing.objectSurvey" @input="setProp('objectSurvey', $event)" />
                <ugi-switch :label="$t('Manual manhole')" :value="proposalEditing.manualManhole" @input="setProp('manualManhole', $event)" />
                <ugi-switch :label="$t('Trench digging')" :value="proposalEditing.trenchDigging" @input="setProp('trenchDigging', $event)" />
                <transition-expand :visible="hasBudgetOthers" class="col-span-12 lg:col-span-6">
                  <ugi-form-field-with-title v-for="budget, index in proposalEditing.budgetOthers" :key="index" class="col-span-12 mt-2 grid grid-cols-3" >
                    <input type="text" id="gpr-data-acquisition-price" class="ugi-input col-span-2" v-model="proposalEditing.budgetOthers[index].description"/>
                    <button type="button" class="button-icon ml-2 cols-span-1 mt-2" @click="removeBudget(index)">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </button>
                  </ugi-form-field-with-title>
                </transition-expand>
                <a class="cursor-pointer underline col-span-2" @click="addItembudgetOthers('budgetOthers')">
                  {{$t('Add item to budget')}}
                </a>

              </ugi-form-field-with-title>
            </div>
          </div>

          <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <div>
              <label for="currency" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {{$t('Currency')}}
              </label>
            </div>
            <div class="sm:col-span-2">
              <ugi-form-field-with-title class="col-span-12 lg:col-start-1 lg:col-end-7"
                                         :error="getErrorDescription('currency', $v.proposalEditing)"
                                         :showErrors="showErrors">
                <select class="ugi-select"
                        :class="{'ugi-input-invalid' : $v.proposalEditing.currency.$invalid && showErrors}"
                        v-model="proposalEditing.currency">
                  <option value='USD'>{{ $t('USD') }}</option>
                  <option value='EUR'>{{ $t('EUR') }}</option>
                </select>
              </ugi-form-field-with-title>
            </div>
          </div>

          <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <div>
              <label for="budget" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {{$t('Budget')}}
              </label>
            </div>
            <div class="sm:col-span-2">
              <ugi-form-field-with-title class="col-span-12 " >
                <transition-expand :visible="proposalEditing.gpr" class="col-span-12 lg:col-span-6">
                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data acquisition (GPR)')"
                                             :error="getErrorDescription('budgetGprAcquisition', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetGprAcquisition"
                                             @input="setProp('budgetGprAcquisition', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetGprAcquisition.$invalid && showErrors"/>

                  </ugi-form-field-with-title>

                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data processing and interpretation (GPR)')"
                                             :error="getErrorDescription('budgetGprProcessing', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetGprProcessing"
                                             @input="setProp('budgetGprProcessing', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetGprProcessing.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                </transition-expand>
                <transition-expand :visible="proposalEditing.eml" class="col-span-12 lg:col-span-6">
                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data acquisition (EML)')"
                                             :error="getErrorDescription('budgetEmlAcquisition', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetEmlAcquisition"
                                             @input="setProp('budgetEmlAcquisition', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetEmlAcquisition.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data processing and interpretation (EML)')"
                                             :error="getErrorDescription('budgetEmlProcessing', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetEmlProcessing"
                                             @input="setProp('budgetEmlProcessing', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetEmlProcessing.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                </transition-expand>
                <transition-expand :visible="proposalEditing.lidar" class="col-span-12 lg:col-span-6">
                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data acquisition (Lidar)')"
                                             :error="getErrorDescription('budgetLidarAcquisition', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetLidarAcquisition"
                                             @input="setProp('budgetLidarAcquisition', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetLidarAcquisition.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data processing and interpretation (Lidar)')"
                                             :error="getErrorDescription('budgetLidarProcessing', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetLidarProcessing"
                                             @input="setProp('budgetLidarProcessing', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetLidarProcessing.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                </transition-expand>
                <transition-expand :visible="proposalEditing.objectSurvey" class="col-span-12 lg:col-span-6">
                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data acquisition (Above-Ground Object Survey)')"
                                             :error="getErrorDescription('budgetObjectSurveyAcquisition', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetObjectSurveyAcquisition"
                                             @input="setProp('budgetObjectSurveyAcquisition', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetObjectSurveyAcquisition.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data processing and interpretation (Above-Ground Object Survey)')"
                                             :error="getErrorDescription('budgetObjectSurveyProcessing', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetObjectSurveyProcessing"
                                             @input="setProp('budgetObjectSurveyProcessing', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetObjectSurveyProcessing.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                </transition-expand>
                <transition-expand :visible="proposalEditing.manualManhole" class="col-span-12 lg:col-span-6"
                                   :error="getErrorDescription('budgetManualManholeAcquisition', $v.proposalEditing)"
                                   :showErrors="showErrors">
                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data acquisition (Manual manhole)')" >
                    <ugi-input-with-currency :value="proposalEditing.budgetManualManholeAcquisition"
                                             @input="setProp('budgetManualManholeAcquisition', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetManualManholeAcquisition.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data processing and interpretation (Manual manhole)')"
                                             :error="getErrorDescription('budgetManualManholeProcessing', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetManualManholeProcessing"
                                             @input="setProp('budgetManualManholeProcessing', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetManualManholeProcessing.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                </transition-expand>
                <transition-expand :visible="proposalEditing.trenchDigging" class="col-span-12 lg:col-span-6">
                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data acquisition (Trench digging)')"
                                             :error="getErrorDescription('budgetTrenchDiggingAcquisition', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetTrenchDiggingAcquisition"
                                             @input="setProp('budgetTrenchDiggingAcquisition', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetTrenchDiggingAcquisition.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                  <ugi-form-field-with-title class="col-span-12 " :title="$t('Data processing and interpretation (Trench digging)')"
                                             :error="getErrorDescription('budgetTrenchDiggingProcessing', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetTrenchDiggingProcessing"
                                             @input="setProp('budgetTrenchDiggingProcessing', $event)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetTrenchDiggingProcessing.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                </transition-expand>
                <ugi-form-field-with-title class="col-span-12 " :title="$t('Mobilisation of plant and personnel')"
                                           :error="getErrorDescription('budgetMobilisation', $v.proposalEditing)"
                                           :showErrors="showErrors">
                  <ugi-input-with-currency :value="proposalEditing.budgetMobilisation"
                                           @input="setProp('budgetMobilisation', $event)"
                                           :unit="proposalEditing.currency"
                                           :invalid="$v.proposalEditing.budgetMobilisation.$invalid && showErrors"/>
                </ugi-form-field-with-title>
                <ugi-form-field-with-title class="col-span-12 " :title="$t('Reporting')"
                                           :error="getErrorDescription('budgetReporting', $v.proposalEditing)"
                                           :showErrors="showErrors">
                  <ugi-input-with-currency :value="proposalEditing.budgetReporting"
                                           @input="setProp('budgetReporting', $event)"
                                           :unit="proposalEditing.currency"
                                           :invalid="$v.proposalEditing.budgetReporting.$invalid && showErrors"/>
                </ugi-form-field-with-title>
                <transition-expand :visible="hasBudgetOthers" class="col-span-12 lg:col-span-6">
                  <ugi-form-field-with-title v-for="budget, index in proposalEditing.budgetOthers" :key="index" class="col-span-12 mt-2" :title="proposalEditing.budgetOthers[index].description"
                                             :error="getErrorDescription('budgetOthers', $v.proposalEditing)"
                                             :showErrors="showErrors">
                    <ugi-input-with-currency :value="proposalEditing.budgetOthers[index].budget"
                                             @input="setPropOfArray('budgetOthers', $event, index)"
                                             :unit="proposalEditing.currency"
                                             :invalid="$v.proposalEditing.budgetOthers.$invalid && showErrors"/>
                  </ugi-form-field-with-title>
                </transition-expand>
                <ugi-form-field-with-title class="col-span-12 " :title="$t('Total')">
                  <ugi-input-with-currency :value="totalBudget"
                                           :unit="proposalEditing.currency"
                                           :disabled="true"/>
                </ugi-form-field-with-title>
              </ugi-form-field-with-title>
            </div>
          </div>

          <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <div>
              <label for="proposed dates" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {{$t('Proposed dates')}}
              </label>
            </div>
            <div class="sm:col-span-2">
              <div class="grid grid-cols-4 gap-1">
                <ugi-form-field-with-title class="col-span-2 mb-4" :title="$t('field survey date from')"
                                           :error="getErrorDescription('dateFieldSurveyFrom', $v.proposalEditing)"
                                           :showErrors="showErrors">
                  <input type="date" id="proposed-field-survey-date" class="ugi-input"
                         :min="today"
                         v-model="proposalEditing.dateFieldSurveyFrom"
                         :class="{'ugi-input-invalid' : $v.proposalEditing.dateFieldSurveyFrom.$invalid && showErrors}"/>
                </ugi-form-field-with-title>
                <ugi-form-field-with-title class="col-span-2 mb-4" :title="$t('field survey date to')"
                                           :error="getErrorDescription('dateFieldSurveyTo', $v.proposalEditing)"
                                           :showErrors="showErrors">
                  <input type="date" id="proposed-field-survey-date" class="ugi-input"
                         :min="proposalEditing.dateFieldSurveyFrom"
                         v-model="proposalEditing.dateFieldSurveyTo"
                         :class="{'ugi-input-invalid' : $v.proposalEditing.dateFieldSurveyTo.$invalid && showErrors}"/>
                </ugi-form-field-with-title>
              </div>
              <ugi-form-field-with-title class="col-span-12 mb-4" :title="$t('Report Submission Date')"
                                         :error="getErrorDescription('dateReportSubmission', $v.proposalEditing)"
                                         :showErrors="showErrors">
                <input type="date" id="introduction" class="ugi-input"
                       :min="proposalEditing.dateFieldSurveyTo"
                       v-model="proposalEditing.dateReportSubmission"
                       :class="{'ugi-input-invalid' : $v.proposalEditing.dateReportSubmission.$invalid && showErrors}"/>
              </ugi-form-field-with-title>
            </div>
          </div>

          <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <div>
              <label for="files" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                {{$t('Files')}}
              </label>
            </div>
            <div class="mt-4 grid grid-cols-1 gap-2">
              <ugi-file-uploader v-for="(fileInfo, index) in fileInfoList" :key="index" :fileInfo="fileInfo" @uploaded="addFileToProposal(fileInfo, $event)" @delete="deleteFile(fileInfo)" @download="downloadFile(fileInfo)" />
              <label>
                <div class="ugi-button-light">{{ $t('Upload document') }}</div>
                <input type="file" style="display:none" @input="importFiles"/>
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ugi-popup-panel>
</template>

<script>

import { HTTP } from "@/http.js";
import { required, requiredIf } from "vuelidate/lib/validators";
import { cloneObject, getErrorDescription, getTotalBudgetFromProposal, epochToStringInputDate } from "@/helpers.js";

export default
{
  beforeMount ()
  {
    this.proposalEditing = {};
  },
  mounted ()
  {
    if (this.proposalEditing.files !== undefined && this.proposalEditing.files !== null)
    {
      this.proposalEditing.files.forEach(file =>
      {
        this.fileInfoList.push({
          id: file.id,
          name: file.name
        });
      });
    }
  },
  props: {
    unitLocation: {
      type: String
    }
  },
  data ()
  {
    return {
      proposalEditing: null,
      showErrors: false,
      savingProposal: false,
      fileInfoList: []
    };
  },
  validations ()
  {
    return {
      proposalEditing: {
        introduction: { required },
        safetyProtocols: { required },
        minimumProfileSeparation: { required: requiredIf((obj) => obj.gpr) },
        budgetGprAcquisition: { required: requiredIf((obj) => obj.gpr) },
        budgetGprProcessing: { required: requiredIf((obj) => obj.gpr) },
        budgetEmlAcquisition: { required: requiredIf((obj) => obj.eml) },
        budgetEmlProcessing: { required: requiredIf((obj) => obj.eml) },
        budgetLidarAcquisition: { required: requiredIf((obj) => obj.lidar) },
        budgetLidarProcessing: { required: requiredIf((obj) => obj.lidar) },
        budgetObjectSurveyAcquisition: { required: requiredIf((obj) => obj.objectSurvey) },
        budgetObjectSurveyProcessing: { required: requiredIf((obj) => obj.objectSurvey) },
        budgetManualManholeAcquisition: { required: requiredIf((obj) => obj.manualManhole) },
        budgetManualManholeProcessing: { required: requiredIf((obj) => obj.manualManhole) },
        budgetTrenchDiggingAcquisition: { required: requiredIf((obj) => obj.trenchDigging) },
        budgetTrenchDiggingProcessing: { required: requiredIf((obj) => obj.trenchDigging) },
        budgetOthers: { },
        budgetMobilisation: { required },
        budgetReporting: { required },
        currency: { required },
        dateFieldSurveyFrom: {
          required,
          minDate: (value) =>
          {
            if (value !== undefined) return new Date(value) >= new Date(epochToStringInputDate(new Date().getTime()));
            else return true;
          }
        },
        dateFieldSurveyTo: {
          required,
          minDate: (value) =>
          {
            if (value !== undefined) return value > this.proposalEditing.dateFieldSurveyFrom;
            else return true;
          }
        },
        dateReportSubmission: {
          required,
          minDate: (value) =>
          {
            if (value !== undefined) return value > this.proposalEditing.dateFieldSurveyTo;
            else return true;
          }
        },
        files: {}
      }
    };
  },
  computed: {
    hasBudgetOthers ()
    {
      if (this.proposalEditing.budgetOthers)
      {
        return true;
      }
      return false;
    },
    totalBudget ()
    {
      return getTotalBudgetFromProposal(this.proposalEditing);
    },
    today ()
    {
      return epochToStringInputDate(new Date().getTime());
    }
  },
  methods: {
    getErrorDescription: getErrorDescription,
    epochToStringInputDate: epochToStringInputDate,
    importFiles (event)
    {
      const droppedFiles = event.target.files;
      if (!droppedFiles) return;
      const file = droppedFiles[0];
      event.target.value = "";

      HTTP.post(`/contractor/projects/${this.$route.params.idProject}/proposal-files`, {})
        .then(response =>
        {
          this.fileInfoList.push({
            id: response.idFile,
            name: file.name,
            file: file,
            url: response.urlUpload
          });
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        });
    },
    addFileToProposal (fileInfo, event)
    {
      let arr = cloneObject(this.proposalEditing.files);
      if (arr === undefined || arr === null)
      {
        arr = [];
      }

      arr.push({
        id: fileInfo.id,
        name: fileInfo.file.name
      });

      this.setProp("files", arr);

      var index = this.fileInfoList.indexOf(fileInfo);
      this.$set(this.fileInfoList, index, { // to make array change reactive
        id: fileInfo.id,
        name: fileInfo.file.name
      });
    },
    downloadFile (fileInfo)
    {
      HTTP.get(`/contractor/projects/${this.$route.params.idProject}/proposal/${this.$store.state.currentSurveyCompany.id}/files/${fileInfo.id}`, {})
        .then(response =>
        {
          const link = document.createElement("a");
          link.href = response.urlDownload;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error =>
        {
          this.$bus.emit("notification", { type: "ERROR", message: error });
        });
    },
    deleteFile (fileInfo)
    {
      var index = this.fileInfoList.indexOf(fileInfo);
      this.$delete(this.fileInfoList, index);

      let arr = cloneObject(this.proposalEditing.files);
      arr = arr.filter(a => a.id !== fileInfo.id);
      this.setProp("files", arr);
    },
    sendProposal ()
    {
      if (this.$v.$invalid)
      {
        this.showErrors = true;
      }
      else
      {
        this.showErrors = false;
        this.savingProposal = true;
        this.proposalEditing.type = "QUOTE";
        HTTP.post(`/contractor/projects/${this.$route.params.idProject}/applications/${this.$store.state.currentSurveyCompany.id}/quote`, { proposal: this.proposalEditing })
          .then((response) =>
          {
            this.$emit("proposalSent", cloneObject(response));
          })
          .catch(error =>
          {
            this.$bus.emit("notification", { type: "ERROR", message: error });
          })
          .finally(() =>
          {
            this.savingProposal = false;
          });
      }
    },
    setProp (prop, value)
    {
      this.$set(this.proposalEditing, prop, value);
    },
    setPropOfArray (prop, value, index)
    {
      this.proposalEditing.budgetOthers[index].budget = value;
    },
    addItembudgetOthers (array)
    {
      if (!this.proposalEditing[array])
      {
        this.proposalEditing[array] = [];
      }
      this.proposalEditing[array].push({ description: "", budget: 0 });
    },
    removeBudget (itemIndex)
    {
      this.proposalEditing.budgetOthers.splice(itemIndex, 1);
    }
  }
};
</script>

<style scoped>
    .button-icon {
        @apply bg-ugi-secondary-light text-ugi-primary p-1 rounded-full h-6 w-6;
    }
</style>
